import { useState } from "react";
import JsonInput from "../../../../Inputs/JsonInput";
import { isValidJson } from "../../hooks/useModuleForm";

export const DisplayTitlesInput = ({
	field, 
	formState, 
	handleFormUpdate 
}) => {
	const [helperText, setHelperText] = useState("")
	const handleUpdate = (newValue) => {
		handleFormUpdate(field, newValue)
		/* TODO */
		// tags validation/helperText
	}
	return (
		<JsonInput
			label={field}
			value={formState[field]} 
			handleUpdate={handleUpdate}
			helperText={helperText}
		/>
	)
}


export default DisplayTitlesInput;