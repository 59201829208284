import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import DirectRequest from "../../API/requests/DirectRequest";
import { useState, useEffect } from "react";
import {useSelector} from "react-redux";
import * as Constants from "../../Constants";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";

const notFountStyle = { fontSize: "18px", fontWeight: 450 }

function NotFound(props) {
	const user = useSelector((state) => state.role.name ? state.role.name : state.role.email);
	const isInternal = useSelector((state) => state.role.isInternal);
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const [badRouteArgs, setBadRouteArgs] = useState(null);
	const frontendVersion = useSelector(state => state.appState.frontendVersion);
	const backendVersion = useSelector(state => state.appState.backendVersion);

	useEffect(() => {
		// construct email for our SES
		const args = {
			url: Constants.SERVER_SEND_EMAIL,
			method: "POST",
			body: JSON.stringify({
				"body": `${user} has accessed a 404 route: ${location.pathname}<br/><br/>Version: ${frontendVersion || ''} (${backendVersion || '0.0.000'})`,
				"subject": `${user} has accessed a 404 route: "${location.pathname.slice(0, 25)}" (${window.location.origin} - ${iso8601Timestamp()})`,
				"from": "noreply@wiselayer.com",
				"toEmail": Constants.notificationList.join(","),
				"sendHTML": true
			})
		};
		if (!isInternal) setBadRouteArgs(args)
	}, [user, isInternal, location])

	const returnToAlerts = () => {
		navigate("/");
	}

	return (
		<>
			<DirectRequest
				requestArgs={badRouteArgs}
				handleError={err => !Constants.USE_DUMMY_DATA && console.log("NotFound email server error", err)}
				handleCatchError={err => !Constants.USE_DUMMY_DATA && console.log("NotFound email client error", err)}
			/>
			<Grid container spacing={2} sx={{ marginTop: "20vh" }} justifyContent={"center"} alignItems={"center"}>
				<Grid item xs={12}  sx={{ textAlign: "center" }}>
					<Typography variant="h1">404</Typography>
				</Grid>
				<Grid item xs={12} container justifyContent={"center"}>
					<Typography sx={notFountStyle}>Page not found for the requested "{location.pathname.length > 60 ? location.pathname.slice(0, 60) + "..." : location.pathname}" URL.</Typography>
				</Grid>
				<Grid item xs={12}></Grid>
				<Grid item xs={12} container justifyContent={"center"}>
					<Typography sx={notFountStyle}>Click <span style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={returnToAlerts}><u>here</u></span> to go home.</Typography>
				</Grid>
			</Grid>
		</>
	)
}

export default NotFound;