// import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'; 
// import { 
// 	setGetDataArgs, 
// 	setGetReportDataArgs, 
// 	resetDashboardSlice, 
// 	setDashboardData, 
// 	setConfigsReady 
// } from '../dashboardSlice'; 
// import { removeConfigs } from '../dashboardConfigsSlice'; 
// import { resetDashboardFiltersSlice } from '../dashboardFiltersSlice'; 
// import { resetDashboardTransformSlice } from '../dashboardTransformSlice'; 
// import { resetDashboardSortSlice } from '../dashboardSortSlice'; 
// 
// export const dashboardListenerMiddleware = createListenerMiddleware(); 
// 
// // Listen for both regular dashboard data and report data actions 
// dashboardListenerMiddleware.startListening({ 
// 	matcher: isAnyOf(setGetDataArgs, setGetReportDataArgs), 
// 	effect: async (action, listenerApi) => { 
// 		// Cancel any pending listeners
// 		listenerApi.cancelActiveListeners(); // First reset all dashboard-related state
// 		listenerApi.dispatch(resetDashboardSlice()); 
// 		listenerApi.dispatch(removeConfigs());
// 		listenerApi.dispatch(resetDashboardFiltersSlice());
// 		listenerApi.dispatch(resetDashboardTransformSlice());
// 		listenerApi.dispatch(resetDashboardSortSlice()); 
// 		
// 		// Let the original action proceed 
// 		// This will trigger your existing GetDashboardAndConfigsRequest 
// 		// or GetReportAndConfigsRequest components 
// 	} 
// }); 

// store.js 
// import { configureStore } from '@reduxjs/toolkit'; 
// import { dashboardListenerMiddleware } from './middleware/dashboardMiddleware'; 
// 
// export const store = configureStore({ 
// 	reducer: { 
// 		dashboard: dashboardReducer, 
// 		dashboardConfigs: dashboardConfigsReducer, 
// 		dashboardFilters: dashboardFiltersReducer, 
// 		transforms: transformsReducer, 
// 		sortRules: sortRulesReducer, // ... other reducers 
// 	}, 
// 	middleware: (getDefaultMiddleware) => 
// 		getDefaultMiddleware() 
// 			.prepend(dashboardListenerMiddleware.middleware) 
// });
import { 
	setGetDataArgs, 
	setGetReportDataArgs, 
	resetDashboardSlice, 
	setDashboardData, 
	setConfigsReady 
} from '../dashboardSlice';
import { removeConfigs } from '../dashboardConfigsSlice';
import { resetDashboardFiltersSlice } from '../dashboardFiltersSlice';
import { resetDashboardTransformSlice } from '../dashboardTransformSlice';
import { resetDashboardSortSlice } from '../dashboardSortSlice';

export const dashboardMiddleware = store => next => action => {
	// Check if the action is either setGetDataArgs or setGetReportDataArgs
	if (action.type === setGetDataArgs.type || action.type === setGetReportDataArgs.type) {
		// Reset all dashboard-related state
		store.dispatch(resetDashboardSlice());
		store.dispatch(removeConfigs());
		store.dispatch(resetDashboardFiltersSlice());
		store.dispatch(resetDashboardTransformSlice());
		store.dispatch(resetDashboardSortSlice());
	}
	
	// Let the action proceed
	return next(action);
};
export default dashboardMiddleware;