import { Button } from "@mui/material";

export const CreateButton = ({ isValid, handleModuleCreate }) => {
  return (
    <Button
      variant={"contained"}
      size={"small"}
      onClick={handleModuleCreate}
      disabled={!isValid}
    >
      Create
    </Button>
  );
};

export default CreateButton;
