import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { AppViewLoader, LoadingBar } from "../Loaders/AppViewLoader";
import * as Constants from "../../Constants";
import LogoutIcon from '@mui/icons-material/Logout';
import { setAlertNotificationArgs } from "../../store/alertsNotificationSlice";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";
import objInBody from "../../helpers/objInBody";
import {module_fields} from "../../store/modulesSlice";

const Loader = ({ moduleTag, error }) => {
	const dispatch = useDispatch();
	const cancelAction = () => {
		const logoutUrl = Constants.SERVER_LOGOUT_URL;
		dispatch({ type: "RESET" });
		localStorage.setItem("isLoggingOut", "true");
		window.open(logoutUrl, "_self");
	}
	const cancelText = "Logout"
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<LoadingBar cancelAction={cancelAction} cancelText={cancelText} cancelIcon={<LogoutIcon/>} />
			</Grid>
			<Grid item xs={12}>
				<AppViewLoader error={error.isErrored} errorMessage={error.message}/>
			</Grid>
		</Grid>
	)
}

const getModuleErrorNotificationArgs = (moduleTag, module, app, customerSubscriptionUuid, user) => {
	let subject;
	let body;
	if (!module) {
		subject = `${user} doesn't have access to module with tag matching ${moduleTag} (${window.location.origin} - ${iso8601Timestamp()})`;
		body = `${user} doesn't have access to module with tag matching ${moduleTag} (${window.location.href} - ${iso8601Timestamp()})`;
	} else if (!app) {
		subject = `${user} doesn't have access to app with uuid ${module.displayApps[0]} (${window.location.origin} - ${iso8601Timestamp()})`;
		body = `${user} doesn't have access to app with uuid ${module.displayApps[0]}<br/><br/>module: ${objInBody(module)}<br/><br/>(${window.location.href} - ${iso8601Timestamp()})`;
	} else {
		subject = `${user} doesn't have access to app subscription for app with uuid ${app.uuid} (${window.location.origin} - ${iso8601Timestamp()})`;
		body = `${user} doesn't have access to app subscription for app with uuid ${app.uuid}<br/><br/>app: ${objInBody(app)}<br/><br/>module: ${objInBody(module)}<br/><br/>(${window.location.href} - ${iso8601Timestamp()})`;
	}
	const tempNotificationArgs = {
		url: Constants.SERVER_SEND_EMAIL,
		method: "POST",
		body: JSON.stringify({
			"body": body,
			"subject": subject,
			"from": "noreply@wiselayer.com",
			"toEmail": Constants.notificationList.join(","),
			"sendHTML": true
		})
	}
	return tempNotificationArgs;
}

const Getter = ({ moduleTag, setError }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.role.name);
	const isInternal = useSelector((state) => state.role.isInternal);
	
	const modules = useSelector(state => state.modules.list);
	const modulesSubscriptions = useSelector(state => state.modules.subscriptionsList);
	
	const apps = useSelector(state => state.apps.filteredList);
	const subscribedApps = useSelector(state => state.appsSubscriptions.list);
	
	const module = modules.find(module => module.tags.includes(moduleTag));
	const app = apps.find(app => app.uuid === module[module_fields.displayApps][0]);
	const customerSubscriptionUuid = subscribedApps.find(sub => sub.developerAppUuid === app?.uuid)?.uuid;
	
	useEffect(() => {
		if (!module || !app || !customerSubscriptionUuid) {
			// handle error
			setError({
				isErrored: true,
				message: `You do not have access to this resource - the team has been notified and a fix is in the works`
			})
			if (!isInternal) {
				// send message to team that username is missing critical resource
				const tempNotificationArgs = getModuleErrorNotificationArgs(moduleTag, module, app, customerSubscriptionUuid, user);
				dispatch(setAlertNotificationArgs(tempNotificationArgs));
			}
		} else {
			if (!isInternal) {
				const moduleTitle = module[module_fields.fullName];
				const displayApps = module[module_fields.displayApps];
				const displayTitles = module[module_fields.displayTitles];
				const displayTitleIndex = displayApps.indexOf(app.uuid);
				const displayTitle = displayTitles[displayTitleIndex];
				
				const emailBody = `<strong>${user}</strong> viewing ${displayTitle} in ${moduleTitle} on ${window.location.origin}
					<br/><br/>Module:<br/>${objInBody(module)}
					<br/><br/>App:<br/>${objInBody(app)}`;
				
				const tempNotificationArgs = {
					url: Constants.SERVER_SEND_EMAIL,
					method: "POST",
					body: JSON.stringify({
						"body": emailBody,
						"subject": `${user} viewing ${displayTitle} in ${moduleTitle} (${window.location.origin} - ${iso8601Timestamp()})`,
						"from": "noreply@wiselayer.com",
						"toEmail": Constants.notificationList.join(","),
						"sendHTML": true
					})
				}
				dispatch(setAlertNotificationArgs(tempNotificationArgs));
			}
			// navigate to dashboard
			const tempDashboardState = {
				viewModule: true,
				module: module,
				moduleUuid: module.uuid,
				uuid: app.uuid,
				customerSubscriptionUuid: customerSubscriptionUuid,
				thisDevApp: app
			}
			const dashboardName = moduleTag.split("__")[1];
			console.log(`navigating to module w/ tag: ${moduleTag} -> tempDashboardState`, tempDashboardState);
			navigate(`/dashboard/${dashboardName}`, { state: tempDashboardState });
		}
	}, [moduleTag, module, app, customerSubscriptionUuid, user, isInternal])

	return null;
}

export const ModuleGetter = ({ moduleTag }) => {
	const [error, setError] = useState({
		isErrored: false,
		message: ""
	})
	return (
		<>
			<Loader moduleTag={moduleTag} error={error} />
			<Getter moduleTag={moduleTag} setError={setError} />
		</>
	)
}

export default ModuleGetter;