import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setAppsCategories } from "../../store/appsCategoriesSlice";


// this component dispatches the following to appCategoriesSlice:

// 1:
// summaryCategories (object) => {
//   "Profitability inflows": {
//     feedCategories: ["Bill high (vs proj)", "Payment early (vs permissible)"],
//     numberOfAlerts: 0,
//   },
//   "Profitability outflows": { ... },
//   ...
// }

// 2:
// feedCategories (Set) => ["Bill: PO disconnect", "Invoice: SO disconnect", ...] (all unique feed categories from all apps and all alerts - ideally there are no alerts which have a feed category not covered by an app tag)

// 3:
// numberOfAlertsByFeedCategory (object) =>
// { "Bill: PO disconnect": {
//      primaryFeed: 1,
//      main: 2,
//    },
//    "Invoice: SO disconnect": {
//      primaryFeed: 0,
//      main: 0,
//    },
//  ... }

// these values are consumed by SummaryModal and its children, HeaderTabs' NotificationFeedTab, and FeedTableFilters
// alert type, feed category, and alert.appNameDisplay are all synonymous
// summary categories serve as buckets to group the alert types for display in the SummaryModal

const updateSummaryCategoryFeedCategories = (curFeedCats, newFeedCats) => {
  // curFeedCats = array of strings
  // newFeedCats = array of strings
  // return new set of feed categories
  return [...new Set(curFeedCats.concat(newFeedCats))].sort()
}

export const AppsCategoriesManager = () => {
  const dispatch = useDispatch()
  const filteredApps = useSelector((state) => state.apps.filteredList);

  const alertFilter = useSelector((state) => state.alertsTabs.filters.Main);
  const alerts = useSelector((state) => state.alerts.alerts);

  const coverageCategories = useSelector((state) => state.appsCategories.coverageCategories);

  useEffect(() => {
    // console.log("AppsCategoriesManager triggered")
    const summaryCategories = {}
    const feedCategories = []
    const numberOfAlertsByFeedCategory = {}

    //---- First, we create an initial summaryCategories object with all the coverageCategories and define their default feedCategories and numberOfAlerts
    coverageCategories.forEach((category) => {
      summaryCategories[category] = {
        feedCategories: [],
        numberOfAlerts: {
          primaryFeed: 0,
          main: 0,
        }
      }
    })

    //---- Now we're ready to iterate through the filteredApps and update the summaryCategories object[s] with the appropriate feedCategories
    filteredApps.forEach((app) => {
      // each app will have it's summaryCategory and feedCategories in one or multiple tags (string) starting with "categories"
      // it will look like this: "categories__<summaryCat>__<feedCat1>" or "categories__<summaryCat>__<feedCat1>::<feedCat2>(etc)" for multiple feed categories
      const categories = app.tags.filter((tag) => tag.includes("categories__"))
      // if (categories.length === 0) console.log("app:", app.fullName, app.uuid, "has no categories tags")
      categories.forEach((category) => {
        const summary_category = category.split("__")[1] // Productivity (compliance & close)
        const feed_categories = category.split("__")[2]?.split("::") || [] // [Expense possibly misclassified vs proj, Expense possibly unaccrued]
        // console.log("app:", app.fullName, app.uuid, "summaryCategory:", summary_category, "feedCategories:", feed_categories);
        //---- 1. check if summary_category is already in summaryCategories
        const currentSummaryCategory = summaryCategories[summary_category]
        // console.log("summaryCategories", summaryCategories)
        // console.log("currentSummaryCategory", currentSummaryCategory);
        if (currentSummaryCategory) {
          //---- update the feedCategories for this summary_category
          summaryCategories[summary_category].feedCategories = updateSummaryCategoryFeedCategories(currentSummaryCategory.feedCategories, feed_categories)
        } else {
          //---- 2. if summary_category is not already in summaryCategories, add it to summaryCategories and set feedCategories and numberOfAlerts
          summaryCategories[summary_category] = {
            feedCategories: updateSummaryCategoryFeedCategories([], feed_categories),
            numberOfAlerts: {
              primaryFeed: 0,
              main: 0,
            }
          }
        }
        feedCategories.push(feed_categories)
      })
    })
    /*
    summaryCategories should look like...
    {
      "Fraud, scams & professional risks": {
        feedCategories: ["Large bill unapproved", "Suspicious bill"],
        numberOfAlerts: 0,
      },
      "Dollars saved (expenses & payments)": {
        feedCategories: ["Bill high (vs proj)", "Payment early (vs permissible)"],
        numberOfAlerts: 0,
       },
      ...
    }
    feedCategories should look like...
    [
      [
        "Large bill unapproved",
        "Suspicious bill"
      ],
      [
        "Bill high (vs proj)",
        "Payment early (vs permissible)",
      ],
      ...
    ]
    */

    //---- Next, we create an array of released alerts that have not had actions performed on them - this will be used to produce the numberOfAlertsByFeedCategory object
    const releasedAlerts = alertFilter(alerts).filter((alert) => alert.flagAiRelevantStatus === "NOT_SET");

    //---- Now we're ready to iterate through the releasedAlerts and update the numberOfAlertsByFeedCategory object with the appropriate counts
    let uniqueFeedCategories = [...new Set(feedCategories.flat().sort())]
    uniqueFeedCategories.forEach((category) => {
      numberOfAlertsByFeedCategory[category] = {
        primaryFeed: 0,
        main: 0,
      }
    });
    releasedAlerts.forEach((alert) => {
      // If an alert/alertType is not found in numberOfAlertsByFeedCategory, that means that it does not match any app tags
      // in this case, the following needs to happen:
      // 1. the alert/alertType needs to be added to numberOfAlertsByFeedCategory
      // 2. the alert/alertType needs to be added to summaryCategories, specifically to "Efficiency (compliance & close)" as a fallback
      const alertType = alert.appNameDisplay;
      // Check if the alertType property exists; if not, initialize it to 0 before incrementing
      if (numberOfAlertsByFeedCategory[alertType] === undefined) {
        numberOfAlertsByFeedCategory[alertType] = {
          primaryFeed: 0,
          main: 0,
        }
        //so far we've satisfied the first condition, now we need to satisfy the second condition
        // Efficiency (compliance & close) is the fallback category, and it already exists by this point, so we just need to update the feedCategories (numberOfAlerts will be updated later)
        // updateSummaryCategoryFeedCategories(currentSummaryCategory.feedCategories, feed_categories) needs to be used here
        summaryCategories["Efficiency (compliance & close)"].feedCategories = updateSummaryCategoryFeedCategories(summaryCategories["Efficiency (compliance & close)"].feedCategories, [alertType])
        // we also need to add the alertType to the uniqueFeedCategories array (feedCategories) - but it needs to maintain its alphabetical order
        uniqueFeedCategories = [...new Set([...uniqueFeedCategories, alertType].sort())]
      }
      // increment the alert count for this alertType
      if (alert.inPrimaryFeed) {
        numberOfAlertsByFeedCategory[alertType].primaryFeed += 1;
      }
      numberOfAlertsByFeedCategory[alertType].main += 1;
    });

    //---- Now that we have number of alerts by feed category...
    //---- we can update the summaryCategories object with the appropriate sum of alerts within that category as summaryCategories[summaryCategory].numberOfAlerts
    Object.entries(summaryCategories).forEach((category) => {
      const specs = category[1]
      const feedCategoriesForThisSummaryCategory = specs.feedCategories
      feedCategoriesForThisSummaryCategory.forEach((feedCategory) => {
        if (numberOfAlertsByFeedCategory[feedCategory]) {
          specs.numberOfAlerts.primaryFeed += numberOfAlertsByFeedCategory[feedCategory].primaryFeed
          specs.numberOfAlerts.main += numberOfAlertsByFeedCategory[feedCategory].main
        }
      })
    })

    // this ensures FeedTableFilters has all the feed categories - not just inPrimaryFeed alerts
    uniqueFeedCategories = [...new Set(uniqueFeedCategories.concat([...alerts.map((alert) => alert.appNameDisplay)]))].sort()

    console.warn("summaryCategories", summaryCategories, "feedCategories", uniqueFeedCategories, "numberOfAlertsByFeedCategory", numberOfAlertsByFeedCategory)
    dispatch(setAppsCategories({
      summaryCategories: summaryCategories,
      feedCategories: uniqueFeedCategories,
      numberOfAlertsByFeedCategory: numberOfAlertsByFeedCategory
    }))
  }, [filteredApps, alerts, alertFilter, coverageCategories])
  return null;
}

export default AppsCategoriesManager;