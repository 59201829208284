import {
	FormControl,
	FormControlLabel,
	InputLabel,
	Radio,
	Select,
	MenuItem,
	Grid
} from "@mui/material";
import { RadioButtonUnchecked, RadioButtonChecked } from '@mui/icons-material';
import { view_options } from "../hooks/useToolbar";
import DirectRequest from "../../../../API/requests/DirectRequest";
import ImpactBar from "../../../ImpactBar/ImpactBar";
import getSourceTag from "../../../../helpers/getSourceTag";
import { module_fields } from "../../hooks/useModules";
import { 
	newPayload,
	payload_fields,
	toolbar_fields
} from "../hooks/useToolbar";
import {useTheme} from '@mui/material/styles';
import { useMemo } from "react";

const getLinkConfigSysAdminTitle = (tags) => {
	console.log("tags", tags)
	const linkConfigTag = tags.find(tag => tag.includes("link_config_sysadmin__"));
	return linkConfigTag.split("__")[1].split("_").join(" ");
}

const getTitle = (linkConfigToggle, app) => {
	return linkConfigToggle ? getLinkConfigSysAdminTitle(app.tags) : app.fullName;
}

const isLinkConfigApp = (tags) => {
	return tags?.some(tag => tag.includes("link_config__") || tag.includes("link_config_sysadmin__")) ?? false
}

const SelectApp = ({
	linkConfigToggle,
	selectedAppUuid,
	selectedModuleUuid,
	allApps,
	appsSubscriptions,
	modules,
	handleChange
}) => {
	const onChange = (e) => {
		const appUuid = e.target.value ?? "";
		const payload = { 
			...newPayload,
			[payload_fields.newValue]: appUuid,
			[payload_fields.field]: toolbar_fields.appUuid
		};
		handleChange(payload)
	}
	
	// const subscribedApps = allApps.filter(app => appsSubscriptions.some(sub => sub.developerAppUuid === app.uuid))
	// 
	// const apps = linkConfigToggle 
	// 	? subscribedApps.filter(app => isLinkConfigApp(app.tags))
	// 	: subscribedApps
	// Memoize the filtered apps to prevent unnecessary recalculations
	const apps = useMemo(() => {
		const subscribedApps = allApps.filter(app => 
			appsSubscriptions.some(sub => sub.developerAppUuid === app.uuid)
		);
		
		if (linkConfigToggle) {
			return subscribedApps.filter(app => isLinkConfigApp(app.tags))
		}
		
		if (selectedModuleUuid) {
			const selectedModule = modules.find(module => module[module_fields.uuid] === selectedModuleUuid)
			return subscribedApps.filter(app => selectedModule[module_fields.displayApps].includes(app.uuid))
		}
		
		return subscribedApps;
	}, [allApps, appsSubscriptions, linkConfigToggle, selectedModuleUuid, modules]);
	
	return (
		<FormControl sx={{ width: "350px" }} size={"small"}>
			<InputLabel id={"app-select-label"}><i>App</i></InputLabel>
			<Select
				id="app-select"
				labelId={"app-select-label"}
				margin="dense"
				onChange={onChange}
				value={selectedAppUuid || ""}
				label={"App"}
			>
				{apps.map((app, index) => {
					return (
						<MenuItem value={app.uuid} key={index}>
							{app.uuid}&nbsp;<strong>{app.fullName}</strong>&nbsp;<i>{getSourceTag(app.tags)}</i>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	)
}

const LinkConfigToggle = ({
	linkConfigToggle,
	handleChange
}) => {
	
	// const onChange = (event) => {
	// 	console.log('Radio onChange fired', event.target.checked);
	// 	const payload = { 
	// 		...newPayload,
	// 		[payload_fields.newValue]: event.target.checked,
	// 		[payload_fields.field]: toolbar_fields.linkConfigToggle
	// 	};
	// 	handleChange(payload)
	// }
	// 
	// return (
	// 	<FormControlLabel
	// 		value="linkConfigToggle"
	// 		control={
	// 			<Radio
	// 				checked={linkConfigToggle}
	// 				onChange={onChange}  // Changed from onClick to onChange and passing the event
	// 				size="small"
	// 				disableRipple
	// 				sx={{
	// 					paddingLeft: '10px',
	// 					paddingRight: "5px",
	// 					'& .MuiSvgIcon-root': {
	// 						fontSize: 18,
	// 					},
	// 					'&:hover': {
	// 						backgroundColor: 'transparent',
	// 					}
	// 				}}
	// 			/>
	// 		}
	// 		label="link_config apps"
	// 	/>
	// )
	const theme = useTheme()
	
	const onClick = () => {
		const payload = { 
			...newPayload,
			[payload_fields.newValue]: !linkConfigToggle,
			[payload_fields.field]: toolbar_fields.linkConfigToggle
		};
		handleChange(payload)
	}
	
	return (
		<div 
			onClick={onClick}
			style={{ 
				display: 'flex', 
				alignItems: 'center', 
				cursor: 'pointer',
				userSelect: 'none'
			}}
		>
			{linkConfigToggle ? (
				<RadioButtonChecked 
					sx={{ 
						fontSize: 28,
						marginLeft: "-15px",
						paddingLeft: '5px',
						paddingRight: '5px',
						color: theme.palette.primary.main
					}} 
				/>
			) : (
				<RadioButtonUnchecked 
					sx={{ 
						fontSize: 28,
						marginLeft: "-15px",
						paddingLeft: '5px',
						paddingRight: '5px'
						// todo: grey
					}} 
				/>
			)}
			<span>link_config apps</span>
		</div>
	)
}

const SelectModule = ({
	selectedModuleUuid,
	modules,
	modulesSubscriptions,
	handleChange
}) => {
	
	const onChange = (e) => {
		const moduleUuid = e.target.value ?? "";
		const payload = { 
			...newPayload,
			[payload_fields.newValue]: moduleUuid,
			[payload_fields.field]: toolbar_fields.moduleUuid
		};
		handleChange(payload)
	}
	
	return (
		<FormControl sx={{ width: "450px" }} size={"small"}>
			<InputLabel id={"module-select-label"}><i>Module</i></InputLabel>
			<Select
				id="module-select"
				labelId={"module-select-label"}
				margin="dense"
				onChange={onChange}
				value={selectedModuleUuid || ""}
				label={"Module"}
			>
				{modules
					.filter(module => modulesSubscriptions.some(sub => sub.moduleUuid === module.uuid))
					.map((module, index) => {
						return (
							<MenuItem value={module.uuid} key={index}>
								{module.uuid}&nbsp;<strong>{module.fullName}</strong>&nbsp;<i>{getSourceTag(module.tags)}</i>
							</MenuItem>
						);
					}
				)}
			</Select>
		</FormControl>
	)
}

export const Toolbar = ({
	allApps,
	appsSubscriptions,
	modules,
	modulesSubscriptions,
	toolbarState,
	handleChange
}) => {
	return (
		<Grid item xs={12}>
			<ImpactBar
				component={
					<Grid container spacing={2} alignItems={"center"}>
						<Grid item>
							{/* app selection */}
							<SelectApp
								linkConfigToggle={toolbarState[toolbar_fields.linkConfigToggle]}
								selectedAppUuid={toolbarState[toolbar_fields.appUuid]}
								selectedModuleUuid={toolbarState[toolbar_fields.moduleUuid]}
								allApps={allApps}
								appsSubscriptions={appsSubscriptions}
								modules={modules}
								handleChange={handleChange}
							/>
						</Grid>
						<Grid item>
							{/* linkConfig toggle - should be temporary as we transition fully to modules */}
							<LinkConfigToggle
								linkConfigToggle={toolbarState[toolbar_fields.linkConfigToggle]}
								handleChange={handleChange}
							/>
						</Grid>
						<Grid item>
							{/* module selection */}
							<SelectModule
								selectedModuleUuid={toolbarState[toolbar_fields.moduleUuid]}
								modules={modules}
								modulesSubscriptions={modulesSubscriptions}
								handleChange={handleChange}
							/>
						</Grid>
					</Grid>
				}
			/>
		</Grid>
	)
}

export default Toolbar;