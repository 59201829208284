import { useState } from "react";
import { useModuleForm, form_fields } from "./useModuleForm";
import * as Constants from "../../../../Constants";

export const useModuleCreate = (handleModulesRefresh) => {
  const { formState, isValid, showForm, toggleShowForm, handleFormUpdate } =
    useModuleForm();

  const [moduleCreateArgs, setModuleCreateArgs] = useState(null);

  const handleModuleCreate = () => {
    /* TODO */
    // setModuleCreateArgs()
    const tempArgs = {
      url: Constants.SERVER_SYSADMIN_CREATE_MODULE_URL,
      method: "POST",
      body: JSON.stringify({
        ...formState,
        // JSON.parse() appDependencyGraph, displayApps, displayTitles, tags, hideFromCompanyUuids, visibleToCompanyUuids
        [form_fields.appDependencyGraph]: JSON.parse(formState[form_fields.appDependencyGraph]),
        [form_fields.displayApps]: JSON.parse(formState[form_fields.displayApps]),
        [form_fields.displayTitles]: JSON.parse(formState[form_fields.displayTitles]),
        [form_fields.tags]: JSON.parse(formState[form_fields.tags]),
        [form_fields.hideFromCompanyUuids]: JSON.parse(formState[form_fields.hideFromCompanyUuids]),
        [form_fields.visibleToCompanyUuids]: JSON.parse(formState[form_fields.visibleToCompanyUuids])
      })
    }
    setModuleCreateArgs(tempArgs)
  };

  const handleModuleCreateSuccess = (res) => {
    /* TODO */
    // handleModulesRefresh()
    setModuleCreateArgs(null);
    toggleShowForm();
    handleModulesRefresh();
  };
  console.log("useModuleCreate- formState", formState);
  return {
    moduleCreateArgs,
    formState,
    isValid,
    showForm,
    toggleShowForm,
    handleFormUpdate,
    handleModuleCreate,
    handleModuleCreateSuccess,
  };
};
