import { useAppsSubscriptions } from "../../hooks/useAppsSubscriptions";
import { useModules, module_fields } from "../../hooks/useModules";
import { useModulesSubscriptions } from "../../hooks/useModulesSubscriptions";
import { useSelector } from "react-redux";
import { useState } from "react";


//link_config_apps: "link_config_apps", -> this should be a toggle
export const view_options = {
	app: "app",
	module: "module"
}

// for this view - i can filter apps by tags, and by inclusion in modules
// show subscribedApps select - show link_config radio (filters for link_config__ && link_config_sysadmin__)
// show subscribedModules
// show clear icon - clears selectedAppUuid && selectedModuleUuid

// selecting appUuid does as currently does
// selecting moduleUuid checks if app is in displayApps - leaves if is, otherwise clears selectedAppUuid
// linkConfigToggle clears moduleUuid and appUuid if appUuid is not in new options
const initialState = {
	appUuid: "",
	moduleUuid: "",
	linkConfigToggle: false,
}

export const toolbar_fields = {
	appUuid: "appUuid",
	moduleUuid: "moduleUuid",
	linkConfigToggle: "linkConfigToggle",
}

export const payload_fields = {
	newValue: "newValue",
	field: "field",
	resetFlag: "resetFlag"
}

export const newPayload = {
	newValue: null,
	field: null,
	resetFlag: false
}

export const useToolbar = (selectedCompany) => {
	const [viewOption, setViewOption] = useState(view_options.link_config_apps);
	const allApps = useSelector((state) => state.appsSysAdmin.list);
	const [toolbarState, setToolbarState] = useState(initialState);
	
	const {
		appsSubscriptions,
		appsSubscriptionsArgs,
		handleAppsSubscriptions
	} = useAppsSubscriptions(selectedCompany);
	
	const {
		modules,
		modulesArgs,
		handleModules,
		//refreshModules//not needed
	} = useModules();
	
	const {
		modulesSubscriptions,
		modulesSubscriptionsArgs,
		handleModulesSubscriptions,
		// refreshModulesSubscriptions// not needed
	} = useModulesSubscriptions(selectedCompany);
	
	const handleChange = (payload) => {
		console.log("payload", payload)
		const {
			newValue,
			field,
			resetFlag
		} = payload
		if (newValue === null || field === null) {
			console.warn("handleChange payload fields cannot be null")
			return;
		}
		setToolbarState(prevState => {
			if (resetFlag) {
				return initialState
			}
			if (field === toolbar_fields.moduleUuid) {
				// selecting moduleUuid checks if app is in displayApps - leaves if is, otherwise clears selectedAppUuid
				const appIsInModule = modules
					.find(module => module.uuid === newValue)
					?.[module_fields.displayApps]
					?.includes(prevState[toolbar_fields.appUuid])
				
				const newAppUuid = appIsInModule 
					? prevState[toolbar_fields.appUuid] 
					: initialState[toolbar_fields.appUuid]
				
				return {
					...prevState,
					[toolbar_fields.linkConfigToggle]: false,
					[toolbar_fields.appUuid]: newAppUuid,
					[field]: newValue
				}
			}
			// linkConfigToggle clears moduleUuid and appUuid if appUuid is not in new options
			if (field === toolbar_fields.linkConfigToggle) {
				const newModuleUuid = newValue 
					? initialState[toolbar_fields.moduleUuid]
					: prevState[toolbar_fields.moduleUuid]
				
				const isLinkConfigApp = allApps.find(app => app.uuid === prevState[toolbar_fields.appUuid])
					?.tags.some(tag => tag.includes("link_config__") || tag.includes("link_config_sysadmin__"))
				
				const newAppUuid = newValue 
					? isLinkConfigApp ? prevState[toolbar_fields.appUuid] : ""
					: prevState[toolbar_fields.appUuid]
				
				return {
					...prevState,
					[toolbar_fields.moduleUuid]: newModuleUuid,
					[toolbar_fields.appUuid]: newAppUuid,
					[field]: newValue
				}  
			}
			return {
				...prevState,
				[field]: newValue
			}
		})
	}
	
	return {
		allApps,
		appsSubscriptions,
		appsSubscriptionsArgs,
		handleAppsSubscriptions,
		modules,
		modulesArgs,
		handleModules,
		modulesSubscriptions,
		modulesSubscriptionsArgs,
		handleModulesSubscriptions,
		toolbarState,
		handleChange
	}
}