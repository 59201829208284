import { useSelector, useDispatch } from 'react-redux';
import {addCustomerSub, customerSubsSelectors, removeCustomerSub} from "../../store/customerSubsSlice";
import React, {useEffect, useMemo} from "react";
import CustomerSubListenerRequest from "../../API/requests/CustomerSubListenerRequest";

const CustomerSubManager = ({ customerSubscription }) => {
  const dispatch = useDispatch();
  const customerSub = useSelector((state) => customerSubsSelectors.selectById(state, customerSubscription?.uuid));

  // create customerSub if no customerSub and subscription is not complete
  useEffect( () => {
    if (customerSub === undefined && customerSubscription && customerSubscription.uuid && !customerSubscription.isSetupComplete) {
      console.log("CustomerSubManager -- creating customerSub for ", customerSubscription.uuid);
      dispatch(addCustomerSub(customerSubscription.uuid));
    }
  }, [customerSub, customerSubscription]);

  // remove customerSub if customerSub and subscription is complete
  useEffect(() => {
    if (customerSub && customerSubscription.isSetupComplete) {
      dispatch(removeCustomerSub(customerSubscription.uuid));
    }
  }, [customerSub, customerSubscription])

  // trigger listener if customerSub and !isListening
  return customerSub && !customerSub.isListening ? (
    <CustomerSubListenerRequest
      customerSubscription={customerSubscription}
    />
  ) : null;
};

export const CustomerSubsManager = () => {
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  const subscriptionsReady = useSelector((state) => state.appsSubscriptions.isReady);
  
  const modules = useSelector((state) => state.modules.list);
  const modulesSubscriptions = useSelector((state) => state.modules.subscriptionsList);
  const modulesReady = useSelector((state) => state.modules.isReady);
  // console.log("subscribedApps", subscribedApps)
  const nonModuleSubscribedApps = useMemo(() => {
    if (subscriptionsReady && modulesReady) {
      // console.log("CustomerSubsManager")
      // console.log(" - subscribedApps", subscribedApps)
      // console.log(" - modulesSubscriptions", modulesSubscriptions)
      const subscribedModules = modules.filter(module => {
        return modulesSubscriptions.map(moduleSubscription => moduleSubscription.moduleUuid).includes(module.uuid);
      });
      // console.log(" - subscribedModules", subscribedModules)
      // now get module.appDependencyGraph for each module
      const subscribedModulesApps = [];
      subscribedModules.forEach(module => {
        Object.entries(module.appDependencyGraph).forEach(([parentApp, childAppArr]) => {
          subscribedModulesApps.push([parentApp, ...childAppArr]);
        })
      })
      // console.log(" - subscribedModulesApps", subscribedModulesApps)
      const uniqueModulesApps = [...new Set(subscribedModulesApps.flat())]
      // console.log(" - uniqueModulesApps", uniqueModulesApps)
      const subscriptionsToReturn =  subscribedApps.filter(appSub => !uniqueModulesApps.includes(appSub.developerAppUuid))
      console.log("CustomerSubsManager - nonModuleSubscribedApps", subscriptionsToReturn)
      return subscriptionsToReturn;
    } else {
      return null;
    }
  }, [subscribedApps, subscriptionsReady, modules, modulesSubscriptions, modulesReady])
  return (
    <>
      {nonModuleSubscribedApps && nonModuleSubscribedApps.length > 0 && nonModuleSubscribedApps.map((subscription) => {
        return <CustomerSubManager key={subscription.uuid} customerSubscription={subscription} />
      })}
    </>
  )
}
/*
// old
export const CustomerSubsManager = () => {
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  // console.log("subscribedApps", subscribedApps)
  return (
    <>
      {subscribedApps.length > 0 && subscribedApps.map((subscription) => {
        return <CustomerSubManager key={subscription.uuid} customerSubscription={subscription} />
      })}
    </>
  )
}
*/

export default CustomerSubsManager;