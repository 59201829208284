import { Button } from "@mui/material";
import { useState, useEffect } from "react";

export const DeleteButton = ({ handleModuleDelete }) => {
  const [confirm, setConfirm] = useState(false);
  
  useEffect(() => {
    let timeoutId;
    if (confirm) {
      timeoutId = setTimeout(() => {
        setConfirm(false);
      }, 3000); // Reset after 3 seconds
    }
    return () => clearTimeout(timeoutId);
  }, [confirm]);
  
  const handleClick = () => {
    if (confirm) {
      handleModuleDelete()
    } else {
      setConfirm(true)
    }
  }
  
  return (
    <Button
      variant={"outlined"}
      color={"error"}
      size={"small"}
      onClick={handleClick}
      disableRipple
    >
      {confirm ? "Confirm delete" : "Delete"}
    </Button>
  );
};

export default DeleteButton;
