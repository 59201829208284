import React from 'react';
import AiMessageInput from '../Inputs/AiMessageInput';
import { useTheme } from "@mui/material/styles";

const DashboardChat = ({ moduleUuid }) => {
	const theme = useTheme();
	return (
		<div style={{
			position: 'fixed',
			bottom: '16px',
			right: '16px',
			zIndex: 1000
		}}>
			<div style={{ 
				position: 'relative',
				backgroundColor: "white",
				borderRadius: '50%',
				boxShadow: '0 2px 12px rgba(0, 0, 0, 0.15)',
				// padding: '2px'
			}}>
				<AiMessageInput moduleUuid={moduleUuid} />
			</div>
		</div>
	);
};

export default DashboardChat;