/*///////////////////////////////////////////////////////////////////
 3 exports:
 1. NotificationFeedTab - compatible with path === '/' location
 2. SettingsTab - compatible with path === '/settings' location
 3. SysAdminTab - compatible with path === '/sysadmin' sysAdminLocation

 Original ListItemLink in Header.js was:

   function ListItemLink(props) {

    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef(function Link(itemProps, ref) {
          return <RouterLink to={{pathname: to}} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to]
    );

    return (
      <li>
        <ListItem button disableRipple component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

In order to support dropdown tabs, I created the tab components below from the original ListItemLink structure.
Note: there is probably a much cleaner/DRY way to do this, but it works for now - Eric 1/2/2024
 */
///////////////////////////////////////////////////////////////////*/

import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {useLayoutEffect, useState} from "react";
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import {setSelectedTab} from "../../store/alertsTabsSlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { setModalState } from "../../store/dashboardEditorSlice";
import {generateAlertsTabSelectionNotificationArgs, generateSettingsTabSelectionNotificationArgs} from "../AlertFeed/FeedTableUtils";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import TocIcon from "@mui/icons-material/Toc";
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const CustomLink = React.forwardRef(({ to, onClick, ...props }, ref) => {
  const dispatch = useDispatch();
  const isFormDirty = useSelector((state) => state.dashboardEditor.isDirty);

  const handleClick = (e) => {
    if (isFormDirty) {
      e.preventDefault(); // Prevent navigation
      // Dispatch an action to show the modal, and pass the intended path
      dispatch(setModalState({ open: true, intendedPath: to }));
    } else {
      // If there's an additional onClick provided, call it
      if (onClick) onClick(e);
    }
  };

  return <RouterLink to={to} ref={ref} onClick={handleClick} {...props} />;
});

const useConditionalNavigate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFormDirty = useSelector((state) => state.dashboardEditor.isDirty);

  const conditionalNavigate = (path) => {
    if (isFormDirty) {
      // Dispatch an action to show the modal, and pass the intended path
      dispatch(setModalState({ open: true, intendedPath: path }));
    } else {
      navigate(path);
    }
  };

  return conditionalNavigate;
}

const NotificationFeedTabText = () => {
  const primaryFeed = useSelector((state) => state.alertsTabs.primaryFeed);
  const numberOfAlertsByFeedCategory = useSelector(state => state.appsCategories.numberOfAlertsByFeedCategory)
  const getTotal = (numberOfAlertsByFeedCategory, primaryFeed) => {
    let total = 0;
    for (const key in numberOfAlertsByFeedCategory) {
      total += primaryFeed ? numberOfAlertsByFeedCategory[key].primaryFeed : numberOfAlertsByFeedCategory[key].main;
    }
    return total;
  }
  const total = getTotal(numberOfAlertsByFeedCategory, primaryFeed);
  return (
    <>
      {total > 0 && <Typography>Alerts ({total})</Typography>}
      {total === 0 && <Typography>Alerts</Typography>}
    </>
  )
};

export const NotificationFeedTab = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();

  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector(state => state.role.isInternal);

  const selectedTab = useSelector((state) => state.alertsTabs.selectedTab);
  const disabled = useSelector((state) => state.alertsTabs.disabled);

  const [subTabsOpen, setSubTabsOpen] = useState(false);

  const { icon, primary, to, subTabs, subTabsContent, selectTab } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <CustomLink to={{pathname: to}} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  const nonNavigatingLink = React.forwardRef((itemProps, ref) => (
    <span ref={ref} {...itemProps} role="button" />
  ));

  useLayoutEffect(() => {
    if (selectedTab !== 'Main') {
      setSubTabsOpen(true);
    }
  }, [selectedTab])

  const toggleSubTabs = () => {
    setSubTabsOpen(!subTabsOpen);
  }

  const pickIcon = (to, icon, subTabsOpen) => {
    if (to === "/") {
      return (
        <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center', marginLeft: '0.25rem' }}>

          <div style={{ position: 'absolute', left: '-18px', top: '5%', zIndex: 1 }}>
            {subTabsOpen ? (
              <ExpandMoreIcon sx={{ "&:hover": { fill: theme.palette.primary.main }}} />
            ) : (
              <ChevronRightIcon sx={{ "&:hover": { fill: theme.palette.primary.main }}} />
            )}
          </div>

          {icon}
        </div>
      );
    } else {
      return icon;
    }
  };

  const pickPrimary = (to, primary) => {
    if (to === "/") {
      return <NotificationFeedTabText />;
    } else {
      return primary;
    }
  }

  const subIconText = (tab, selectedTab, location, to) => {
    const style = tab.selectTab === selectedTab && location.pathname === to
      ? {
        color: theme.palette.primary.main,
        fontSize: "10px !important",
        alignItems: "center",
        display: "flex",
        "& svg": {fontSize: "14px", fill: `${theme.palette.primary.main} !important`}
      }
      : {fontSize: "10px !important", alignItems: "center", display: "flex", "& svg": {fontSize: "14px"}}
    if (tab.selectTab === 'Main') {
      return <Typography sx={style}><strong>Inbox:</strong>&nbsp;unread + read (&nbsp;{<CheckCircleOutlineIcon/>}&nbsp;)</Typography>
    } else if (tab.selectTab === "Unread") {
      return <Typography sx={style}><strong>Unread only</strong></Typography>
    } else if (tab.selectTab === "Resolved") {
      return <Typography sx={style}><strong>Previous:</strong>&nbsp;resolved in system</Typography>
    } else if (tab.selectTab === "Irrelevant") {
      return <Typography sx={style}><strong>Removed:</strong>&nbsp;(&nbsp;{<VisibilityOffIcon/>}&nbsp;)</Typography>
    } else {
      return tab.label
    }
  }

  const handleSubTabClick = (tab) => {
    dispatch(setSelectedTab(tab));
    if (!isInternal) {
      const tabMap = {
        Main: "Inbox",
        Unread: "Unread only",
        Resolved: "Previous",
        Irrelevant: "Removed"
      }
      const tempAlertsTabSelectionNotificationArgs = generateAlertsTabSelectionNotificationArgs(tabMap[tab], user);
      dispatch(setAlertNotificationArgs(tempAlertsTabSelectionNotificationArgs));
    }
  }
  // NOTE: renderLink does the navigation part - the onClick is for filtering alerts via the alertsTabs.selectedTab state
  return (
    <li>
      <ListItem button disableRipple component={nonNavigatingLink} onClick={selectTab ? (e) => {dispatch(setSelectedTab(selectTab)); setSubTabsOpen((prevSubTabsOpen) => !prevSubTabsOpen); } : null}>
        {icon ? <ListItemIcon>{pickIcon(to, icon, subTabsOpen)}</ListItemIcon> : null}
        <ListItemText primary={pickPrimary(to, primary)} />
      </ListItem>
      {subTabs && subTabsOpen && subTabsContent.map((tab, index) => (
        <ListItem
          key={index}
          button
          disableRipple
          component={renderLink}
          onClick={(e) => disabled ? null : handleSubTabClick(tab.selectTab)}
          sx={{ maxHeight: "30px", fontSize: "10px" }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                //marginLeft: "0.5rem",
                marginRight: "-1.5rem",
                "& svg": tab.selectTab === selectedTab && location.pathname === to ? {
                  fontSize: "20px",
                  fill: `${theme.palette.primary.main} !important`
                }  : { fontSize: "20px" }
              }}
            >
              {tab.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={subIconText(tab, selectedTab, location, to)}
          />
        </ListItem>
      ))}
    </li>
  );
}

const LinkConfigAppTab = ({ app, subscribedApps }) => {
  const nonNavigatingLink = React.forwardRef((itemProps, ref) => (
    <span ref={ref} {...itemProps} role="button" />
  ));

  const getLinkConfigTitle = (tags) => {
    const linkConfigTag = tags.find(tag => tag.includes("link_config__"));
    return linkConfigTag.split("__")[1].split("_").join(" ");
  }

  const handleClick = () => {
    localStorage.setItem("dashboardState", JSON.stringify({
      customerSubscriptionUuid: subscribedApps.find((subbedApp) => subbedApp.developerAppUuid === app.uuid).uuid,
      viewApp: true,
      //flagFilterComponentName: row.flagFilterComponentName,
      //flagFilterInput: row.flagFilterInput,
      //thisAlert: row,
      thisDevApp: app,
      //companyUuid: companyUuid, // will be needed for sysadmin
      //currentTab: currentTab,
      //readyForCustomer: readyForCustomer
    }));
    window.open(`${window.location.origin}/redirect/${app.uuid}`, '_blank');
  }

  return (
    <li>
      <ListItem
        button
        disableRipple
        component={nonNavigatingLink}
        onClick={handleClick}
      >
        <ListItemIcon >
          <div
          style={{position: 'relative', display: 'inline-flex', alignItems: 'center', marginLeft: '0.25rem'}}
          >
            <TocIcon />
          </div>
        </ListItemIcon>
        <ListItemText primary={getLinkConfigTitle(app.tags)}/>
      </ListItem>
    </li>
  );
}

export const LinkConfigAppTabs = () => {
  const postSetupComplete = useSelector(state => state.role.postSetupComplete); // def return null if !postSetupComplete
  const apps = useSelector(state => state.apps.filteredList);
  const subscribedApps = useSelector(state => state.appsSubscriptions.list);
  const linkConfigApps = apps.filter(app => {
    let linkConfigFlag = false;
    app.tags.forEach((tag) => {
      if (tag.includes("link_config__")) {
        linkConfigFlag = true
      }
    })
    let subscribedAppFlag;
    subscribedAppFlag = subscribedApps.some((subbedApp) => subbedApp.developerAppUuid === app.uuid);
    return linkConfigFlag && subscribedAppFlag;
  })
  return postSetupComplete ? (
    <>
      {linkConfigApps.map((app) => (
        <LinkConfigAppTab
          key={app.uuid + "_link"}
          app={app}
          subscribedApps={subscribedApps}
        />
      ))}
    </>
  ) : null;
}

const ModuleTab = ({module}) => {
  const nonNavigatingLink = React.forwardRef((itemProps, ref) => (
    <span ref={ref} {...itemProps} role="button" />
  ));
  
  const getLinkConfigTitle = (tags) => {
    const linkConfigTag = tags.find(tag => tag.includes("link_config__"));
    return linkConfigTag.split("__")[1].split("_").join(" ");
  }
  
  const handleClick = () => {
    // WIP
    // localStorage.setItem("dashboardState", JSON.stringify({
    //   viewModule: true,
    //   moduleUuid: module.uuid
    // }));
    // window.open(`${window.location.origin}/redirect/${app.uuid}`, '_blank');
    /* TODO */
    // probably need to redirect to different url - perhaps /module/redirect/uuid - do we even need dashboardState?
    // should we have a sysadmin redirect to module/redirect/uuid/companyUuid?
    window.open(`${window.location.origin}/module/redirect/${module.uuid}`, '_blank');
  }
  
  
  
  return (
    <li>
      <ListItem
        button
        disableRipple
        component={nonNavigatingLink}
        onClick={handleClick}
      >
        <ListItemIcon >
          <div
          style={{position: 'relative', display: 'inline-flex', alignItems: 'center', marginLeft: '0.25rem'}}
          >
            <ViewModuleIcon />
          </div>
        </ListItemIcon>
        <ListItemText primary={module.fullName}/>
      </ListItem>
    </li>
  );
}

export const ModulesTabs = () => {
  const postSetupComplete = useSelector(state => state.role.postSetupComplete);
  const modules = useSelector((state) => state.modules.list);
  const modulesSubscriptions = useSelector((state) => state.modules.subscriptionsList);
  
  const subscribedModules = modules.filter(module => {
    return modulesSubscriptions.map(moduleSubscription => moduleSubscription.moduleUuid).includes(module.uuid);
  });
  
  return postSetupComplete ? (
    <>
      {subscribedModules.map(module => (
        <ModuleTab
          key={module.uuid + "_module_link"}
          module={module}
        />
      ))}
    </>
  ) : null;
}

export const SettingsTab = (props) => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.role.isAdmin);
  const theme = useTheme();
  const location = useLocation();
  const conditionalNavigate = useConditionalNavigate();
  const [selected, setSelected] = useState(false);
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector(state => state.role.isInternal);

  const [subTabsOpen, setSubTabsOpen] = useState(false);

  const { icon, primary, to, subTabs, subTabsContent } = props;

  const nonNavigatingLink = React.forwardRef((itemProps, ref) => (
    <span ref={ref} {...itemProps} role="button" />
  ));

  const pickIcon = (to, icon, subTabsOpen) => {
    if (to === "/settings") {
      return (
        <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center', marginLeft: '0.25rem' }}>

          <div style={{ position: 'absolute', left: '-18px', top: '2%', zIndex: 1 }}>
            {subTabsOpen ? (
              <ExpandMoreIcon sx={{ "&:hover": { fill: theme.palette.primary.main }}} />
            ) : (
              <ChevronRightIcon sx={{ "&:hover": { fill: theme.palette.primary.main }}} />
            )}
          </div>

          {icon}
        </div>
      );
    } else {
      return icon;
    }
  };

  const subIconText = (tab, location, to) => {
    const style = location.pathname === to
      ? { color: theme.palette.primary.main, fontSize: "10px !important", alignItems: "center", display: "flex", "& svg": { fontSize: "14px", fill: `${theme.palette.primary.main} !important` }}
      : { fontSize: "10px !important", alignItems: "center", display: "flex", "& svg": { fontSize: "14px" }}
    return <Typography sx={style}>{tab.label}</Typography>
  }

  useLayoutEffect(() => {
    if (location.pathname === "/account" || location.pathname === "/notifications" || (isAdmin && location.pathname === "/integrations") || (isAdmin && location.pathname === "/messages")) {
      setSelected(true);
      setSubTabsOpen(true);
    } else {
      setSelected(false);
    }
  }, [location.pathname, isAdmin])

  const handleSubTabClick = (path) => {
    conditionalNavigate(path);
    if (!isInternal) {
      const tempSettingsTabSelectionNotificationArgs = generateSettingsTabSelectionNotificationArgs(path, user);
      dispatch(setAlertNotificationArgs(tempSettingsTabSelectionNotificationArgs));
    }
  }

  return (
    <li>
      <ListItem
        button
        disableRipple
        component={nonNavigatingLink}
        onClick={(e) => setSubTabsOpen((prevSubTabsOpen) => !prevSubTabsOpen)}
        sx={selected ? {
          backgroundColor: theme.palette.secondary.main,
          borderLeft: "4px solid " + theme.palette.border.main,
        } : {}}
      >
        {icon ? <ListItemIcon >{pickIcon(to, icon, subTabsOpen)}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
      {subTabs && subTabsOpen && subTabsContent.filter(tab => !isAdmin ? !tab.admin : true).map((tab, index) => (
        <ListItem
          key={index}
          button
          disableRipple
          component={nonNavigatingLink}
          onClick={(e) => handleSubTabClick(tab.path)}
          sx={selected ? {
            maxHeight: "30px",
            fontSize: "10px",
            backgroundColor: theme.palette.secondary.main,
            borderLeft: "4px solid " + theme.palette.border.main,
          }:{
            maxHeight: "30px",
            fontSize: "10px"
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                //marginLeft: "0.5rem",
                marginRight: "-1.5rem",
                "& svg": location.pathname === tab.path ? {
                  fontSize: "20px",
                  fill: `${theme.palette.primary.main} !important`,
                }  : { fontSize: "20px" },
              }}
            >
              {tab.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={subIconText(tab, location, tab.path)}
          />
        </ListItem>
      ))}
    </li>
  );
}

export const SysAdminTab = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const conditionalNavigate = useConditionalNavigate();
  const [selected, setSelected] = useState(false);

  const [subTabsOpen, setSubTabsOpen] = useState(false);

  const { icon, primary, to, subTabs, subTabsContent } = props;

  const nonNavigatingLink = React.forwardRef((itemProps, ref) => (
    <span ref={ref} {...itemProps} role="button" />
  ));

  const pickIcon = (to, icon, subTabsOpen) => {
    if (to === "/sysadmin") {
      return (
        <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center', marginLeft: '0.25rem' }}>

          <div style={{ position: 'absolute', left: '-18px', top: '2%', zIndex: 1 }}>
            {subTabsOpen ? (
              <ExpandMoreIcon sx={{ "&:hover": { fill: theme.palette.primary.main }}} />
            ) : (
              <ChevronRightIcon sx={{ "&:hover": { fill: theme.palette.primary.main }}} />
            )}
          </div>

          {icon}
        </div>
      );
    } else {
      return icon;
    }
  };

  const subIconText = (tab, location, to) => {
    const style = location.pathname === to
      ? { color: theme.palette.primary.main, fontSize: "10px !important", alignItems: "center", display: "flex", "& svg": { fontSize: "14px", fill: `${theme.palette.primary.main} !important` }}
      : { fontSize: "10px !important", alignItems: "center", display: "flex", "& svg": { fontSize: "14px" }}
    return <Typography sx={style}>{tab.label}</Typography>
  }

  useLayoutEffect(() => {
    if (location.pathname === "/sysadmin_controls" || location.pathname === "/build_dashboard" || location.pathname === "/edit_dashboard") {
      setSelected(true);
      setSubTabsOpen(true);
    } else {
      setSelected(false);
    }
  }, [location.pathname])

  return (
    <li>
      <ListItem
        button
        disableRipple
        component={nonNavigatingLink}
        onClick={(e) => setSubTabsOpen((prevSubTabsOpen) => !prevSubTabsOpen)}
        sx={selected ? {
          backgroundColor: theme.palette.secondary.main,
          borderLeft: "4px solid " + theme.palette.border.main,
        } : {}}
      >
        {icon ? <ListItemIcon >{pickIcon(to, icon, subTabsOpen)}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
      {subTabs && subTabsOpen && subTabsContent.map((tab, index) => (
        <ListItem
          key={index}
          button
          disableRipple
          component={nonNavigatingLink}
          onClick={(e) => conditionalNavigate(tab.path)}
          sx={selected ? {
            maxHeight: "30px",
            fontSize: "10px",
            backgroundColor: theme.palette.secondary.main,
            borderLeft: "4px solid " + theme.palette.border.main,
          }:{
            maxHeight: "30px",
            fontSize: "10px"
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                //marginLeft: "0.5rem",
                marginRight: "-1.5rem",
                "& svg": location.pathname === tab.path ? {
                  fontSize: "20px",
                  fill: `${theme.palette.primary.main} !important`,
                }  : { fontSize: "20px" },
              }}
            >
              {tab.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={subIconText(tab, location, tab.path)}
          />
        </ListItem>
      ))}
    </li>
  );
}