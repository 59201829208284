import { useModuleCreate } from "../hooks/useModuleCreate";
import { Add as AddIcon } from "@mui/icons-material";
import { IconButton, Grid } from "@mui/material";
import {
  form_fields,
  string_fields,
  disabled_fields,
  existingModuleFormFields,
} from "../hooks/useModuleForm";
import ModalWrapper from "../../../Modal/ModalWrapper";
import BasicInput from "./Inputs/BasicInput";
import AppDependencyGraphInput from "./Inputs/AppDependencyGraphInput";
import DisplayAppsInput from "./Inputs/DisplayAppsInput";
import DisplayTitlesInput from "./Inputs/DisplayTitlesInput";
import TagsInput from "./Inputs/TagsInput";
import HideFromInput from "./Inputs/HideFromInput";
import VisibleToInput from "./Inputs/VisibleToInput";
import CreateButton from "./Buttons/CreateButton";
import DirectRequest from "../../../../API/requests/DirectRequest";

const CreateForm = ({
  formState,
  isValid,
  showForm,
  toggleShowForm,
  handleFormUpdate,
  handleModuleCreate,
  companies,
}) => {
  return (
    <ModalWrapper open={showForm} onClose={toggleShowForm} width={1200}>
      {/* new form here */}
      <Grid container sx={{ width: "100%" }} spacing={2}>
        {[
          form_fields.uuid,
          form_fields.name,
          form_fields.fullName,
          form_fields.description,
        ].map((field) => {
          return (
            <Grid item xs={6} key={field + "_item"}>
              <BasicInput
                field={field}
                formState={formState}
                handleFormUpdate={handleFormUpdate}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <AppDependencyGraphInput
            field={form_fields.appDependencyGraph}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <DisplayAppsInput
            field={form_fields.displayApps}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <DisplayTitlesInput
            field={form_fields.displayTitles}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <TagsInput
            field={form_fields.tags}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <HideFromInput
            field={form_fields.hideFromCompanyUuids}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
            companies={companies}
          />
        </Grid>
        <Grid item xs={4}>
          <VisibleToInput
            field={form_fields.visibleToCompanyUuids}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
            companies={companies}
          />
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent={"center"}>
          <Grid item>
            <CreateButton
              isValid={isValid}
              handleModuleCreate={handleModuleCreate}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export const ModuleCreate = ({ companies, handleModulesRefresh }) => {
  const {
    moduleCreateArgs,
    formState,
    isValid,
    showForm,
    toggleShowForm,
    handleFormUpdate,
    handleModuleCreate,
    handleModuleCreateSuccess,
  } = useModuleCreate(handleModulesRefresh);
  return (
    <>
      <DirectRequest
        requestArgs={moduleCreateArgs}
        afterProcess={handleModuleCreateSuccess}
      />
      <Grid item xs={12}>
        <IconButton onClick={toggleShowForm}>
          <AddIcon />
        </IconButton>
      </Grid>
      <CreateForm
        formState={formState}
        isValid={isValid}
        showForm={showForm}
        toggleShowForm={toggleShowForm}
        handleFormUpdate={handleFormUpdate}
        handleModuleCreate={handleModuleCreate}
        companies={companies}
      />
    </>
  );
};

export default ModuleCreate;
