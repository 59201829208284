import { useState } from "react";
import { useModuleForm, form_fields } from "./useModuleForm";
import * as Constants from "../../../../Constants";

const updateArgs = {
  /* TODO */
  url: "TODO",
  method: "POST",
  body: null
}

const deleteArgs = {
  /* TODO */
  url: "TODO",
  method: "POST",
  body: null
}

export const useModuleActions = (module, handleModulesRefresh) => {
  const {
    formState,
    isValid,
    showForm,
    toggleShowForm,
    handleFormUpdate
  } = useModuleForm(module);

  const [moduleActionArgs, setModuleActionArgs] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleModuleUpdate = () => {
    console.log("useModuleActions - handleModuleUpdate")
    const tempArgs = {
      url: Constants.SERVER_SYSADMIN_UPDATE_MODULE_URL,
      method: "POST",
      body: JSON.stringify({
        ...formState,
        // JSON.parse() appDependencyGraph, displayApps, displayTitles, tags, hideFromCompanyUuids, visibleToCompanyUuids
        [form_fields.appDependencyGraph]: JSON.parse(formState[form_fields.appDependencyGraph]),
        [form_fields.displayApps]: JSON.parse(formState[form_fields.displayApps]),
        [form_fields.displayTitles]: JSON.parse(formState[form_fields.displayTitles]),
        [form_fields.tags]: JSON.parse(formState[form_fields.tags]),
        [form_fields.hideFromCompanyUuids]: JSON.parse(formState[form_fields.hideFromCompanyUuids]),
        [form_fields.visibleToCompanyUuids]: JSON.parse(formState[form_fields.visibleToCompanyUuids])
      })
    }
    setModuleActionArgs(tempArgs)
  }

  const handleModuleDelete = () => {
    console.log("useModuleActions - handleModuleDelete - setting isDeleting")
    setIsDeleting(true)
    const tempArgs = {
      url: Constants.SERVER_SYSADMIN_DELETE_MODULE_URL,
      method: "POST",
      body: JSON.stringify({
        [form_fields.uuid]: formState[form_fields.uuid]
      })
    }
    setModuleActionArgs(tempArgs)
  }

  const handleModuleActionSuccess = () => {
    console.log("useModuleActions - handleModuleActionSuccess")
    if (isDeleting) {
      console.log("useModuleActions - handleModuleActionSuccess - unsetting isDeleting")
      setModuleActionArgs(null);
      setIsDeleting(false);
      handleModulesRefresh();
      toggleShowForm();
    } else {
      setModuleActionArgs(null);
      handleModulesRefresh();
      toggleShowForm();
    }
  }

  return {
    moduleActionArgs,
    formState,
    isValid,
    showForm,
    toggleShowForm,
    handleFormUpdate,
    handleModuleUpdate,
    handleModuleDelete,
    handleModuleActionSuccess
  }
}