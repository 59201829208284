import { Typography } from "@mui/material";
import AceEditor from "react-ace";
import ace from "ace-builds";

// JSON mode for AceEditor
import "ace-builds/src-noconflict/mode-json";

// TextMate theme for AceEditor
import "ace-builds/src-noconflict/theme-textmate";

// Autocompletion tools for AceEditor
import "ace-builds/src-noconflict/ext-language_tools";

// Disable workers entirely
ace.config.setDefaultValue('session', 'useWorker', false);

export const JsonInput = ({
  label,
  value,
  handleUpdate,
  helperText,
  minLines = 2,
  maxLines = 10,
  disabled = false,
}) => {
  return (
    <>
      <Typography
        sx={{
          color: helperText ? "red" : "rgba(0, 0, 0, 0.6)",
          transform: "scale(0.75)",
          transformOrigin: "left",
        }}
      >
        {label}&nbsp;{helperText ? `- ${helperText}` : ""}
      </Typography>
      <AceEditor
        mode="json"
        theme="textmate"
        value={value}
        onChange={handleUpdate}
        name="DisplayAppsEditor"
        editorProps={{ $blockScrolling: true }}
        showPrintMargin={false}
        minLines={minLines}
        maxLines={maxLines}
        readOnly={disabled}
        //setOptions={{
        //  tabSize: 2, // Set indentation size
        //  useSoftTabs: true, // Use spaces instead of tabs
        //  wrap: true, // Wrap long lines
        //  autoScrollEditorIntoView: true, // Auto-scroll when near the edge
        //}}
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          marginRight: "1%",
          marginLeft: "1%",
          width: "98%",
        }}
      />
    </>
  );
};

export default JsonInput;
