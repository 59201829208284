import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppCard from "./AppCard";
import Divider from "@mui/material/Divider";
import { useTheme } from '@mui/material/styles';

/*
AppStore gets apps/subscribedApps
  saveApps sets "categories" state in AppStore after cleaning and gathering from RedirectAppsRequest
    AppStore will map over "categories" state and render Category component per category
      Category will receive apps and subscribedApps as props
 */
export default function Category({ index, apps, subscribedApps, category }) {
  /*
  1: -> filter apps without matching category
  2: -> sort apps by subscribed alphabetical/not subscribed alphabetical if not developer - just alphabetical if developer
  */

  const theme = useTheme();

  const filterCategory = (app, category) => {
    // this function needs to check for the first instance of a "categories__" tag and then check if the category is the same as the one passed in
    // this will ensure apps with multiple categories are only included once
    const firstCategoryTag = app.tags.find((tag) => tag.includes("categories__"));
    if (!firstCategoryTag) {
      return category === "Efficiency (compliance & close)";
    }
    const summaryCategory = firstCategoryTag.split("__")[1];
    return summaryCategory === category;
  }

  const sortApps = (apps) => {
    // console.log("sortApps category:", category);
    const sortedApps = JSON.parse(JSON.stringify(apps))
      .filter(app => filterCategory(app, category))
      .sort((a, b) => {
        if (a.fullName !== b.fullName) {
          return a.fullName.localeCompare(b.fullName); // This will sort alphabetically.
        } else {
          return 0;
        }
      });
    // console.log("sortedApps:", sortedApps);
    return sortedApps;
  }

  // will be needed for AppCard
  const isActiveSubscribed = (app, subscribedApps) => {
    const filteredSubscribedList = subscribedApps.filter(subscribedApp => subscribedApp.developerAppUuid === app.uuid)
    if (!filteredSubscribedList || filteredSubscribedList.length === 0) {
      return false;
    }
    return filteredSubscribedList[0].isActive
  }

  const getCustomerSubscription = (appUuid, subscribedApps) => {
    if (appUuid && subscribedApps) {
      const customerSubscriptionCandidate = subscribedApps.filter(app => app.developerAppUuid === appUuid)
      if (customerSubscriptionCandidate?.length > 0) {
        return customerSubscriptionCandidate[0];
      }
      return null;
    }
    else {
      return null;
    }
  }

  return (
    <>
      {index !== 0 && <Grid item xs={12}>
        <Divider />
      </Grid>}
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={"h4"}>{category}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {sortApps(apps, subscribedApps).map((app, index) => {
                return (
                  <AppCard
                    key={index}
                    app={app}
                    subscribed={isActiveSubscribed(app, subscribedApps)}
                    customerSubscription={getCustomerSubscription(app.uuid, subscribedApps)}
                  />
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}