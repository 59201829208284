import { useSelector, useDispatch } from "react-redux";
import React, {useEffect, useState} from "react";
import { setIsReady, setBackendVersion, setFrontendVersion } from "../../store/appStateSlice";
import * as Constants from "../../Constants";
import DirectRequest from "../../API/requests/DirectRequest";

const BackendVersionGetter = () => {
  const dispatch = useDispatch();
  const [serverVersionArgs, setServerVersionArgs] = useState({ url: Constants.SERVER_VERSION_URL });
  const handleServerVersion = (res) => {
    // setVersion(res.version);
    dispatch(setBackendVersion(res.version));
  }
  const handleServerVersionError = (err) => {
    console.log("error getting server version", err);
    dispatch(setBackendVersion("0.0.000"));
  }
  return (
    <DirectRequest
      requestArgs={serverVersionArgs}
      afterProcess={handleServerVersion}
      handleError={handleServerVersionError}
      handleCatchError={handleServerVersionError}
    />
  )
}

const FrontendVersionGetter = () => {
  const dispatch = useDispatch();
  const getTextFile = async () => {
    // prefix public dir files with `process.env.PUBLIC_URL`
    // see https://create-react-app.dev/docs/using-the-public-folder/
    const res = await fetch(`${process.env.PUBLIC_URL}/version.txt?timestamp=${new Date().getTime()}`);
    // check for errors
    if (!res.ok) {
      throw res;
    }
    return res.text();
  };
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      getTextFile().then((version) => {
        dispatch(setFrontendVersion(version.split("_")[1]));
      }).catch((err) => {
        console.log('error getting frontend version', err);
        dispatch(setFrontendVersion("0.0.000"));
      });
    }
  }, []);
  return null;
}

const VersionManager = () => {
  return (
    <>
      <FrontendVersionGetter/>
      <BackendVersionGetter/>
    </>
  )
}

const Manager = () => {
  const dispatch = useDispatch();
  // const modulesReady = useSelector((state) => state.modules.isCaptured)
  const modulesReady = useSelector((state) => state.modules.isReady);
  const appsReady = useSelector((state) => state.apps.isCalculated);
  const alertsReady = useSelector((state) => state.alerts.isReady);
  const postSetupComplete = useSelector((state) => state.role.postSetupComplete);
  const subscriptionsReady = useSelector((state) => state.appsSubscriptions.isReady);

  useEffect(() => {
    if (!postSetupComplete && modulesReady && appsReady && subscriptionsReady) {
      // user has not onboarded - only need filtered apps to display view
      console.log("IsReadyManager - postSetupComplete is false: modules (and their subscriptions), apps and app subscriptions ready --> setting isReady");
      dispatch(setIsReady());
    } else if (postSetupComplete && modulesReady && appsReady && alertsReady && subscriptionsReady) {
      // user has onboarded - need filtered apps and alerts to display view
      console.log("IsReadyManager - postSetupComplete is true: modules (and their subscriptions), apps, alerts and app subscriptions ready --> setting isReady");
      dispatch(setIsReady());
    }
  }, [modulesReady, appsReady, alertsReady, postSetupComplete, subscriptionsReady]);

  return null;
}

const IsReadyManager = () => {
  const isReady = useSelector((state) => state.appState.isReady);

  return !isReady && (
    <Manager/>
  )
};

export const AppStateManager = () => {
  return (
    <>
      <IsReadyManager/>
      <VersionManager/>
    </>
  )
};

export default AppStateManager;