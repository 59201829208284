import { useState, useEffect } from "react"
import * as Constants from "../../../../Constants";

const getMultiAppConfigsIntancesArgs = (companyUuid) => {
	return { url: Constants.SERVER_SYSADMIN_MULTI_APP_CONFIGS_INSTANCES_URL + companyUuid }
}

export const useMultiAppConfigsInstances = (selectedCompany) => {
	const [multiAppConfigsInstancesArgs, setMultiAppConfigsInstancesArgs] = useState(null);
	const [multiAppConfigsInstances, setMultiAppConfigsInstances] = useState([]);
	
	const handleMultiAppConfigsInstances = (res) => {
		console.log("useMultiAppConfigsInstances - handleMultiAppConfigInstances - res", res);
		setMultiAppConfigsInstances([...res.multiAppConfigInstances]);
		setMultiAppConfigsInstancesArgs(null);
	}
	
	useEffect(() => {
		if (selectedCompany && selectedCompany.uuid) {
			if (selectedCompany.name) console.log("useMultiAppConfigsInstances - selected company", selectedCompany.name);
			setMultiAppConfigsInstances([]);
			setMultiAppConfigsInstancesArgs(getMultiAppConfigsIntancesArgs(selectedCompany.uuid));
		}
	}, [selectedCompany])
	
	const refreshMultiAppConfigsInstances = () => {
		setMultiAppConfigsInstancesArgs(getMultiAppConfigsIntancesArgs(selectedCompany.uuid));
	}
	
	return {
		multiAppConfigsInstancesArgs,
		multiAppConfigsInstances,
		handleMultiAppConfigsInstances,
		refreshMultiAppConfigsInstances	
	}
}