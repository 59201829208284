import { useState, useEffect } from "react";
import * as Constants from "../../../Constants";

/* 
TODO: 
rig up SysAdminModules to use this file (and useModulesSubscriptions) to maintain common location 
*/

const listArgs = { url: Constants.SERVER_SYSADMIN_GET_MODULES_URL }

export const module_fields = {
	uuid: "uuid",
	displayApps: "displayApps",
	// TODO: finish filling out - see above TODO
}

export const useModules = () => {
	const [modules, setModules] = useState([])
	const [modulesArgs, setModulesArgs] = useState(null);
	
	const handleModules = (res) => {
		console.log("useModules - handleModules - res", res)
		setModules([...res]);
		setModulesArgs(null);
	}
	
	const refreshModules = () => {
		setModulesArgs(listArgs);
	}
	
	useEffect(() => {
		setModules([]);
		setModulesArgs(listArgs);
	}, [])
	
	return {
		modules,
		modulesArgs,
		handleModules,
		refreshModules
	}
}