import { useState, useEffect } from "react";
import { FormControl, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

// renamed copy of CreateEntryInput - TODO: kill CreateEntryInput

const StringInput = ({ value, handleUpdate, label, error, errorMessage, multiline, startAdornment, disabled }) => {
  // only required are value and handleUpdate

  return (
    <FormControl size="small" sx={{ width: "100%" }}>
      <TextField
        size="small"
        variant="standard"
        label={label}
        value={value}
        disabled={disabled ?? false}
        onChange={(event) => handleUpdate(event.target.value)}
        error={error}
        helperText={errorMessage}
        multiline={multiline}
        maxRows={8}
        InputLabelProps={label ? {
          shrink: true
        } : {}}
        InputProps={startAdornment ? {
          startAdornment: (
            <InputAdornment position="start">
              <span style={{marginBottom: "4px"}}>{startAdornment}</span>
            </InputAdornment>
          ),
        } : {}}
      />
    </FormControl>
  );
}

export default StringInput;