import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState, useMemo} from "react";
import * as Constants from "../../Constants";
import {setPostSetupComplete} from "../../store/roleSlice";
import DirectRequest from "./DirectRequest";

export const PostSetupCompleteRequest = () => {
  const dispatch = useDispatch();
  
  // OLD
  // const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  // 
  // const postSetupComplete = useSelector((state) => state.role.postSetupComplete);
  // const [postSetupCompleteArgs, setPostSetupCompleteArgs] = useState(null);
  // // if !postSetupComplete will check all subscriptions for isSetupComplete and hit postSetupComplete if true
  // useEffect(() => {
  //   if (!postSetupComplete && subscribedApps.length > 0 && subscribedApps.every(sub => sub.isSetupComplete)) {
  //     console.log("all subscriptions are setup complete, hitting post setup complete");
  //     setPostSetupCompleteArgs({ url: Constants.SERVER_GET_RUN_POST_SETUP_URL, method: "POST" })
  //   }
  // }, [subscribedApps])
  // OLD
  
  // NEW
  const postSetupComplete = useSelector((state) => state.role.postSetupComplete);
  const [postSetupCompleteArgs, setPostSetupCompleteArgs] = useState(null);
  
  const subscribedApps = useSelector((state) => state.appsSubscriptions.list);
  const subscriptionsReady = useSelector((state) => state.appsSubscriptions.isReady);
  
  const modules = useSelector((state) => state.modules.list);
  const modulesSubscriptions = useSelector((state) => state.modules.subscriptionsList);
  const modulesReady = useSelector((state) => state.modules.isReady);
  // console.log("subscribedApps", subscribedApps)
  const nonModuleSubscribedApps = useMemo(() => {
    if (subscriptionsReady && modulesReady) {
      // console.log("CustomerSubsManager")
      // console.log(" - subscribedApps", subscribedApps)
      // console.log(" - modulesSubscriptions", modulesSubscriptions)
      const subscribedModules = modules.filter(module => {
        return modulesSubscriptions.map(moduleSubscription => moduleSubscription.moduleUuid).includes(module.uuid);
      });
      // console.log(" - subscribedModules", subscribedModules)
      // now get module.appDependencyGraph for each module
      const subscribedModulesApps = [];
      subscribedModules.forEach(module => {
        Object.entries(module.appDependencyGraph).forEach(([parentApp, childAppArr]) => {
          subscribedModulesApps.push([parentApp, ...childAppArr]);
        })
      })
      // console.log(" - subscribedModulesApps", subscribedModulesApps)
      const uniqueModulesApps = [...new Set(subscribedModulesApps.flat())]
      // console.log(" - uniqueModulesApps", uniqueModulesApps)
      const subscriptionsToReturn =  subscribedApps.filter(appSub => !uniqueModulesApps.includes(appSub.developerAppUuid))
      console.log("PostSetupCompleteRequest - nonModuleSubscribedApps", subscriptionsToReturn)
      return subscriptionsToReturn;
    } else {
      return null;
    }
  }, [subscribedApps, subscriptionsReady, modules, modulesSubscriptions, modulesReady])
  
  useEffect(() => {
    if (
      !postSetupComplete 
      && nonModuleSubscribedApps 
      && nonModuleSubscribedApps.length > 0 
      && nonModuleSubscribedApps.every(sub => sub.isSetupComplete)
    ) {
      console.log("all non-module subscriptions are setup complete, hitting post setup complete");
      setPostSetupCompleteArgs({ url: Constants.SERVER_GET_RUN_POST_SETUP_URL, method: "POST" })
    }
  }, [nonModuleSubscribedApps])
  // NEW

  const handlePostSetupComplete = (res) => {
    console.log("Post setup complete")
    dispatch(setPostSetupComplete());
  }

  return postSetupComplete ? null : (
    <DirectRequest
      requestArgs={postSetupCompleteArgs}
      afterProcess={handlePostSetupComplete}
      handleError={(err) => console.log("server error hitting post setup complete", err)}
      handleCatchError={(err) => console.log("client error hitting post setup complete", err)}
    />
  );
}

export default PostSetupCompleteRequest;