import { useState, useEffect } from "react";
import * as Constants from "../../../../Constants";

const listArgs = {
  /* TODO */
  url: Constants.SERVER_SYSADMIN_GET_MODULES_URL,
}
/*
export const SERVER_GET_MODULES_URL = SERVER_BASE_URL + "/api/module/list_visible";
export const SERVER_GET_MODULES_SUBSCRIPTIONS_URL = SERVER_BASE_URL + "/api/module/subscriptions";
export const SERVER_SYSADMIN_GET_MODULES_URL = SERVER_BASE_URL + "/api/module/list";
export const SERVER_SYSADMIN_GET_COMPANY_MODULES_SUBSCRIPTIONS_URL = SERVER_BASE_URL + "/api/module/subscriptions";// + companyUuid
export const SERVER_SYSADMIN_CREATE_MODULE_URL = SERVER_BASE_URL + "/api/module/create";
export const SERVER_SYSADMIN_UPDATE_MODULE_URL = SERVER_BASE_URL + "/api/module/update";
export const SERVER_SYSADMIN_DELETE_MODULE_URL = SERVER_BASE_URL + "/api/module/delete";
export const SERVER_SYSADMIN_MODULE_SUBSCRIBE_URL = SERVER_BASE_URL + "/api/module/subscribe";
export const SERVER_SYSADMIN_MODULE_UNSUBSCRIBE_URL = SERVER_BASE_URL + "/api/module/unsubscribe";
*/
const getSubscriptionsArgs = (companyUuid) => {
  // make args
  return {
    url: Constants.SERVER_SYSADMIN_GET_COMPANY_MODULES_SUBSCRIPTIONS_URL + companyUuid,
  }
}

export const useModules = (selectedCompany) => {
  const [modulesArgs, setModulesArgs] = useState(listArgs);
  const [modules, setModules] = useState([]);

  const [subscriptionsArgs, setSubscriptionsArgs] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  const handleModules = (res) => {
    setModules(res);
    setModulesArgs(null);
  }

  const handleSubscriptions = (res) => {
    console.log("useModules - handleSubscriptions - res", res);
    const moduleSubscriptions = [...res].map(module => {
      return {
        moduleUuid: module.uuid
      }
    })
    setSubscriptions(moduleSubscriptions);
    setSubscriptionsArgs(null);
  }

  const handleModulesRefresh = () => {
    setModulesArgs(listArgs);
  }

  const handleSubscriptionsRefresh = () => {
    setSubscriptionsArgs(getSubscriptionsArgs(selectedCompany.uuid));
  }

  useEffect(() => {
    if (selectedCompany && selectedCompany.uuid) {
      if (selectedCompany.name) console.log("useModules - selected company", selectedCompany.name);
      setSubscriptions([]);
      setSubscriptionsArgs(getSubscriptionsArgs(selectedCompany.uuid));
    }
  }, [selectedCompany])

  return {
    modulesArgs,
    modules,
    subscriptionsArgs,
    subscriptions,
    handleModules,
    handleSubscriptions,
    handleModulesRefresh,
    handleSubscriptionsRefresh
  }
}