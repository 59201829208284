import React, { useEffect } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setGetDataArgs, setGetReportDataArgs, setDashboardData, setConfigsReady, getDataArgsPayload} from "../../store/dashboardSlice";
import {setUpFilters} from "../../store/dashboardFiltersSlice";
import * as Constants from "../../Constants";
import Grid from "@mui/material/Grid";
import {AppViewLoader, LoadingBar} from "../Loaders/AppViewLoader";
import DashboardConfigsManager from "./components/DashboardConfigsManager";
import DashboardRowsManager from "./components/DashboardRowsManager";
import DashboardView from "./components/DashboardView";
import {setUpTransforms} from "../../store/dashboardTransformSlice";
import {setUpSortRules} from "../../store/dashboardSortSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ModuleTabs from "./ModuleTabs";
import DashboardChat from "./DashboardChat";


const getDataArgs = (appUuid, customerSubscriptionUuid, companyUuid) => {
  const tempArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_CUS_SUBBED_APP_URL + companyUuid : Constants.SERVER_CUS_SUBBED_APP_URL,
    method: "POST",
    body: JSON.stringify({
      customerSubscriptionUuid: customerSubscriptionUuid,
    }),
  };
  return tempArgs;
};

const getReportDataArgs = (erpReportUuid, companyUuid) => {
  const tempReportArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_GET_ERP_REPORT_URL + companyUuid : Constants.SERVER_GET_ERP_REPORT_URL,
    method: "POST",
    body: JSON.stringify({
      erpReportUuid: erpReportUuid
    })
  }
  return tempReportArgs;
}

const Loader = ({ cancelAction, isSysAdmin, state }) => {
  const getDataError = useSelector((state) => state.dashboard.getDataError.isErrored);
  const isAccrualsUser = useSelector((state) => state.role.isAccrualsUser);
  const isModulesUser = useSelector((state) => state.role.isModulesUser);
  const errorMessage = (isAccrualsUser || isModulesUser) ? "There was an error retrieving details" : "There was an error retrieving details, you're being redirected";
  const cancelText = () => {
    if (isAccrualsUser || isModulesUser) {
      return "Logout"
    } else if (state?.preview) {
      return "Return to builder"
    } else if (state?.emailLink && state?.companyUuid && isSysAdmin) {
      return "SysAdmin Alerts"
    } else if (state?.emailLink) {
      return "Alerts"
    } else {
      return "Close"
    }
  }
  const cancelIcon = () => {
    if (isAccrualsUser || isModulesUser) {
      return <LogoutIcon/>
    } else if (state?.preview || state?.emailLink) {
      return <ChevronLeftIcon/>
    } else {
      return <CloseIcon/>
    }
  }
  if (getDataError) {
    if (!isSysAdmin && !isAccrualsUser && !isModulesUser) {
      setTimeout(()=>{
        cancelAction();
      }, 3000)
    }
  }
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <LoadingBar cancelAction={cancelAction} cancelText={cancelText()} cancelIcon={cancelIcon()}/>
      </Grid>
      <Grid item xs={12}>
        <AppViewLoader error={getDataError} errorMessage={errorMessage} />
      </Grid>
    </Grid>
  )
}

export const Dashboard = () => {
  const page_params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  // isSysAdmin used by cancelAction to help determine navigation -- state.companyUuid signifies if dashboard viewed from SysAdmin panel
  const isSysAdmin = useSelector(state => state.role.isSysAdmin);
  const previewApp = useSelector(state => state.previewApp);
  const layout = useSelector((state) => state.dashboard.dashboardJson);
  const configsReady = useSelector((state) => state.dashboard.configsReady);
  const isAccrualsUser = useSelector(state => state.role.isAccrualsUser);
  const isModulesUser = useSelector(state => state.role.isModulesUser);

  useEffect(() => {
    if (state != null) {
      console.log("Dashboard state:", state);
      if (state.preview) {
        const dashboardJsonObject = JSON.parse(previewApp.dashboardJson);
        dispatch(setDashboardData({
          dashboardJson: dashboardJsonObject,
          tables: previewApp.tables
        }));
        dispatch(setUpTransforms({ tables: previewApp.tables, dashboardJson: dashboardJsonObject }));
        dispatch(setUpFilters({ tables: previewApp.tables }));
        dispatch(setUpSortRules({ dashboardJson: dashboardJsonObject }));
        dispatch(setConfigsReady());
      }
      if (state.viewModule) {
        // need to generate args based on uuid (new ModuleTabs will allow user to select app from displayApps)
        dispatch(setGetDataArgs(getDataArgsPayload(getDataArgs(state.uuid, state.customerSubscriptionUuid, state.companyUuid), state.companyUuid, state.uuid)))
      }
      if (state.viewApp) {
        // dispatch(setGetDataArgs(getDataArgs(page_params.dashboardID, state.customerSubscriptionUuid, state.companyUuid)))
        dispatch(setGetDataArgs(getDataArgsPayload(getDataArgs(state.uuid, state.customerSubscriptionUuid, state.companyUuid), state.companyUuid, state.uuid)))
      }
      if (state.viewReport) {
        // dispatch setGetReportDataArgs
        dispatch(setGetReportDataArgs(getDataArgsPayload(getReportDataArgs(state.erpReportUuid, state.companyUuid), state.companyUuid, state.uuid)))
      }
    } else {
      if (isAccrualsUser || isModulesUser) {
        navigate("/")
      } else {
        // state === null -> user pasted this url into the browser as opposed to accessing via Dashboard
        navigate(`/redirect/${page_params.dashboardID}`);
      }
    }
  }, [state]);

  // TODO: wipe dashboard and dashboardConfigs and dashboardFilters if emailLink (otherwise page just closes which wipes this instance)
  // dashboardSlice -> resetDashboardSlice
  // dashboardConfigsSlice -> removeConfigs
  // dashboardFiltersSlice -> resetDashboardFiltersSlice
  const cancelAction = () => {
    if (!state.preview) {
      if (isAccrualsUser || isModulesUser) {
        const logoutUrl = Constants.SERVER_LOGOUT_URL;
        dispatch({ type: "RESET" });
        localStorage.setItem("isLoggingOut", "true");
        window.open(logoutUrl, "_self");
      } else if (state?.emailLink) {// if WiseLayer generated email alert link pasted into url - EmailRedirect catches and applies to passed state
        state?.companyUuid && isSysAdmin ? navigate("/sysadmin_controls") : navigate("/");
      } else {
        window.close();
      }
    } else {
      navigate("/build_dashboard", {
        state: { previewed: true },
      });
    }
  };
  
  const updateState = (newProps) => {
    console.log("Dashboard - updateState - newProps", newProps)
    Object.assign(state, newProps);
  }

  return (
    <div style={{ width: '100%' }}>
      {state?.viewModule && <ModuleTabs state={state} module={state.module} cancelAction={cancelAction}/>}
      {!configsReady && <Loader cancelAction={cancelAction} isSysAdmin={isSysAdmin} state={state}/>}
      {configsReady && <DashboardConfigsManager/>}
      {configsReady && <DashboardRowsManager/>}
      {configsReady && <DashboardView cancelAction={cancelAction} state={state} layout={layout} />}
      {state?.viewModule && <DashboardChat moduleUuid={state.moduleUuid}/>}
    </div>
  )
}

export default Dashboard;