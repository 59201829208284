import React, {useState, useEffect} from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import formatNum from "../../../helpers/formatNum";
import Brightness1 from "@mui/icons-material/Brightness1";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { SiteLanguage } from "../../../Constants";

const component_map = {
  dot: Brightness1,
  check: CheckCircleIcon,
  cross: ClearIcon,
  line: RemoveIcon,
  flag: FlagCircleIcon,
  down: SouthIcon,
  up: NorthIcon,
  dash: HorizontalRuleIcon
};

const titleStyle = { fontSize: ".8rem", fontWeight: 500 }

const applyBackgroundStyle = (theme) => {
	return {
		backgroundColor: theme.palette.secondary.main,
		paddingBottom: "16px",
		borderRadius: "10px",
		marginLeft: "0px",
		width: "100%",
		paddingLeft: "0px !important",
		'@media (max-width: 900px)': {  // Apply additional margin or padding for small screens
			marginTop: '-2px',
		},
	}
}

function TopItem(props) {
	const theme = useTheme();
	const [highlighted, setHighlighted] = useState(undefined);
	const compareFormatting = props.compareData && props.compareData.type === "arithmetic" ? props.formatting : { "min": 1, "max": 1, "suffix": "%" };
	//console.log("TopItem props...", props)

	const adjustedFormat = (figure, formatting) => {
  		let prefix = (formatting && formatting.currency ? "$" : (formatting && formatting.prefix ? formatting.prefix : ""));
  		let suffix = (formatting && formatting.percent ? "%" : (formatting && formatting.suffix ? formatting.suffix : ""));
  		let digits = (formatting && formatting.digits ? formatting.digits : (formatting && formatting.max ? formatting.max : 0));

  		let output;
  		let new_formatter = {};
  		new_formatter["min"] = digits;
  		new_formatter["max"] = digits;
  		new_formatter["percent"] = (formatting && formatting.percent ? true : false);


  		// if (Math.abs(figure) >= 1000000000) {
	    // 		output = formatNum(Math.abs(figure) / 1000000000, new_formatter) + 'B'
	  	// } else if (Math.abs(figure) >= 1000000) {
	    // 		output = formatNum(Math.abs(figure) / 1000000, new_formatter) + 'M'
	  	// } else if (Math.abs(figure) >= 1000) {
	    // 		output = formatNum(Math.abs(figure) / 1000, new_formatter) + 'K';
	  	// } else {
	    // 		output = formatNum(Math.abs(figure), new_formatter)
	  	// }
		output = formatNum(Math.abs(figure), new_formatter)
  	output = prefix + output + suffix;

  	if(figure < 0) {
  		output = "(" + output + ")";
  	}

		return output
	} 


	const renderIcon = () => {
		let params = {};

		if(props.compareData) {
			let component_type = "down";

			if(props.compareData.isPositiveGood !== undefined) {
				if((props.compareData.isPositiveGood === true && props.compareData.figure > 0) ||
					(props.compareData.isPositiveGood === false && props.compareData.figure < 0)) {
					params.sx = {color: theme.palette.good.main};
				} else if (props.compareData.figure === 0) {
					params.sx = {};
				} else {
					params.sx = {color: theme.palette.bad.main};
				}
			} else {
				if(props.compareData.figure < 0) {
					params.sx = {color: theme.palette.bad.main};
				} else if (props.compareData.figure === 0) {
					params.sx= {};
				}
				else {
					params.sx = {color: theme.palette.good.main};
				}
			}

			if(props.compareData.figure < 0) {
				component_type = "down";
			} else if (props.compareData.figure === 0) {
				component_type = "dash";
			} else {
				component_type = "up";
			}

			const MyComponent = component_map[component_type];
			return (
					<React.Fragment>
						<Grid item>
		 					{React.createElement(MyComponent, params)}
		 				</Grid>
		 				<Grid item>
		 					<Typography variant="p" {...params}>{adjustedFormat(props.compareData.figure, compareFormatting)} vs. {props.compareData.subtitle.toLowerCase()}</Typography>
		 				</Grid>
					</React.Fragment>
				)
			
		}
		return (<></>)
	}

	return (
		<React.Fragment>
			<Grid container spacing={2} sx={/*props.applyBackground ? */applyBackgroundStyle(theme)/* : {}*/}>
				<Grid item xs={12}>
					<Typography variant="p" sx={titleStyle}/*"h6"*/>{props.title}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									{props.hasData && <Typography variant="p" sx={{ color: props.highlighted ? theme.palette.good.main : "inherit", fontSize: "1.4rem", fontWeight: 500 }}>{adjustedFormat(props.figureData, props.formatting)}</Typography>}
									{!props.hasData && <Typography variant="p" sx={{ color: theme.typography.missingData.color }}>{SiteLanguage.MissingData}</Typography>}
								</Grid>
								{props.subtitle &&
									<Grid item xs={12}>
										<Typography variant="p">{props.subtitle}</Typography>
									</Grid>
								}
							</Grid>
						</Grid>
						{props.compareData && props.hasData &&
		   		 		<Grid item xs={12}>
		   		 			<Grid container spacing={2} justifyContent={"flex-end"}>
		   		 				{renderIcon()}
		   		 			</Grid>
		   		 		</Grid>
						}
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	)
}

export default TopItem;