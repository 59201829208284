import { useState, useEffect } from "react";
import * as Constants from "../../../Constants";

const getSubscriptionsArgs = (companyUuid) => {
	return { url: Constants.SERVER_SYSADMIN_GET_COMPANY_MODULES_SUBSCRIPTIONS_URL + companyUuid }
}

export const useModulesSubscriptions = (selectedCompany) => {
	const [modulesSubscriptions, setModulesSubscriptions] = useState([])
	const [modulesSubscriptionsArgs, setModulesSubscriptionsArgs] = useState(null);
	
	const handleModulesSubscriptions = (res) => {
		const subscriptions = [...res].map(module => {
			return {
				moduleUuid: module.uuid
			}
		})
		setModulesSubscriptions(subscriptions);
		setModulesSubscriptionsArgs(null);
	}
	
	const refreshModulesSubscriptions = () => {
		setModulesSubscriptionsArgs(getSubscriptionsArgs(selectedCompany.uuid));
	}
	
	useEffect(() => {
		if (selectedCompany && selectedCompany.uuid) {
			if (selectedCompany.name) console.log("useModulesSubscriptions - selected company", selectedCompany.name);
			setModulesSubscriptions([]);
			setModulesSubscriptionsArgs(getSubscriptionsArgs(selectedCompany.uuid));
		}
	}, [selectedCompany])
	
	return {
		modulesSubscriptions,
		modulesSubscriptionsArgs,
		handleModulesSubscriptions,
		refreshModulesSubscriptions
	}
}