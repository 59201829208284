import { useState, useEffect } from "react";
import * as Constants from "../../../Constants";

const getSubscriptionsArgs = (companyUuid) => {
	return { url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + companyUuid }
}

export const useAppsSubscriptions = (selectedCompany) => {
	const [appsSubscriptions, setAppsSubscriptions] = useState([])
	const [appsSubscriptionsArgs, setAppsSubscriptionsArgs] = useState(null);
	
	const handleAppsSubscriptions = (res) => {
		setAppsSubscriptions([...res])
	}
	
	useEffect(() => {
		if (selectedCompany && selectedCompany.uuid) {
			if (selectedCompany.name) console.log("useAppsSubscriptions - selected company", selectedCompany.name);
			console.log("selectedCompany", selectedCompany);
			console.log("selectedCompany.uuid", selectedCompany.uuid)
			setAppsSubscriptions([]);
			setAppsSubscriptionsArgs(getSubscriptionsArgs(selectedCompany.uuid));
		}
	}, [selectedCompany])
	
	return {
		appsSubscriptions,
		appsSubscriptionsArgs,
		handleAppsSubscriptions
	}
}