import { disabled_fields } from "../../hooks/useModuleForm";
import StringInput from "../../../../Inputs/StringInput";

const BasicInput = ({ field, formState, handleFormUpdate }) => {
  const handleUpdate = (newValue) => {
    handleFormUpdate(field, newValue);
  };
  return (
    <StringInput
      value={formState[field] ?? ""}
      handleUpdate={handleUpdate}
      label={field}
      multiline={true}
      error={formState[field] === "" ?? false}
      errorMessage={
        formState[field] === "" ? (
          <span style={{ transform: "scale(0.75)", transformOrigin: "left" }}>
            Missing {field}
          </span>
        ) : (
          ""
        )
      }
      disabled={
        disabled_fields.includes(field) || formState[field] === "undefined"
      }
    />
  );
};

export default BasicInput;
