import ModuleDetails from "./ModuleDetails";
import ModuleActions from "./ModuleActions";
import ModuleSubscription from "./ModuleSubscription";
import { Grid, Divider } from "@mui/material"
/*
module:
    String uuid;
    String companyUuid;
    String externalUuid;
    String name;
    String createdBy;

    String fullName;
    String description;

    // The apps that should have visual tabs;
    // there may be apps run in the dependency graph that are not displayed and thus not included in this list.
    List<String> displayApps;
    List<String> displayTitles;

    // a map describing the dependency graph of the module.
    // There should always be one or more nodes that are "root" nodes that are the first apps to be added to the queue
    // When all parent nodes' jobs have finished, then the child node should be added to the job queue.
    // Todo: Junaid decide what directed graph implementation to use
    DirectedGraph<String> appDependencyGraph;

    List<String> tags;

    List<String> visibleToCompanyUuids;
    List<String> hideFromCompanyUuids;
*/
// ModuleActions
// ModuleSubscription



export const Module = ({ module, subscriptions, handleModulesRefresh, handleSubscriptionsRefresh, companies, selectedCompany }) => {
  // companies used to set hiddenFrom and visibleTo in form

  // need an xs={12} Grid item for each module
  // 8 -> details
  // 4 -> 
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={9} container spacing={1}>
        <ModuleDetails module={module} companies={companies} />
      </Grid>
      <Grid item xs={1} container spacing={1}>
        <ModuleActions module={module} companies={companies} handleModulesRefresh={handleModulesRefresh} />
      </Grid>
      <Grid item xs={2} container spacing={1}>
        <ModuleSubscription module={module} handleSubscriptionsRefresh={handleSubscriptionsRefresh} selectedCompany={selectedCompany} subscriptions={subscriptions} />
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
    </Grid>
  )
}

export default Module;