import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import * as Constants from "../../../Constants";
import { RedirectLoader } from "../redirectUtils";
import DirectRequest from "../../../API/requests/DirectRequest";

const allModulesArgs = {
	url: Constants.SERVER_SYSADMIN_GET_MODULES_URL
}

const getCompanyModulesSubscriptionsArgs = (companyUuid) => {
	return {
		url: Constants.SERVER_SYSADMIN_GET_COMPANY_MODULES_SUBSCRIPTIONS_URL + companyUuid
	}
}

const getSubscribedAppsArgs = (companyUuid) => {
	return {
		url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + companyUuid 
	}
}

export const SysAdminModuleRedirect = ({moduleUuid, companyUuid}) => {
	const navigate = useNavigate();
	// const modules = useSelector(state => state.modules.list);
	// const modulesSubscriptions = useSelector(state => state.modules.subscriptionsList);
	const [resourceState, setResourceState] = useState({
		modules: null,
		modulesSubscriptions: null,
		appsSubscriptions: null
	})
	
	const [modulesArgs, setModulesArgs] = useState(allModulesArgs);
	
	const [modulesSubscriptionsArgs, setModulesSubscriptionsArgs] = useState(getCompanyModulesSubscriptionsArgs(companyUuid));
	
	const [companySubscribedAppsArgs, setCompanySubscribedAppsArgs] = useState(getSubscribedAppsArgs(companyUuid));
	
	const sysAdminApps = useSelector((state) => state.appsSysAdmin.list);
	
	useEffect(()=>{
		console.log("resourceState", resourceState);
		const {
			modules,
			modulesSubscriptions,
			appsSubscriptions
		} = resourceState
		if (modules && modulesSubscriptions && appsSubscriptions) {
			console.warn("modules && modulesSubscriptions && appsSubscriptions captured");
			if (modulesSubscriptions.map(subscription => subscription.moduleUuid).includes(moduleUuid)) {
				console.log("module subscription found - redirecting to Dashboard");
				const module = modules.find(module => module.uuid === moduleUuid);
				const tempDashboardState = {
					viewModule: true,
					companyUuid: companyUuid,
					module: module,
					moduleUuid: moduleUuid,
					uuid: module.displayApps[0],
					customerSubscriptionUuid: appsSubscriptions.find(sub => sub.developerAppUuid === module.displayApps[0]).uuid,
					thisDevApp: sysAdminApps.find(app => app.uuid === module.displayApps[0])
				}
				console.log("SysAdminModuleRedirect - tempState", tempDashboardState)
				navigate(`/dashboard/${module.displayApps[0]}`, { state: tempDashboardState })
			}
		}
	}, [resourceState, moduleUuid, companyUuid, sysAdminApps])
	
	const handleModules = (res) => {
		console.warn("SysAdminModuleRedirect - modules:", res);
		setResourceState(prev => {
			return {
				...prev,
				modules: [...res]
			}
		})
	}
	
	const handleModulesSubscriptions = (res) => {
		console.warn("SysAdminModuleRedirect - company modules subscriptions:", res);
		const subscriptions = [...res].map(module => {
			return {
				moduleUuid: module.uuid
			}
		})
		setResourceState(prev => {
			return {
				...prev,
				modulesSubscriptions: subscriptions
			}
		})
	}
	
	const handleCompanySubscribedApps = (res) => {
		console.warn("SysAdminModuleRedirect - company app subscriptions:", res);
		if (res) {
			setResourceState(prev => {
				return {
					...prev,
					appsSubscriptions: [...res]
				}
			})
		}
	}
	
	
	return (
		<>
			<DirectRequest requestArgs={modulesArgs} afterProcess={handleModules} />
			<DirectRequest requestArgs={modulesSubscriptionsArgs} afterProcess={handleModulesSubscriptions} />
			<DirectRequest requestArgs={companySubscribedAppsArgs} afterProcess={handleCompanySubscribedApps} />
			<RedirectLoader/>
		</>
	)
}

export default SysAdminModuleRedirect;