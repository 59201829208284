import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export const BooleanInput = ({ value, label, handleChange, disabled }) => {
	const handleSwitchChange = () => {
		if (value === "true") {
			handleChange("false")
		} else {
			handleChange("true")
		}
	}
	return (
		<FormControlLabel
			control={
				<Switch checked={value === "true"} onChange={handleSwitchChange} disabled={disabled}/>
			}
			label={label}
		/>
	)
}

export default BooleanInput