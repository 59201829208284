import commandsReducer from "./commandsSlice";
import roleReducer from "./roleSlice";
import appBuilderReducer from "./appBuilderSlice";
import previewAppReducer from "./previewAppSlice";
import requestErrorsReducer from "./requestErrorsSlice";
import customerSubsReducer from "./customerSubsSlice";
import syncStatusReducer from "./syncStatusSlice";
import themeReducer from "./themeSlice";// Theme setting disabled for now - default values still used
import themeLogoReducer from "./themeLogoSlice";// Theme setting disabled for now - default slice values still used
import clusterStateReducer from "./clusterStateSlice";
import emailNotificationReducer from "./emailNotificationSlice";
import mixpanelReducer from "./mixpanelSlice";
import mixpanelSignInReducer from "./mixpanelSignInSlice";
import developerOptInReducer from "./developerOptInSlice";
import mixpanelTrackReducer from "./mixpanelTrackSlice";
import integrationMetadataReducer from "./integrationMetadataSlice";
import selectedIntegrationsReducer from "./selectedIntegrationsSlice";
import appsReducer from "./appsSlice";
import appsSysAdminReducer from "./appsSysAdminSlice";
import summaryMetricsReducer from "./summaryMetricsSlice";
import monitorPendingAppsReducer from "./monitorPendingAppsSlice";

import alertsReducer from "./alertsSlice";
import alertsSysAdminReducer from "./alertsSysAdminSlice";
import alertsTabsReducer from "./alertsTabsSlice";
import alertsTabsSysAdminReducer from "./alertsTabsSysAdminSlice";

import summaryNotificationsModalReducer from "./summaryNotificationsModalSlice";
import appsCategoriesReducer from "./appsCategoriesSlice";

import appStateReducer from "./appStateSlice";
import alertsUpdateReducer from "./alertsUpdateSlice";
import alertsNotificationReducer from "./alertsNotificationSlice";
import dashboardEditorReducer from "./dashboardEditorSlice";

import alertConfigsSysAdminReducer from "./alertConfigsSysAdminSlice";

import appsSubscriptionsReducer from "./appsSubscriptionsSlice";
import alertMessagesReducer from "./alertMessagesSlice";
import alertMessagesSysAdminReducer from "./alertMessagesSysAdminSlice";

import alertSyncsReducer from "./alertSyncsSlice";

import dashboardReducer from "./dashboardSlice";
import dashboardConfigsReducer from "./dashboardConfigsSlice";
import dashboardFiltersReducer from "./dashboardFiltersSlice";

import dashboardSortReducer from "./dashboardSortSlice";
import dashboardTransformReducer from "./dashboardTransformSlice";

import modulesReducer from "./modulesSlice";

import alertsNotificationMiddleware from "./middleware/alertsNotificationMiddleware";
import dashboardMiddleware from './middleware/dashboardMiddleware';

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  configureStore,
  createImmutableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";

const reducers = combineReducers({
  commands: commandsReducer,
  role: roleReducer,
  appBuilder: appBuilderReducer,
  previewApp: previewAppReducer,
  requestErrors: requestErrorsReducer,
  customerSubs: customerSubsReducer,
  syncStatus: syncStatusReducer,
  theme: themeReducer,
  themeLogo: themeLogoReducer,
  clusterState: clusterStateReducer,
  emailNotification: emailNotificationReducer,
  mixpanel: mixpanelReducer,
  mixpanelSignIn: mixpanelSignInReducer,
  developerOptIn: developerOptInReducer,
  mixpanelTrack: mixpanelTrackReducer,
  integrationMetadata: integrationMetadataReducer,
  selectedIntegrations: selectedIntegrationsReducer,
  apps: appsReducer,
  appsSysAdmin: appsSysAdminReducer,
  summaryMetrics: summaryMetricsReducer,
  monitorPendingApps: monitorPendingAppsReducer,
  alerts: alertsReducer,
  alertsSysAdmin: alertsSysAdminReducer,
  alertsTabs: alertsTabsReducer,
  alertsTabsSysAdmin: alertsTabsSysAdminReducer,
  summaryNotificationsModal: summaryNotificationsModalReducer,
  appsCategories: appsCategoriesReducer,
  appState: appStateReducer,
  alertsUpdate: alertsUpdateReducer,
  alertsNotification: alertsNotificationReducer,
  dashboardEditor: dashboardEditorReducer,
  alertConfigsSysAdmin: alertConfigsSysAdminReducer,
  appsSubscriptions: appsSubscriptionsReducer,
  alertMessages: alertMessagesReducer,
  alertMessagesSysAdmin: alertMessagesSysAdminReducer,
  alertSyncs: alertSyncsReducer,
  dashboard: dashboardReducer,
  dashboardConfigs: dashboardConfigsReducer,
  dashboardFilters: dashboardFiltersReducer,
  dashboardSort: dashboardSortReducer,
  dashboardTransform: dashboardTransformReducer,
  modules: modulesReducer
});

// slices relating to external scripts which must attach to dom, load, and initialize should not be persisted (paragon, mixpanel, etc)
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "commands",// SSE bound job tickets - should persist across session
    "role",
    "syncStatus",
    "clusterState",
    "emailNotification",
    "mixpanelSignIn",
    "developerOptIn",
    "theme",
    "themeLogo",
    "selectedIntegrations",
    "summaryNotificationsModal",// open / close state should persist across session
    "appBuilder",
    "previewApp",
  ],
};

const logoutResetHandler = (rootReducer) => (state, action) => {
  if (action.type !== "RESET") return rootReducer(state, action);

  const newState = rootReducer(undefined, {});
  return newState;
};

const persistedReducer = persistReducer(
  persistConfig,
  logoutResetHandler(reducers)
);

// Dev only
const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: ["ignoredPath", "ignoredNested.one", "ignoredNested.two"],
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, immutableInvariantMiddleware, alertsNotificationMiddleware, dashboardMiddleware],
});

export default store;
