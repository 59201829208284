import React, { useEffect, useState, useMemo } from 'react';
import DirectRequest from "../../../API/requests/DirectRequest";
import * as Constants from "../../../Constants";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {refreshAppsSubscriptions} from "../../../store/appsSubscriptionsSlice";
import { integration_fields } from "../../../store/integrationMetadataSlice";

const SubscribeToOneApp = ({ app, setSubscriptionStatus }) => {
	const [subscriptionArgs, setSubscriptionArgs] = useState(null);

	useEffect(() => {
		if (!subscriptionArgs) {
			const tempSubscriptionArgs = {
				url: Constants.SERVER_CUS_APP_BUY_URL,
				method: "POST",
				body: JSON.stringify({
					"developerAppUuid": app.uuid,
					"stripeSubscriptionUuid": null,
					"isTrial": false,
					"isActive": true
				})
			};
			setSubscriptionArgs(tempSubscriptionArgs);
		}
	}, [subscriptionArgs, app])

	const handleSubscription = (res) => {
		setSubscriptionStatus((subscriptionStatus) => {
			const tempSubscriptionStatus = { ...subscriptionStatus };
			tempSubscriptionStatus[app.uuid] = true;
			return tempSubscriptionStatus;
		})
	}

	return (
		<>
			<DirectRequest
				requestArgs={subscriptionArgs}
				afterProcess={handleSubscription}
				handleError={(err) => console.log(`error creating subscription for developer app ${app.uuid}`, err)}
				handleCatchError={(err) => console.log(`catch error creating subscription for developer app ${app.uuid}`, err)}
			/>
		</>
	)
};

const SubscribeToOnboardingApps = ({connectedIntegrations}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const apps = useSelector((state) => state.apps.filteredList);
	
	const onboardingApps = useMemo(() => {
		// filter for "primary" integrations
		const primaryIntegrations = connectedIntegrations
			.map(integration => integration[integration_fields.sourceName])
			.filter(sourceName => Constants.primary_integrations.includes(sourceName))
		// create list of onboarding app tags to match against (1 per primary integration the user has connected ex: ["onboarding__quickbooks", ...])
		const onboardingTags = primaryIntegrations.map(integration => `${Constants.onboarding_app_tag_prefix}${integration}`)
		// filter out all apps which don't include at least 1 onboarding app tag in their "tags" array
		const tempOnboardingApps = apps.filter(app => app.tags.some(tag => onboardingTags.includes(tag)));
		console.log("connectedIntegrations", connectedIntegrations);
		console.log("primaryIntegrations", primaryIntegrations);
		console.log("onboardingTags", onboardingTags);
		console.log("tempOnboardingApps", tempOnboardingApps);
		return tempOnboardingApps;
	}, [apps, connectedIntegrations])
	
	const [subscriptionStatus, setSubscriptionStatus] = useState(null);
	
	useEffect(() => {
		if (onboardingApps.length > 0) {
			const tempSubscriptionStatus = {};
			onboardingApps.forEach((app) => {
				tempSubscriptionStatus[app.uuid] = false;
			})
			setSubscriptionStatus(tempSubscriptionStatus);
		} else {
			console.warn("no onboarding apps found");
		}
	}, [onboardingApps])
	
	useEffect(() => {
		if (subscriptionStatus) {
			const allSubscribed = Object.values(subscriptionStatus).every((status) => status);
			if (allSubscribed) {
				dispatch(refreshAppsSubscriptions());
				navigate("/notifications")
			}
		}
	}, [subscriptionStatus])
	
	return (
		<>
			{subscriptionStatus && onboardingApps.map((app) =>  (
				<SubscribeToOneApp key={`${app.uuid}_subscriber`} app={app} setSubscriptionStatus={setSubscriptionStatus} />
			))}
		</>
	)
}

export default SubscribeToOnboardingApps;