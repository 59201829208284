import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { useAppsSubscriptions } from "../../hooks/useAppsSubscriptions";
import { useModules } from "../../hooks/useModules";
import { useModulesSubscriptions } from "../../hooks/useModulesSubscriptions";
import { view_options } from "../hooks/useToolbar";
import DirectRequest from "../../../../API/requests/DirectRequest";
import ImpactBar from "../../../ImpactBar/ImpactBar";
import getSourceTag from "../../../../helpers/getSourceTag";
import {useSelector } from "react-redux";

const ViewToggle = ({viewOption, handleViewSelection}) => {
	return (
		<ButtonGroup aria-label="outlined primary button group">
			<Button 
				disableRipple 
				variant={viewOption === view_options.all ? "contained" : "outlined"} 
				size={"small"} 
				sx={{ borderRadius: "0px !important" }} 
				onClick={() => handleViewSelection(view_options.all)}
			>{view_options.all}</Button>
			<Button 
				disableRipple 
				variant={viewOption === view_options.module ? "contained" : "outlined"} 
				size={"small"} 
				sx={{ borderRadius: "0px !important" }} 
				onClick={() => handleViewSelection(view_options.module)}
			>{view_options.module}</Button>
			<Button 
				disableRipple 
				variant={viewOption === view_options.app ? "contained" : "outlined"} 
				size={"small"} 
				sx={{ borderRadius: "0px !important" }}
				onClick={() => handleViewSelection(view_options.app)}
				>{view_options.app}</Button>
		</ButtonGroup>
	)
}

const SelectApp = ({selectedAppUuid, handleAppSelection, selectedCompany}) => {
	const allApps = useSelector((state) => state.appsSysAdmin.list);
	const {
		appsSubscriptions,
		appsSubscriptionsArgs,
		handleAppsSubscriptions
	} = useAppsSubscriptions(selectedCompany)
	
	const onChange = (e) => {
		const appUuid = e.target.value ?? "";
		handleAppSelection(appUuid)
	}
	
	return (
		<>
			<DirectRequest requestArgs={appsSubscriptionsArgs} afterProcess={handleAppsSubscriptions}/>
			<FormControl sx={{ minWidth: "350px", width: "700px" }} size={"small"}>
				<InputLabel id={"app-select-label"}><i>App</i></InputLabel>
				<Select
					id="app-select"
					labelId={"app-select-label"}
					margin="dense"
					onChange={onChange}
					value={selectedAppUuid || ""}
					label={"App"}
				>
					{allApps
						.filter(app => appsSubscriptions.some(sub => sub.developerAppUuid === app.uuid))
						.map((app, index) => {
							return (
								<MenuItem value={app.uuid} key={index}>
									{app.uuid}&nbsp;<strong>{app.fullName}</strong>&nbsp;<i>{getSourceTag(app.tags)}</i>
								</MenuItem>
							);
						}
					)}
				</Select>
			</FormControl>
		</>
	)
}

const SelectModule = ({selectedModuleUuid, handleModuleSelection, selectedCompany}) => {
	const {
		modules,
		modulesArgs,
		handleModules
	} = useModules();
	
	const {
		modulesSubscriptions,
		modulesSubscriptionsArgs,
		handleModulesSubscriptions
	} = useModulesSubscriptions(selectedCompany);
	
	const onChange = (e) => {
		const moduleUuid = e.target.value ?? "";
		handleModuleSelection(moduleUuid)
	}
	console.log("SelectModule - modules", modules)
	console.log("SelectModule - modulesSubscriptions", modulesSubscriptions)
	return (
		<>
			<DirectRequest requestArgs={modulesArgs} afterProcess={handleModules}/>
			<DirectRequest requestArgs={modulesSubscriptionsArgs} afterProcess={handleModulesSubscriptions}/>
			<FormControl sx={{ minWidth: "350px", width: "700px" }} size={"small"}>
				<InputLabel id={"app-select-label"}><i>Module</i></InputLabel>
				<Select
					id="app-select"
					labelId={"app-select-label"}
					margin="dense"
					onChange={onChange}
					value={selectedModuleUuid || ""}
					label={"Module"}
				>
					{modules
						.filter(module => modulesSubscriptions.some(sub => sub.moduleUuid === module.uuid))
						.map((module, index) => {
							return (
								<MenuItem value={module.uuid} key={index}>
									{module.uuid}&nbsp;<strong>{module.fullName}</strong>&nbsp;<i>{getSourceTag(module.tags)}</i>
								</MenuItem>
							);
						}
					)}
				</Select>
			</FormControl>
		</>
	)
}

export const Toolbar = ({
	viewOption,
	handleViewSelection,
	selectedAppUuid,
	handleAppSelection,
	selectedModuleUuid,
	handleModuleSelection,
	selectedCompany
}) => {
	
	return (
		<Grid item xs={12}>
			<ImpactBar
				component={
					<Grid container spacing={2} alignItems={"center"}>
						<Grid item>
							<ViewToggle
								viewOption={viewOption}
								handleViewSelection={handleViewSelection}
							/>
						</Grid>
						{viewOption === view_options.app && 
							<Grid item>
								<SelectApp
									selectedAppUuid={selectedAppUuid}
									handleAppSelection={handleAppSelection}
									selectedCompany={selectedCompany}
								/>
							</Grid>
						}
						{viewOption === view_options.module && 
							<Grid item>
								<SelectModule
									selectedModuleUuid={selectedModuleUuid}
									handleModuleSelection={handleModuleSelection}
									selectedCompany={selectedCompany}
								/>
							</Grid>
						}
					</Grid>
				}
			/>
		</Grid>
	)
}

export default Toolbar;