import JsonInput from "../../../Inputs/JsonInput";

export const ListInput = ({value, label, error, errorMessage, handleChange, disabled}) => {
	return (
		<JsonInput
			label={label}
			value={value} 
			handleUpdate={handleChange}
			helperText={error && errorMessage ? errorMessage : null}
			disabled={disabled}
		/>
	)
}

export default ListInput