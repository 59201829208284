import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import * as Constants from "../../../Constants";
import { RedirectLoader } from "../redirectUtils";
import {setAlertNotificationArgs} from "../../../store/alertsNotificationSlice";
import objInBody from "../../../helpers/objInBody";
import iso8601Timestamp from "../../../helpers/iso8601Timestamp";

const generateModuleViewNotificationArgs = (module, app, user) => {
	const {
		fullName,
		displayApps,
		displayTitles
	} = module
	const tempArgs = {
		url: Constants.SERVER_SEND_EMAIL,
		method: "POST",
		body: JSON.stringify({
			"body": `${user} viewed the ${fullName} module on ${window.location.href}<br/><br/>module: ${objInBody(module)}<br/><br/>app: ${objInBody(app)}`,
			"subject": `${user} viewed the ${fullName} module (${window.location.origin} - ${iso8601Timestamp()})`,
			"from": "noreply@wiselayer.com",
			"toEmail": Constants.notificationList.join(","),
			"sendHTML": true
		})
	}
	return tempArgs;
}

export const UserModuleRedirect = ({moduleUuid}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const user = useSelector((state) => state.role.name);
	const isInternal = useSelector((state) => state.role.isInternal);
	
	const modules = useSelector(state => state.modules.list);
	const modulesSubscriptions = useSelector(state => state.modules.subscriptionsList);
	
	const apps = useSelector(state => state.apps.filteredList);
	const subscribedApps = useSelector(state => state.appsSubscriptions.list);
	
	useEffect(() => {
		if (modulesSubscriptions.map(subscription => subscription.moduleUuid).includes(moduleUuid)) {
			console.log("module subscription found - redirecting to Dashboard");
			const module = modules.find(module => module.uuid === moduleUuid);
			const tempDashboardState = {
				viewModule: true,
				module: module,
				moduleUuid: moduleUuid,
				uuid: module.displayApps[0],
				customerSubscriptionUuid: subscribedApps.find(sub => sub.developerAppUuid === module.displayApps[0]).uuid,
				thisDevApp: apps.find(app => app.uuid === module.displayApps[0])
			}
			console.log("UserModuleRedirect - tempState", tempDashboardState)
			if (!isInternal) {
				dispatch(setAlertNotificationArgs(generateModuleViewNotificationArgs(module, apps.find(app => app.uuid === module.displayApps[0]), user)))
			}
			navigate(`/dashboard/${module.displayApps[0]}`, { state: tempDashboardState })
		}
	}, [modules, modulesSubscriptions, moduleUuid, apps, subscribedApps, user, isInternal])
	return (
		<RedirectLoader/>
	)
}

export default UserModuleRedirect;