import { useModuleSubscription } from "../hooks/useModuleSubscription";
import { useState, useMemo } from "react";
import { Grid, Typography, Tooltip, IconButton, FormGroup, FormControlLabel, Switch } from "@mui/material";
import DirectRequest from "../../../../API/requests/DirectRequest";
import PageviewIcon from '@mui/icons-material/Pageview';

export const ModuleSubscription = ({ module, handleSubscriptionsRefresh, selectedCompany, subscriptions }) => {
  const {
    moduleSubscriptionArgs,
    handleSubscriptionCreate,
    handleSubscriptionDelete,
    handleSubscriptionSuccess,
    handleViewModule
  } = useModuleSubscription(handleSubscriptionsRefresh, module.uuid, selectedCompany.uuid);
  
  const subscribed = useMemo(() => {
    return subscriptions.map(sub => sub.moduleUuid).includes(module.uuid);
  }, [subscriptions, module])
  
  const handleChange = () => {
    if (subscribed) {
      handleSubscriptionDelete()
    } else {
      handleSubscriptionCreate()
    }
  }
  
  return (
    <>
      <DirectRequest
        requestArgs={moduleSubscriptionArgs}
        afterProcess={handleSubscriptionSuccess}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px"
        }}
      >
        <IconButton 
          onClick={handleViewModule}
          disabled={!subscribed}
        >
          <PageviewIcon />
        </IconButton>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch size={"small"} checked={subscribed} onChange={handleChange} disabled={!selectedCompany.uuid} />
            }
            label={subscribed ? "Enabled" : "Disabled"}
          />
        </FormGroup>
      </div>
    </>
  );
}

export default ModuleSubscription