import { useState } from "react";
import * as Constants from "../../../../Constants";
/*
export const SERVER_GET_MODULES_URL = SERVER_BASE_URL + "/api/module/list_visible";
export const SERVER_GET_MODULES_SUBSCRIPTIONS_URL = SERVER_BASE_URL + "/api/module/subscriptions";
export const SERVER_SYSADMIN_GET_MODULES_URL = SERVER_BASE_URL + "/api/module/list";
export const SERVER_SYSADMIN_GET_COMPANY_MODULES_SUBSCRIPTIONS_URL = SERVER_BASE_URL + "/api/module/subscriptions";// + companyUuid
export const SERVER_SYSADMIN_CREATE_MODULE_URL = SERVER_BASE_URL + "/api/module/create";
export const SERVER_SYSADMIN_UPDATE_MODULE_URL = SERVER_BASE_URL + "/api/module/update";
export const SERVER_SYSADMIN_DELETE_MODULE_URL = SERVER_BASE_URL + "/api/module/delete";
export const SERVER_SYSADMIN_MODULE_SUBSCRIBE_URL = SERVER_BASE_URL + "/api/module/subscribe";
export const SERVER_SYSADMIN_MODULE_UNSUBSCRIBE_URL = SERVER_BASE_URL + "/api/module/unsubscribe";
*/
const subscriptionArgs = {
  /* TODO */
  url: Constants.SERVER_SYSADMIN_MODULE_SUBSCRIBE_URL,
  method: "POST",
  body: JSON.stringify({})
}

const getSubscribeArgs = (moduleUuid, companyUuid) => {
  return {
    url: Constants.SERVER_SYSADMIN_MODULE_SUBSCRIBE_URL,
    method: "POST",
    body: JSON.stringify({
      moduleUuid: moduleUuid,
      companyUuid: companyUuid
    })
  }
}

const getUnsubscribeArgs = (moduleUuid, companyUuid) => {
  return {
    url: Constants.SERVER_SYSADMIN_MODULE_UNSUBSCRIBE_URL,
    method: "POST",
    body: JSON.stringify({
      moduleUuid: moduleUuid,
      companyUuid: companyUuid
    })
  }
}

export const useModuleSubscription = (handleSubscriptionsRefresh, moduleUuid, companyUuid) => {
  const [moduleSubscriptionArgs, setModuleSubscriptionArgs] = useState(null);

  const handleSubscriptionCreate = () => {
    setModuleSubscriptionArgs(getSubscribeArgs(moduleUuid, companyUuid));// TODO: what's the expected payload??
  }

  const handleSubscriptionDelete = () => {
    setModuleSubscriptionArgs(getUnsubscribeArgs(moduleUuid, companyUuid));
  }

  const handleSubscriptionSuccess = () => {
    setModuleSubscriptionArgs(null);
    handleSubscriptionsRefresh();
  }
  
  const handleViewModule = () => {
    window.open(`${window.location.origin}/module/redirect/${moduleUuid}/${companyUuid}`, '_blank');
  }

  return {
    moduleSubscriptionArgs,
    handleSubscriptionCreate,
    handleSubscriptionDelete,
    handleSubscriptionSuccess,
    handleViewModule,
  }
}