import React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AppCardCustomerButtonPickerV2 from "./AppCardCustomerButtonPickerV2";

export default function AppCard({app, subscribed, customerSubscription}) {
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      {/* card level */}
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={9}>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography
                variant={"p"}
                sx={{ fontWeight: "bold" }}
              >
                {app.fullName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={"p"}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {app.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={"p"}
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: theme.palette.primary.main
                }}
              >
                  <i>{app.tags.find(tag => tag.includes("impact__"))?.split("__")[1] || "Coming soon"}</i>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container sx={{ width: "100%" }}>
            <AppCardCustomerButtonPickerV2
              app={app}
              subscribed={subscribed}
              customerSubscription={customerSubscription}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
