import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { form_fields } from "../../hooks/useModuleForm";

const ReferenceButton = ({
  data, // array of objects to display
  columns, // array of strings corresponding to object keys to display
  copyField, // field to copy when action button clicked
  title = "Reference", // window title
  searchField = columns[0], // field to search by (defaults to first column)
}) => {
  const openReference = () => {
    const windowFeatures = "width=800,height=600,resizable=yes,scrollbars=yes";
    const newWindow = window.open("", title, windowFeatures);

    const html = `
			<!DOCTYPE html>
			<html>
			<head>
				<title>${title}</title>
				<style>
					body {
						font-family: system-ui, -apple-system, sans-serif;
						padding: 20px;
						margin: 0;
						background: #f9fafb;
					}
					.section-title {
						color: #111827;
						font-size: 1.125rem;
						font-weight: 500;
						margin-bottom: 16px;
					}
					.apps-table {
						width: 100%;
						border-collapse: separate;
						border-spacing: 0 8px;
					}
					.apps-table th {
						text-align: left;
						color: #6b7280;
						font-size: 0.875rem;
						font-weight: 500;
						padding: 8px 12px;
						background: #f9fafb;
					}
					.apps-table td {
						background: white;
						padding: 12px;
						font-size: 0.875rem;
						border-top: 1px solid #e5e7eb;
						border-bottom: 1px solid #e5e7eb;
					}
					.apps-table td:first-child {
						border-left: 1px solid #e5e7eb;
						border-top-left-radius: 6px;
						border-bottom-left-radius: 6px;
					}
					.apps-table td:last-child {
						border-right: 1px solid #e5e7eb;
						border-top-right-radius: 6px;
						border-bottom-right-radius: 6px;
					}
					.apps-table tr:hover td {
						background: #f3f4f6;
					}
					.copy-cell {
						cursor: pointer;
						display: flex;
						align-items: center;
						gap: 8px;
					}
					.copy-icon {
						width: 16px;
						height: 16px;
						color: #6b7280;
					}
					.copy-success {
						color: #059669;
						font-size: 0.75rem;
						display: none;
						margin-left: 4px;
					}
					.search-container {
						margin-bottom: 16px;
					}
					.search-input {
						width: 70%;
						padding: 8px 12px;
						border: 1px solid #e5e7eb;
						border-radius: 6px;
						font-size: 0.875rem;
					}
				</style>
			</head>
			<body>
				<div class="section-title">${title}</div>
				<div class="search-container">
					<input 
						type="text" 
						class="search-input" 
						placeholder="Search..." 
						oninput="filterItems(this.value)"
					>
				</div>
				<table class="apps-table">
					<thead>
						<tr>
							${columns
                .map(
                  (col) => `
								<th>${col}</th>
							`
                )
                .join("")}
							<th>Actions</th>
						</tr>
					</thead>
					<tbody id="data-tbody">
						${data
              .map(
                (item, index) => `
							<tr data-search="${item[searchField].toLowerCase()}">
								${columns
                  .map(
                    (col) => `
									<td>${item[col]}</td>
								`
                  )
                  .join("")}
								<td>
									<div class="copy-cell" onclick="copyToClipboard('${
                    item[copyField]
                  }', ${index})">
										<svg class="copy-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
											<path d="M8 17.929H6c-1.105 0-2-.912-2-2.036V5.036C4 3.912 4.895 3 6 3h8c1.105 0 2 .912 2 2.036v1.866m-6 .17h8c1.105 0 2 .91 2 2.035v10.857C20 21.088 19.105 22 18 22h-8c-1.105 0-2-.911-2-2.036V9.107c0-1.124.895-2.036 2-2.036z"/>
										</svg>
										<span>Copy ${copyField}</span>
										<span id="copy-success-${index}" class="copy-success">Copied!</span>
									</div>
								</td>
							</tr>
						`
              )
              .join("")}
					</tbody>
				</table>

				<script>
					function copyToClipboard(value, index) {
						const textToCopy = \`"\${value}"\`;
						navigator.clipboard.writeText(textToCopy).then(() => {
							const successElement = document.getElementById(\`copy-success-\${index}\`);
							successElement.style.display = 'inline';
							setTimeout(() => {
								successElement.style.display = 'none';
							}, 3000);
						}).catch(err => {
							console.error('Failed to copy text: ', err);
						});
					}

					function filterItems(searchTerm) {
						const rows = document.querySelectorAll('#data-tbody tr');
						const term = searchTerm.toLowerCase();
						
						rows.forEach(row => {
							const searchText = row.getAttribute('data-search');
							row.style.display = searchText.includes(term) ? '' : 'none';
						});
					}
				</script>
			</body>
			</html>
		`;

    newWindow.document.write(html);
    newWindow.document.close();
  };

  return (
    <Tooltip title={title}>
      <IconButton sx={{ padding: "4px" }} onClick={openReference}>
        <InfoIcon sx={{ fontSize: "18px" }} />
      </IconButton>
    </Tooltip>
  );
};

export default ReferenceButton;
