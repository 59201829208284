import { useEffect, useState, useMemo } from "react";
import { form_fields } from "./createEntryForm";
import { getTimeAgo } from "./helpers/getTimeAgo";
import { Box, Grid} from "@mui/material"
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";
import { formatToTwoDecimals } from "./helpers/formatToTwoDecimals";

const AdjustmentNeededMessage = ({ recipientEmailAddress, variance, emailResponseTimestamp }) => {
  return (<span><u>{recipientEmailAddress}</u> responded with an unsent amount of <strong>{formatToTwoDecimals(variance)}</strong> to be accrued</span>)
}
// {getTimeAgo(emailResponseTimestamp) ?? ""} - removed from above because getTimeAgo language is confusing with updated message

const NoAdjustmentNeededMessage = ({ recipientEmailAddress, clientEmailExpectedAmount, emailResponseTimestamp }) => {
  return (<span><u>{recipientEmailAddress}</u> confirmed the amount of <strong>{formatToTwoDecimals(clientEmailExpectedAmount)}</strong> {getTimeAgo(emailResponseTimestamp) ?? ""}</span>)
}

const message_types = {
  no_message: "no_message",
  no_adjustment_message: "no_adjustment_message",
  adjustment_message: "adjustment_message",
  adjustment_override_message: "adjustment_override_message"
}

export const ClientEmailMessage = ({ clientEmail, clientEmailExpectedAmount }) => {
  const theme = useTheme();
  const messageType = useMemo(() => {
    if (clientEmail.uuid) {
      /* && clientEmail.aiReviewState === "llm_processed"
      - removed from if condition above to support case where override attributes present and llm errored
      - Eric 1/17*/
      if (clientEmail.attributes) {
        if (Object.keys(clientEmail.attributes).includes("adjustmentNeeded_override")) {
          if (clientEmail.attributes.adjustmentNeeded_override && Object.keys(clientEmail.attributes).includes("variance_override")) {
            return message_types.adjustment_override_message
          } else if (!clientEmail.attributes.adjustmentNeeded_override) {
            return message_types.no_adjustment_message
          }
        } else if (Object.keys(clientEmail.attributes).includes("adjustmentNeeded")) {
          if (clientEmail.attributes.adjustmentNeeded && Object.keys(clientEmail.attributes).includes("variance")) {
            // newFormState[form_fields.emailResponseMessage] = `${clientEmail.recipientEmailAddress} responded with an adjusted amount of ${clientEmail.attributes.variance} ${getTimeAgo(clientEmail.emailResponseTimestamp) ?? ""}`;
            return message_types.adjustment_message
          } else if (!clientEmail.attributes.adjustmentNeeded) {
            // newFormState[form_fields.emailResponseMessage] = `${clientEmail.recipientEmailAddress} confirmed this amount ${getTimeAgo(clientEmail.emailResponseTimestamp) ?? ""}`;
            return message_types.no_adjustment_message
          }
        }
      }
    }
    return message_types.no_message
  }, [clientEmail])
  return messageType === message_types.adjustment_override_message ? (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center">
        <InfoIcon sx={{ marginRight: "8px", color: theme.palette.primary.main }} />
        <AdjustmentNeededMessage
          //recipientEmailAddress, variance, emailResponseTimestamp
          recipientEmailAddress={clientEmail.recipientEmailAddress}
          variance={clientEmail.attributes.variance_override}
          emailResponseTimestamp={clientEmail.emailResponseTimestamp}
        />
      </Box>
    </Grid>
  ) : messageType === message_types.adjustment_message ? (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center">
        <InfoIcon sx={{ marginRight: "8px", color: theme.palette.primary.main }} />
        <AdjustmentNeededMessage
          //recipientEmailAddress, variance, emailResponseTimestamp
          recipientEmailAddress={clientEmail.recipientEmailAddress}
          variance={clientEmail.attributes.variance}
          emailResponseTimestamp={clientEmail.emailResponseTimestamp}
        />
      </Box>
    </Grid>
  ) : messageType === message_types.no_adjustment_message ? (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center">
        <InfoIcon sx={{ marginRight: "8px", color: theme.palette.primary.main }} />
        <NoAdjustmentNeededMessage
          //recipientEmailAddress, emailResponseTimestamp
          recipientEmailAddress={clientEmail.recipientEmailAddress}
          clientEmailExpectedAmount={clientEmailExpectedAmount}
          emailResponseTimestamp={clientEmail.emailResponseTimestamp}
        />
      </Box>
    </Grid>
  ) : null;
}

export default ClientEmailMessage;