import { Grid, Typography, Tooltip } from "@mui/material";
import { form_fields} from "../hooks/useModuleForm";
import SellIcon from '@mui/icons-material/Sell';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AppsIcon from '@mui/icons-material/Apps';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import PolylineIcon from '@mui/icons-material/Polyline';
/*
module:
    String uuid;
    String companyUuid;
    String externalUuid;
    String name;
    String createdBy;

    String fullName;
    String description;

    // The apps that should have visual tabs;
    // there may be apps run in the dependency graph that are not displayed and thus not included in this list.
    List<String> displayApps;

    // a map describing the dependency graph of the module.
    // There should always be one or more nodes that are "root" nodes that are the first apps to be added to the queue
    // When all parent nodes' jobs have finished, then the child node should be added to the job queue.
    // Todo: Junaid decide what directed graph implementation to use
    DirectedGraph<String> appDependencyGraph;

    List<String> tags;

    List<String> visibleToCompanyUuids;
    List<String> hideFromCompanyUuids;
*/
const iconStyle = { fontSize: "14px", color: "rgba(0, 0, 0, 0.5)" };

const details_fields = [
  { field: form_fields.uuid, tooltip: false, icon: null },
  { field: form_fields.name, tooltip: false, icon: null },
  { field: form_fields.fullName, tooltip: false, icon: null },
  { field: form_fields.description, tooltip: false, icon: null },
  { field: form_fields.tags, tooltip: true, icon: <SellIcon sx={iconStyle}/> },
  { field: form_fields.visibleToCompanyUuids, tooltip: true, icon: <VisibilityIcon sx={iconStyle}/> },
  { field: form_fields.hideFromCompanyUuids, tooltip: true, icon: <VisibilityOffIcon sx={iconStyle}/> },
  { field: form_fields.displayApps, tooltip: true, icon: <AppsIcon sx={iconStyle}/> },
  { field: form_fields.displayTitles, tooltip: true, icon: <FormatQuoteIcon sx={iconStyle}/> },
  { field: form_fields.appDependencyGraph, tooltip: true, icon: <PolylineIcon sx={iconStyle}/> },
]

const Detail = ({field, value, tooltip, icon}) => {
  if (tooltip) {
    return (
      <Grid item>
        <div style={{ display: "flex", alignItems: "center"}}>
          <strong>{field}:</strong>&nbsp;
          <Tooltip title={<pre>{JSON.stringify(value, null, 2)}</pre>} arrow followCursor>
            {icon}
          </Tooltip>
        </div>
      </Grid>
    )
  } else {
    return (
      <Grid item><strong>{field}:</strong>&nbsp;{value}</Grid>
    )
  }
}

export const ModuleDetails = ({module, companies}) => {
  // uuid - name
  // fullName
  // description
  return (
    <>
      {details_fields.map(detail_field => {
        const { field, tooltip, icon } = detail_field;
        const value = module[field];
        return (
          <Detail
            key={field + "_detail"}
            field={field}
            value={value}
            tooltip={tooltip}
            icon={icon}
          />
        )
      })}
    </>
  )
}

export default ModuleDetails;