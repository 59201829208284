import { Modal, Box } from "@mui/material";

const ModalWrapper = ({ children, open, onClose, width = 800 }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableRestoreFocus
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width,
          maxHeight: '100vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default ModalWrapper;