import { useState, useMemo } from "react";
import DirectRequest from "./DirectRequest";
import {useDispatch, useSelector} from "react-redux";
import { 
	setModulesAndSubscriptions,
	setModulesAndSubscriptionsPayload,
	setList, 
	setListPayload, 
	setModulesError,
	setSubscriptionsError, 
	setErrorPayload 
} from "../../store/modulesSlice";
import * as Constants from "../../Constants";

const modulesArgs = {
	url: Constants.SERVER_GET_MODULES_URL
}

const subscriptionsArgs = {
	url: Constants.SERVER_GET_MODULES_SUBSCRIPTIONS_URL
}

const initialListState = {
	list: [],
	isCaptured: false
}

export const sortModules = (apps) => {
	return apps.sort((a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()));
}

const useModules = () => {
	const dispatch = useDispatch();
	const [modules, setModules] = useState(initialListState)
	
	const handleModules = (res) => {
		const sortedModules = sortModules([...res]);
		setModules({ list: sortedModules, isCaptured: true });
	}
	
	const handleModulesError = (errType, err) => {
		const errorMessage = `There was a ${errType} error getting your modules. Please refresh the page.`
		dispatch(setModulesError(setErrorPayload(errorMessage)));
		setModules({ list: [], isCaptured: true });
	}
	
	return {
		modules,
		handleModules,
		handleModulesError
	}
}

const useSubscriptions = () => {
	const dispatch = useDispatch();
	const [subscriptions, setSubscriptions] = useState(initialListState);
	
	const handleSubscriptions = (res) => {
		const subscriptions = [...res].map(module => {
			return {
				moduleUuid: module.uuid
			}
		})
		setSubscriptions({ list: subscriptions, isCaptured: true });
	}
	
	const handleSubscriptionsError = (errType, err) => {
		const errorMessage = `There was a ${errType} error getting your modules subscriptions. Please refresh the page.`
		dispatch(setSubscriptionsError(setErrorPayload(errorMessage)));
		setSubscriptions({ list: [], isCaptured: true });
	}
	
	return {
		subscriptions,
		handleSubscriptions,
		handleSubscriptionsError	
	}
}

const useModulesAndSubscriptions = () => {
	const dispatch = useDispatch();
	const {
		modules,
		handleModules,
		handleModulesError
	} = useModules();
	const {
		subscriptions,
		handleSubscriptions,
		handleSubscriptionsError
	} = useSubscriptions();
	// should I useSelector here?
	const isReady = useSelector(state => state.modules.isReady);
	
	if (modules.isCaptured && subscriptions.isCaptured && !isReady) {
		console.warn("dispatching setModulesAndSubscriptions")
		console.warn("modules", modules.list);
		console.warn("subscriptions", subscriptions.list)
		dispatch(setModulesAndSubscriptions(setModulesAndSubscriptionsPayload(modules.list, subscriptions.list)))
	}
	
	return {
		modules,
		handleModules,
		handleModulesError,
		subscriptions,
		handleSubscriptions,
		handleSubscriptionsError
	};
}

const ModulesAndSubscriptionsRequest = () => {
	const {
		modules,
		handleModules,
		handleModulesError,
		subscriptions,
		handleSubscriptions,
		handleSubscriptionsError
	} = useModulesAndSubscriptions();

	return (
		<>
			<DirectRequest
				requestArgs={modules.isCaptured ? null : modulesArgs}
				afterProcess={handleModules}
				handleError={(err) => handleModulesError(Constants.requestErrorTypes.server, err)}
				handleCatchError={(err) => handleModulesError(Constants.requestErrorTypes.network, err)}
			/>
			<DirectRequest
				requestArgs={subscriptions.isCaptured ? null : subscriptionsArgs}
				afterProcess={handleSubscriptions}
				handleError={(err) => handleSubscriptionsError(Constants.requestErrorTypes.server, err)}
				handleCatchError={(err) => handleSubscriptionsError(Constants.requestErrorTypes.network, err)}
			/>
		</>
	);
}

export const ModulesRequest = () => {
	return (
		<>
			<ModulesAndSubscriptionsRequest/>
			{/* TODO (maybe): update subscriptions request (will users subscribe to modules themselves?) */}
		</>
	);
}

export default ModulesRequest;