import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserModuleRedirect from "./UserModuleRedirect";
import SysAdminModuleRedirect from "./SysAdminModuleRedirect";

export const ModuleRedirect = () => {
	const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
	const page_params = useParams();
	const moduleUuid = page_params.moduleUuid;
	const companyUuid = page_params.companyUuid;
	// console.log("Redirect - sysadmin:", isSysAdmin, "dashboardID", dashboardID, "alertID:", alertID, "companyID:", companyID);
	console.log("ModuleRedirect - moduleUuid", moduleUuid);
	return (
		<>
			{!companyUuid && <UserModuleRedirect moduleUuid={moduleUuid}/>}
			{companyUuid && <SysAdminModuleRedirect moduleUuid={moduleUuid} companyUuid={companyUuid}/>}
		</>
	)
}
// {companyUuid && isSysAdmin && <SysAdminModuleRedirect companyUuid={companyUuid} moduleUuid={moduleUuid}/>}
export default ModuleRedirect;