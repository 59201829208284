import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export const DropdownInput = ({ value, label, options, handleChange, disabled }) => {
	const theme = useTheme();
	const handleDropdownSelection = (event) => {
		const selectedValue = event.target.value;
		handleChange(selectedValue);
	}
	return (
		<FormControl fullWidth size={"small"}>
			<InputLabel id={`config-option-label_${options?.join("_") ?? ""}`}><span style={{color: "rgba(0, 0, 0, 0.6)",
				transform: "scale(0.75)",
				transformOrigin: "left"}}>{label}</span></InputLabel>
			<Select
				labelId={`config-option-label_${options?.join("_") ?? ""}`}
				id={`config-option_${options?.join("_") ?? ""}`}
				fullWidth
				label={label}
				value={value}
				onChange={handleDropdownSelection}
				sx={{ borderColor: theme.palette.primary.main, width: "100%" }}
				disabled={disabled}
			>
				{options?.map((value, index) => {
					return (
						<MenuItem key={`${index}_${value}_option`} value={value}>
							{value}
						</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}

export default DropdownInput;