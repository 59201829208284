import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import StringInput from "../../../Inputs/StringInput";

// should work for TEXT configs and config displayNote
export const TextInput = ({ value, label, handleChange, disabled, minLines, error, errorMessage }) => {
	return (
		<StringInput
			value={value}
			handleUpdate={handleChange}
			label={label}
			multiline={true}
			disabled={disabled}
			error={error}
			errorMessage={errorMessage}
		/>
	)
}

export default TextInput;