import { useState } from "react";
import JsonInput from "../../../../Inputs/JsonInput";
import { isValidJson } from "../../hooks/useModuleForm";
import ReferenceButton from "../Buttons/ReferenceButton";

const copyField = "uuid";

const columns = ["name", "uuid"];

export const HideFromInput = ({
  field,
  formState,
  handleFormUpdate,
  companies,
}) => {
  const sortedCompanies = [...companies].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  const [helperText, setHelperText] = useState("");
  const handleUpdate = (newValue) => {
    handleFormUpdate(field, newValue);
    /* TODO */
    // hideFrom validation/helperText
  };
  return (
    <div style={{ position: "relative" }}>
      <JsonInput
        label={field}
        value={formState[field]}
        handleUpdate={handleUpdate}
        helperText={helperText}
      />
      <div style={{ position: "absolute", top: "-5px", right: "0px" }}>
        <ReferenceButton
          data={sortedCompanies}
          columns={columns}
          copyField={copyField}
          title="Companies Reference"
        />
      </div>
    </div>
  );
};

export default HideFromInput;
