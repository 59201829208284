import { Button } from "@mui/material";

export const UpdateButton = ({ isValid, handleModuleUpdate }) => {
  return (
    <Button
      variant={"contained"}
      size={"small"}
      onClick={handleModuleUpdate}
      disabled={!isValid}
    >
      Update
    </Button>
  );
};

export default UpdateButton;
