export const objInBody = (obj) => {
	try {
		// Stringify it with nice formatting
		const formattedJSON = JSON.stringify(obj, null, 2);
		// Wrap in pre tags and style for email readability
		return `
			<pre style="
				background-color: #f5f5f5;
				padding: 10px;
				border-radius: 4px;
				font-family: monospace;
				white-space: pre-wrap;
				word-wrap: break-word;
				font-size: 14px;
				color: #333;
				margin: 10px 0;
			">${formattedJSON}</pre>
		`;
	} catch (e) {
		// Handle case where the obj can't be valid JSON
		return `<p style="color: #666;">Unable to stringify obj: ${obj}</p>`;
	}
}

export default objInBody;