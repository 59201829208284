import { useState, useEffect } from "react"
import * as Constants from "../../../../Constants";
import { view_options } from "./useToolbar";
import { config_fields } from "./useMultiAppConfigsForm";

const sortMultiAppConfigs = (multiAppConfigs) => {
	return [...multiAppConfigs].sort((a, b) => {
		return a[config_fields.displayName].localeCompare(b[config_fields.displayName]);
	})
}

const getMultiAppConfigsArgs = (viewOption, uuid) => {
	if (viewOption === view_options.module) {
		return {
			url:  Constants.SERVER_SYSADMIN_MULTI_APP_CONFIGS_BY_MODULE_URL,
			method: "POST",
			body: JSON.stringify({
				moduleUuid: uuid
			})
		}
	} else {
		return {
			url:  Constants.SERVER_SYSADMIN_MULTI_APP_CONFIGS_BY_APP_URL,
			method: "POST",
			body: JSON.stringify({
				developerAppUuid: uuid
			})
		}
	}
}

export const useMultiAppConfigsByApp = (selectedCompany, selectedAppUuid) => {
	const [multiAppConfigsArgs, setMultiAppConfigsArgs] = useState(null);
	const [multiAppConfigs, setMultiAppConfigs] = useState([]);
	
	const handleMultiAppConfigs = (res) => {
		console.log("useMultiAppConfigsByApp - handleMultiAppConfigs - res", res);
		const sortedConfigs = sortMultiAppConfigs(res.multiAppConfigs);
		setMultiAppConfigs(sortedConfigs);
		setMultiAppConfigsArgs(null);
	}
	
	useEffect(() => {
		if (selectedAppUuid && selectedCompany && selectedCompany.uuid) {
			console.log("useMultiAppConfigsByApp - selectedAppUuid", selectedAppUuid)
			setMultiAppConfigs([]);
			setMultiAppConfigsArgs(getMultiAppConfigsArgs(view_options.app, selectedAppUuid));
		}
	}, [selectedAppUuid, selectedCompany])
	
	return {
		multiAppConfigs,
		multiAppConfigsArgs,
		handleMultiAppConfigs
	}
}

export const useMultiAppConfigsByModule = (selectedCompany, selectedModuleUuid) => {
	const [multiAppConfigsArgs, setMultiAppConfigsArgs] = useState(null);
	const [multiAppConfigs, setMultiAppConfigs] = useState([]);
	
	const handleMultiAppConfigs = (res) => {
		console.log("useMultiAppConfigsByApp - handleMultiAppConfigs - res", res);
		const sortedConfigs = sortMultiAppConfigs(res.multiAppConfigs);
		setMultiAppConfigs(sortedConfigs);
		setMultiAppConfigsArgs(null);
	}
	
	useEffect(() => {
		if (selectedModuleUuid && selectedCompany && selectedCompany.uuid) {
			setMultiAppConfigs([]);
			setMultiAppConfigsArgs(getMultiAppConfigsArgs(view_options.module, selectedModuleUuid));
		}
	}, [selectedModuleUuid, selectedCompany])
	
	return {
		multiAppConfigs,
		multiAppConfigsArgs,
		handleMultiAppConfigs
	}
}

export const useMultiAppConfigs = () => {
	const [multiAppConfigsArgs, setMultiAppConfigsArgs] = useState({ url: Constants.SERVER_SYSADMIN_MULTI_APP_CONFIGS_BY_ALL_URL });
	const [multiAppConfigs, setMultiAppConfigs] = useState([]);
	
	const handleMultiAppConfigs = (res) => {
		console.log("useMultiAppConfigs - handleMultiAppConfigs - res", res);
		const sortedConfigs = sortMultiAppConfigs(res.multiAppConfigs);
		setMultiAppConfigs(sortedConfigs);
		setMultiAppConfigsArgs(null);
	}
	
	const refreshMultiAppConfigs = () => {
		console.log("refreshMultiAppConfigs");
		setMultiAppConfigsArgs({ url: Constants.SERVER_SYSADMIN_MULTI_APP_CONFIGS_BY_ALL_URL });
	}
	
	return {
		multiAppConfigs,
		multiAppConfigsArgs,
		handleMultiAppConfigs,
		refreshMultiAppConfigs
	}
}