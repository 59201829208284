import { useState } from "react";

export const isValidJson = (value) => {
  try {
    const parsedJson = JSON.parse(value);
    return true;
  } catch (err) {
    return false;
  }
};
/*
module:
    String uuid;
    String companyUuid;
    String externalUuid;
    String name;
    String createdBy;

    String fullName;
    String description;

    // The apps that should have visual tabs;
    // there may be apps run in the dependency graph that are not displayed and thus not included in this list.
    List<String> displayApps;
    List<String> displayTitles;

    // a map describing the dependency graph of the module.
    // There should always be one or more nodes that are "root" nodes that are the first apps to be added to the queue
    // When all parent nodes' jobs have finished, then the child node should be added to the job queue.
    // Todo: Junaid decide what directed graph implementation to use
    DirectedGraph<String> appDependencyGraph;

    List<String> tags;

    List<String> visibleToCompanyUuids;
    List<String> hideFromCompanyUuids;
*/
const initialFormState = {
  name: "",
  fullName: "",
  description: "",
  appDependencyGraph: {}, // object of keys with array of strings values
  displayApps: [], // array of strings
  displayTitles: [], // array of strings - needs to be ordered with displayApps
  tags: [], // array of strings
  visibleToCompanyUuids: [], // array of strings
  hideFromCompanyUuids: [], // array of strings
};

export const initialFormFields = Object.keys(initialFormState);

const existingModuleFormState = {
  ...initialFormState,
  uuid: "",
  // ... other fields
};

export const existingModuleFormFields = Object.keys(existingModuleFormState);

export const form_fields = {
  uuid: "uuid",
  name: "name",
  fullName: "fullName",
  description: "description",
  appDependencyGraph: "appDependencyGraph",
  displayApps: "displayApps",
  displayTitles: "displayTitles",
  tags: "tags",
  visibleToCompanyUuids: "visibleToCompanyUuids",
  hideFromCompanyUuids: "hideFromCompanyUuids",
};

export const string_fields = [
  form_fields.name,
  form_fields.fullName,
  form_fields.description,
  form_fields.uuid,
];

export const json_fields = [
  form_fields.appDependencyGraph,
  form_fields.displayApps,
  form_fields.displayTitles,
  form_fields.tags,
  form_fields.visibleToCompanyUuids,
  form_fields.hideFromCompanyUuids,
];

export const disabled_fields = [form_fields.uuid];

const generateFormState = (existingModule) => {
  console.log("generateFormState - existingModule", existingModule);
  if (!existingModule) {
    // return initialFormState;
    return Object.keys(initialFormState).reduce((acc, key) => {
      if (json_fields.includes(key)) {
        acc[key] = JSON.stringify(initialFormState[key], null, 2);
      } else {
        acc[key] = initialFormState[key];
      }
      return acc;
    }, {});
  }

  // Create new object with existingModuleFormState properties
  // populated with matching values from existingModule
  return Object.keys(existingModuleFormState).reduce((acc, key) => {
    if (json_fields.includes(key)) {
      acc[key] =
        JSON.stringify(existingModule[key], null, 2) ?? existingModuleFormState[key];
    } else {
      acc[key] = existingModule[key] ?? existingModuleFormState[key];
    }
    return acc;
  }, {});
};

export const validateForm = (formState) => {
  // if string_fields.includes(field) -> non-empty string
  // if json_fields.includes(field) -> isValidJson (not touching value validation - just needs to be valid json)
  let isValid = true;
  Object.keys(formState).forEach(field => {
    if (string_fields.includes(field) && !formState[field]) {
      // if empty string - not valid
      console.warn(`validateForm - string field ${field} is not valid`, formState[field]);
      isValid = false
    } else if (json_fields.includes(field) && !isValidJson(formState[field])) {
      console.warn(`validateForm - json field ${field} is not valid`, formState[field]);
      isValid = false
    }
  })
  return isValid;
};

/* may need to specify if create or edit - would be passed as arg to this hook */
export const useModuleForm = (existingModule = null) => {
  const [formState, setFormState] = useState(() =>
    generateFormState(existingModule)
  ); /* TODO */
  const isValid = validateForm(formState);

  const [showForm, setShowForm] = useState(false);

  const toggleShowForm = () => {
    setShowForm(!showForm);
    setFormState(generateFormState(existingModule));
  };

  const handleFormUpdate = (field, value) => {
    console.log("field", field);
    console.log("value", value);
    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [field]: value,
      };
    });
  };

  return {
    formState,
    isValid,
    showForm,
    toggleShowForm,
    handleFormUpdate,
  };
};
