import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import TopBar from "../../TopBar/TopBar";
import { useSelector } from "react-redux";
import LogoutIcon from '@mui/icons-material/Logout';

const getTitle = (state, previewApp, layout, isReport) => {
  if (state.preview) {
    if (previewApp.alerts && previewApp.alerts.length > 0) {
      return previewApp.alerts[0].appNameDisplay + " - Preview";
    } else {
      return layout.title + " - Preview";
    }
  } else {
    if (state.thisDevApp?.tags.includes("view_config__hide_alerts")) {
      return isReport ? "Report - " + layout.title : layout.title
    } else {
      if (state.thisAlert?.appNameDisplay) {
        return state.thisAlert?.appNameDisplay
      } else {
        return isReport ? "Report - " + layout.title : layout.title
      }
    }
  }
}

export const FDTopBar = ({ state, cancelAction, layout }) => {
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const previewApp = useSelector(state => state.previewApp);
  const loading = useSelector(state => state.dashboardFilters.loading);
  const isReport = useSelector(state => state.dashboard.isReport);
  const isAccrualsUser = useSelector((state) => state.role.isAccrualsUser);
  const isModulesUser = useSelector(state => state.role.isModulesUser);
  return (
    <Grid item xs={12}>
      <TopBar
        cancel_button={true}
        cancel_action={cancelAction}
        hide_help={true}
        description={layout.description}
        top_message={getTitle(state, previewApp, layout, isReport)}
        loading={loading}
        recipe
        preview={state.preview}
        cancel_icon={(isAccrualsUser || isModulesUser) ? <LogoutIcon/> : (state.preview || state.emailLink) ? <ChevronLeftIcon/> : <CloseIcon/>}
        cancel_text={
          (isAccrualsUser || isModulesUser) ? "Logout"
            : state.preview ? "Return to Builder"
            : state.emailLink && state.companyUuid && isSysAdmin ? "SysAdmin alerts" 
            : state.emailLink ? "Alerts" 
            : "Close"
        }
        journal_entry_button={true}
        view_module={state.viewModule}
        module_uuid={state.moduleUuid}
        company_uuid={state.companyUuid}
      />
    </Grid>
  )
}

export default FDTopBar;