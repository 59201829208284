import { createSlice } from '@reduxjs/toolkit';

export const module_fields = {
	uuid: "uuid",
	name: "name",
	fullName: "fullName",
	description: "description",
	appDependencyGraph: "appDependencyGraph",
	displayApps: "displayApps",
	displayTitles: "displayTitles",
	tags: "tags",
	visibleToCompanyUuids: "visibleToCompanyUuids",
	hideFromCompanyUuids: "hideFromCompanyUuids",
}

const initialModulesState = {
	uuid: "",
	list: [],
	modulesCaptured: false,
	modulesError: {
		isErrored: false,
		errorMessage: "",
	},
	subscriptionsList: [],
	subscriptionsCaptured: false,
	subscriptionsError: {
		isErrored: false,
		errorMessage: "",
	},
	isReady: false,
}

export const setModulesAndSubscriptionsPayload = (modules, subscriptions) => {
	return {
		list: modules,
		subscriptionsList: subscriptions
	}
}

export const setListPayload = (modules) => {
	return {
		list: modules
	}
}

export const setErrorPayload = (errorMessage) => {
	return {
		isErrored: true,
		errorMessage: errorMessage
	}
}

export const modulesSlice = createSlice({
	name: "modules",
	initialState: initialModulesState,
	reducers: {
		setModulesAndSubscriptions: (state, action) => {
			console.log("modules - setModulesAndSubscriptions");
			const {
				list,
				subscriptionsList
			} = action.payload;
			state.list = list;
			state.subscriptionsList = subscriptionsList;
			state.isReady = true;
		},
		setList: (state, action) => {
			console.log("modules - setList", action.payload);
			const {
				list
			} = action.payload;
			state.list = list;
			state.modulesCaptured = true;
		},
		setSubscriptionsList: (state, action) => {
			console.log("modules - setSubscriptionsList", action.payload);
			const {
				list
			} = action.payload;
			state.subscriptionsList = list;
			state.subscriptionsCaptured = true;
		},
		setModulesError: (state, action) => {
			console.log("modules - setModulesError", action.payload);
			state.modulesError = action.payload;
		},
		setSubscriptionsError: (state, action) => {
			console.log("modules - setSubscriptionsError", action.payload);
			state.subscriptionsError = action.payload;
		},
		setUuid: (state, action) => {
			console.log("modules - setUuid", action.payload);
			state.uuid = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setModulesAndSubscriptions,
	setList,
	setSubscriptionsList,
	setModulesError,
	setSubscriptionsError,
	setUuid
} = modulesSlice.actions;

export default modulesSlice.reducer;