import { Grid, Button, CircularProgress, Chip } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	config_options, 
	dashboardConfigsSelectors, 
	updateConfig, 
	rowConfigSetupInfo_fields, 
	createEntryConfig_fields,
	accountMappingConfig_fields,
	autoPerformConfig_fields,
	sendEmailConfig_fields
} from "../../../../store/dashboardConfigsSlice";
import {generateDisplayConfigUpdateArgs, generateDisplayConfigUpdateNotification} from "./DisplayConfigUpdateUtils"
/*
	I believe we can use DashboardConfigsUpdateRequest just like CreateEntryConfigActionButton - this request just looks for an args array in it's config and runs - one open request for each configUuid
	
	should be double click confirms
	
	const configUpdateArgs = generateDisplayConfigUpdateArgs(
		companyUuid, 
		newConfigValue, 
		configAction, 
		row, 
		displayConfigUuid, 
		entryPeriod, 
		primaryKeyFieldList, 
		linkedTableForeignFieldMap, 
		statusTrackerConfig
	);
*/

const generateAutoCreateEntryArgs = (rows, generationConfigs) => {
	const {
		displayConfigUuid,
		companyUuid,
		createEntryConfig,
		statusTrackerConfig,
		entryPeriod,
		sourceTable,
		primaryKeyFieldList,
		linkedTableForeignFieldMap,
		displayConfigTableRows,
		tables
	} = generationConfigs;
	
	const entry_fields = createEntryConfig_fields;
	const auto_perform_fields = autoPerformConfig_fields;
	const setup_fields = rowConfigSetupInfo_fields[config_options.create_entry];
	
	let argsArr = [];
	
	rows.forEach(row => {
		console.log("checking row for autoCreateEntry", row);
		const displayConfigTableRow = displayConfigTableRows
			?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap)
			.every(([field, value]) => row[field] === value));
		
		const hidden = displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.["optionValue"];
		const autoPerformFlag = row[createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
		const validStatus = createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
		
		if (!hidden && autoPerformFlag && validStatus) {
			console.log("row qualifies - generating createEntryArgs");
			
			// rowConfigSetupInfo generation
			const entryUuid = "";
			const rowConfigSetupInfo = {};
			
			rowConfigSetupInfo[setup_fields.sourceTableName] = sourceTable;
			rowConfigSetupInfo[setup_fields.tranDate] = row[createEntryConfig[entry_fields.jeTranDateColumn]];
			rowConfigSetupInfo[setup_fields.postingPeriod] = row[createEntryConfig[entry_fields.jePostingPeriodIdColumn]];
			rowConfigSetupInfo[setup_fields.journalMemo] = row[createEntryConfig[entry_fields.jeMemoTextColumn]];
			
			const journalLineDebitAccountIds = row[createEntryConfig[entry_fields.debitAccountIdListColumn]];
			const journalLineDebitAccountNames = row[createEntryConfig[entry_fields.debitAccountNameListColumn]];
			const journalLineDebitAmounts = row[createEntryConfig[entry_fields.debitAccountAmountListColumn]];
			const journalLineCreditAccountIds = row[createEntryConfig[entry_fields.creditAccountIdListColumn]];
			const journalLineCreditAccountNames = row[createEntryConfig[entry_fields.creditAccountNameListColumn]];
			const journalLineCreditAmounts = row[createEntryConfig[entry_fields.creditAccountAmountListColumn]];
			
			rowConfigSetupInfo[setup_fields.journalLineDebitAccountIds] = journalLineDebitAccountIds;
			rowConfigSetupInfo[setup_fields.journalLineDebitAccountNames] = journalLineDebitAccountNames;
			rowConfigSetupInfo[setup_fields.journalLineDebitAmounts] = journalLineDebitAmounts;
			
			rowConfigSetupInfo[setup_fields.journalLineCreditAccountIds] = journalLineCreditAccountIds;
			rowConfigSetupInfo[setup_fields.journalLineCreditAccountNames] = journalLineCreditAccountNames;
			rowConfigSetupInfo[setup_fields.journalLineCreditAmounts] = journalLineCreditAmounts;
			
			rowConfigSetupInfo[setup_fields.addReversalFlag] = row[createEntryConfig[entry_fields.addReversalFlagColumn]];
			rowConfigSetupInfo[setup_fields.reversalPostingPeriodId] = row[createEntryConfig[entry_fields.reversalPostingPeriodIdColumn]];
			rowConfigSetupInfo[setup_fields.entryPeriod] = createEntryConfig[entry_fields.entryPeriod];
			rowConfigSetupInfo[setup_fields.destination] = row[createEntryConfig[entry_fields.destinationColumn]];
			rowConfigSetupInfo[setup_fields.entryType] = row[createEntryConfig[entry_fields.entryTypeColumn]];
			
			rowConfigSetupInfo[setup_fields.reversalTranDate] = row[createEntryConfig[entry_fields.reversalTranDateColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.subsidiaryId] = row[createEntryConfig[entry_fields.jeSubsidiaryIdColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.subsidiaryName] = row[createEntryConfig[entry_fields.jeSubsidiaryNameColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.departmentId] = row[createEntryConfig[entry_fields.jeDepartmentIdColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.departmentName] = row[createEntryConfig[entry_fields.jeDepartmentNameColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.locationId] = row[createEntryConfig[entry_fields.jeLocationIdColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.locationName] = row[createEntryConfig[entry_fields.jeLocationNameColumn]] ?? "";
			
			rowConfigSetupInfo[setup_fields.docNum] = row[createEntryConfig[entry_fields.jeDocNumColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.tranId] = row[createEntryConfig[entry_fields.jeTranIdColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.currency] = row[createEntryConfig[entry_fields.jeCurrencyColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.lineEntity] = row[createEntryConfig[entry_fields.jeLineEntityColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.class] = row[createEntryConfig[entry_fields.jeClassColumn]] ?? "";
			rowConfigSetupInfo[setup_fields.reversalJournalMemo] = row[createEntryConfig[entry_fields.reversalJeMemoTextColumn]] ?? "";
			
			rowConfigSetupInfo[setup_fields.jeLineMemos] = row[createEntryConfig[entry_fields.jeLineMemoListColumn]]
			
			if (Object.keys(createEntryConfig).includes(entry_fields.reversalAsSeparateLine)) {
				rowConfigSetupInfo[setup_fields.reversalAsSeparateLine] = createEntryConfig[entry_fields.reversalAsSeparateLine]
			}
			
			rowConfigSetupInfo[setup_fields.journalLineDebitAccountNos] = "";
			rowConfigSetupInfo[setup_fields.journalLineCreditAccountNos] = "";
			
			const accountMappingConfig = createEntryConfig[entry_fields.accountMappingConfig];
			if (accountMappingConfig && accountMappingConfig[accountMappingConfig_fields.accountMappingNoColumnName]) {
				const accountMappingTableName = accountMappingConfig[accountMappingConfig_fields.accountMappingTableName];
				const accountMappingNameColumnName = accountMappingConfig[accountMappingConfig_fields.accountMappingNameColumnName];
				const accountMappingNoColumnName = accountMappingConfig[accountMappingConfig_fields.accountMappingNoColumnName];
				
				const accountMappingTable = tables[accountMappingTableName]?.data;
				const acctNoColIndex = accountMappingTable[0].indexOf(accountMappingNoColumnName);
				if (acctNoColIndex !== -1) {
					// assemble: 
					// journalLineDebitAccountNos
					// journalLineCreditAccountNos
					const acctNameColIndex = accountMappingTable[0].indexOf(accountMappingNameColumnName);
					
					const debitAccountNames = journalLineDebitAccountNames.split(";")
					const creditAccountNames = journalLineCreditAccountNames.split(";")
					
					const journalLineDebitAccountNos = [];
					const journalLineCreditAccountNos = [];
					
					debitAccountNames.forEach((acctName) => {
						// find the row with this name
						const matchingRow = accountMappingTable.find((row, index) => index > 0 && row[acctNameColIndex] === acctName); // Skip header row
						if (matchingRow) {
							journalLineDebitAccountNos.push(matchingRow[acctNoColIndex]); // Add account number to the array
						}
					})
					
					creditAccountNames.forEach((acctName) => {
						// find the row with this name
						const matchingRow = accountMappingTable.find((row, index) => index > 0 && row[acctNameColIndex] === acctName); // Skip header row
						if (matchingRow) {
							journalLineCreditAccountNos.push(matchingRow[acctNoColIndex]); // Add account number to the array
						}
					})
					
					rowConfigSetupInfo[setup_fields.journalLineDebitAccountNos] = journalLineDebitAccountNos.join(";");
					rowConfigSetupInfo[setup_fields.journalLineCreditAccountNos] = journalLineCreditAccountNos.join(";");
				} else {
					console.log("accountMappingNoColumnName not found in accountMappingTable")
				}
			}
			
			const newConfigValue = { entryUuid: entryUuid, rowConfigSetupInfo: rowConfigSetupInfo };
			const configUpdateArgs = generateDisplayConfigUpdateArgs(
				companyUuid, 
				newConfigValue, 
				config_options.create_entry, 
				row, 
				displayConfigUuid, 
				entryPeriod, 
				primaryKeyFieldList, 
				linkedTableForeignFieldMap, 
				statusTrackerConfig
			);
			argsArr = [...argsArr, ...configUpdateArgs];
		}
	})
	return argsArr;
}

const generateAutoSendEmailArgs = (rows, generationConfigs) => {
	const {
		displayConfigUuid,
		companyUuid,
		sendEmailConfig,
		statusTrackerConfig,
		entryPeriod,
		sourceTable,
		primaryKeyFieldList,
		linkedTableForeignFieldMap,
		displayConfigTableRows
	} = generationConfigs;
	
	const email_fields = sendEmailConfig_fields;
	const auto_perform_fields = autoPerformConfig_fields;
	const setup_fields = rowConfigSetupInfo_fields[config_options.send_email];
	
	let argsArr = [];
	
	rows.forEach(row => {
		console.log("checking row for autoSendEmail", row);
		const displayConfigTableRow = displayConfigTableRows
			?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap)
			.every(([field, value]) => row[field] === value));
		
		const hidden = displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.["optionValue"];
		const autoPerformFlag = row[sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
		const validStatus = sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
		
		if (!hidden && autoPerformFlag && validStatus) {
			console.log("row qualifies - generating sendEmailArgs");
			
			const rowConfigSetupInfo = {};
			
			rowConfigSetupInfo[setup_fields.uniqueInteractionId] = row[sendEmailConfig[email_fields.uniqueInteractionIdColumn]];
			rowConfigSetupInfo[setup_fields.recipientEmailAddress] = row[sendEmailConfig[email_fields.recipientEmailColumn]];
			rowConfigSetupInfo[setup_fields.subject] = row[sendEmailConfig[email_fields.emailSubjectColumn]];
			rowConfigSetupInfo[setup_fields.body] = row[sendEmailConfig[email_fields.emailBodyColumn]];
			rowConfigSetupInfo[setup_fields.statusToChangeToOnAiResponse] = sendEmailConfig[email_fields.statusToChangeToOnResponse];
			rowConfigSetupInfo[setup_fields.statusToChangeFromOnAiResponse] = sendEmailConfig[email_fields.statusToChangeFromOnResponse];
			rowConfigSetupInfo[setup_fields.statusToChangeToOnDone] = sendEmailConfig[email_fields.statusToChangeToOnDone];
			if (sendEmailConfig[email_fields.followUp]) {
				rowConfigSetupInfo[setup_fields.followUp] = sendEmailConfig[email_fields.followUp];
				rowConfigSetupInfo[setup_fields.emailFollowUpBody] = row[sendEmailConfig[email_fields.emailFollowUpBodyColumn]];
				rowConfigSetupInfo[setup_fields.followUpMaxCount] = sendEmailConfig[email_fields.followUpMaxCount];
				rowConfigSetupInfo[setup_fields.followUpWaitDays] = sendEmailConfig[email_fields.followUpWaitDays];
			}
			
			const newConfigValue = { rowConfigSetupInfo: rowConfigSetupInfo };
		
			// now generate update args...
			const configUpdateArgs = generateDisplayConfigUpdateArgs(
				companyUuid, 
				newConfigValue, 
				config_options.send_email, 
				row, 
				displayConfigUuid, 
				entryPeriod, 
				primaryKeyFieldList, 
				linkedTableForeignFieldMap, 
				statusTrackerConfig
			);
			argsArr = [...argsArr, ...configUpdateArgs];
		}
	})
	return argsArr;
}

const AutoCreateEntryButton = ({
	displayConfigUuid,
	companyUuid,
	createEntryConfig,
	statusTrackerConfig,
	disableActions,
	rows
}) => {
	const dispatch = useDispatch();
	const isUpdating = useSelector(state =>  dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.isUpdating)
	
	const tables = useSelector(state => state.dashboard.tables);
	
	const entryPeriod = useSelector(state => state.dashboard.entryPeriod);
	const sourceTable = useSelector(state => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.sourceTable);
	const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
	const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);
	const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
	/*
	const [confirm, setConfirm] = useState(false);
	
	// Handle confirmation timeout
	useEffect(() => {
		let timeoutId;
		if (confirm) {
			timeoutId = setTimeout(() => {
				setConfirm(false);
			}, 3000); // Reset after 3 seconds
		}
		return () => clearTimeout(timeoutId);
	}, [confirm]);
	*/
	const [confirm, setConfirm] = useState(false);
	const [updateInProgress, setUpdateInProgress] = useState(false);
	
	// Handle confirmation timeout
	useEffect(() => {
		let timeoutId;
		
		if (confirm && !updateInProgress) {
			timeoutId = setTimeout(() => {
				setConfirm(false);
			}, 3000); // Reset after 3 seconds
		} else {
			// Clear the timeout if confirm becomes false or updateInProgress becomes true
			timeoutId && clearTimeout(timeoutId);
		}
		
		return () => {
			timeoutId && clearTimeout(timeoutId);
		};
	}, [confirm, updateInProgress]);
	
	// Track update completion
	useEffect(() => {
		if (updateInProgress && !isUpdating) {
			setUpdateInProgress(false);
			setConfirm(false);
		}
	}, [isUpdating, updateInProgress]);
	
	const handleClick = () => {
		if (confirm) {
			// handle auto create entry
			const generationConfigs = {
				displayConfigUuid,
				companyUuid,
				createEntryConfig,
				statusTrackerConfig,
				entryPeriod,
				sourceTable,
				primaryKeyFieldList,
				linkedTableForeignFieldMap,
				displayConfigTableRows,
				tables
			}
			const autoCreateEntryArgs = generateAutoCreateEntryArgs(rows, generationConfigs);
			console.log("autoCreateEntryArgs", autoCreateEntryArgs);
			if (autoCreateEntryArgs?.length > 0) {
				setUpdateInProgress(true)// maybe?
				dispatch(updateConfig(displayConfigUuid, autoCreateEntryArgs));
			} else {
				console.log("no entries to auto create")
				setConfirm(false)
			}
		} else {
			setConfirm(true)
		}
	}
	
	return (
		<Button
			disableRipple
			sx={{ 
					width: "auto", 
					textTransform: 'none', 
					fontSize: "10px", 
					marginRight: "14px", 
					whiteSpace: "nowrap", 
					textAlign: "center", 
					paddingLeft: "12px", 
					paddingRight: "12px",
					minWidth: "fit-content"
			}}
			onClick={handleClick}
			disabled={isUpdating || disableActions}
			variant={confirm ? "contained" : "outlined"}
			size={"small"}
			startIcon={isUpdating && updateInProgress ? <CircularProgress size={"10px"}/> : null}
		>{confirm ? "CONFIRM AUTO CREATE ENTRIES" : "AUTO CREATE ENTRIES"}</Button>
	)
}

const AutoSendEmailButton = ({
	displayConfigUuid,
	companyUuid,
	sendEmailConfig,
	statusTrackerConfig,
	disableActions,
	rows
}) => {
	const dispatch = useDispatch();
	const isUpdating = useSelector(state =>  dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.isUpdating)
	
	const entryPeriod = useSelector(state => state.dashboard.entryPeriod);
	const sourceTable = useSelector(state => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.sourceTable);
	const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
	const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);
	const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
	/*
	const [confirm, setConfirm] = useState(false);
	
	useEffect(() => {
		let timeoutId;
		if (confirm) {
			timeoutId = setTimeout(() => {
				setConfirm(false);
			}, 3000); // Reset after 3 seconds
		}
		return () => clearTimeout(timeoutId);
	}, [confirm]);
	*/
	const [confirm, setConfirm] = useState(false);
	const [updateInProgress, setUpdateInProgress] = useState(false);
	
	// Handle confirmation timeout
	useEffect(() => {
		let timeoutId;
		
		if (confirm && !updateInProgress) {
			timeoutId = setTimeout(() => {
				setConfirm(false);
			}, 3000); // Reset after 3 seconds
		} else {
			// Clear the timeout if confirm becomes false or updateInProgress becomes true
			timeoutId && clearTimeout(timeoutId);
		}
		
		return () => {
			timeoutId && clearTimeout(timeoutId);
		};
	}, [confirm, updateInProgress]);
	
	// Track update completion
	useEffect(() => {
		if (updateInProgress && !isUpdating) {
			setUpdateInProgress(false);
			setConfirm(false);
		}
	}, [isUpdating, updateInProgress]);
	
	const handleClick = () => {
		if (confirm) {
			// handle auto send email
			const generationConfigs = {
				displayConfigUuid,
				companyUuid,
				sendEmailConfig,
				statusTrackerConfig,
				entryPeriod,
				sourceTable,
				primaryKeyFieldList,
				linkedTableForeignFieldMap,
				displayConfigTableRows
			}
			const autoSendEmailArgs = generateAutoSendEmailArgs(rows, generationConfigs);
			console.log("autoSendEmailArgs", autoSendEmailArgs)
			if (autoSendEmailArgs?.length > 0) {
				setUpdateInProgress(true)// maybe?
				dispatch(updateConfig(displayConfigUuid, autoSendEmailArgs));
			} else {
				console.log("no emails to auto send")
				setConfirm(false)
			}
		} else {
			setConfirm(true)
		}
	}
	return (
		<Button
			disableRipple
			sx={{ 
					width: "auto", 
					textTransform: 'none', 
					fontSize: "10px", 
					marginRight: "14px", 
					whiteSpace: "nowrap", 
					textAlign: "center", 
					paddingLeft: "12px", 
					paddingRight: "12px",
					minWidth: "fit-content"
			}}
			onClick={handleClick}
			disabled={isUpdating || disableActions}
			variant={confirm ? "contained" : "outlined"}
			size={"small"}
			startIcon={isUpdating && updateInProgress ? <CircularProgress size={"10px"}/> : null}
		>{confirm ? "CONFIRM AUTO SEND EMAILS" : "AUTO SEND EMAILS"}</Button>
	)
}

const ErrorChip = () => {
	return (
		<Chip
			label=<span><strong>ERROR:</strong> autoPerformConfig found in sendEmailConfig and createEntryConfig</span>
			color="error"
			sx={{
				height: "24px", // Small button default height
				fontSize: "10px",
				marginRight: "14px",
				padding: "0px 12px",
				"& .MuiChip-label": {
					padding: "0",
					whiteSpace: "nowrap"
				}
			}}
		/>
	)
}

export const AutoDisplayConfigActionButtons = ({
	displayConfigUuid,
	createEntryConfig,
	sendEmailConfig,
	statusTrackerConfig,
	disableActions,
	rows
}) => {
	const companyUuid = useSelector(state => state.dashboard.companyUuid);
	const isReport = useSelector(state => state.dashboard.isReport);
	
	const showButtons = useMemo(() => {
		const autoPerformEntries = createEntryConfig?.[createEntryConfig_fields.autoPerformConfig];
		const autoPerformEmails = sendEmailConfig?.[sendEmailConfig_fields.autoPerformConfig];
		// const autoCreateEntry = !!(companyUuid && displayConfigUuid && createEntryConfig && autoPerformEntries && statusTrackerConfig)
		// const autoSendEmail = !!(companyUuid && displayConfigUuid && sendEmailConfig && autoPerformEmails && statusTrackerConfig)
		let autoCreateEntry = false;
		let autoSendEmail = false;
		
		if (disableActions && !isReport) {
			// 'preview' dashboard mode (no displayConfigUuid or companyUuid)
			autoCreateEntry = !!(createEntryConfig && autoPerformEntries && statusTrackerConfig);
			autoSendEmail = !!(sendEmailConfig && autoPerformEmails && statusTrackerConfig);
			
			return { autoCreateEntry: autoCreateEntry, autoSendEmail: autoSendEmail }
		}
		
		autoCreateEntry = !!(companyUuid && displayConfigUuid && createEntryConfig && autoPerformEntries && statusTrackerConfig)
		autoSendEmail = !!(companyUuid && displayConfigUuid && sendEmailConfig && autoPerformEmails && statusTrackerConfig)
		
		return { autoCreateEntry: autoCreateEntry, autoSendEmail: autoSendEmail }
	}, [companyUuid, isReport, displayConfigUuid, createEntryConfig, sendEmailConfig, statusTrackerConfig, disableActions])
	// console.log("AutoDisplayConfigActionButtons - showButtons = ", showButtons)
	return (showButtons?.autoCreateEntry || showButtons?.autoSendEmail) ? (
		<div style={{ display: "flex", alignItems: "center" }}>
			{showButtons?.autoSendEmail && !showButtons?.autoCreateEntry &&
				<AutoSendEmailButton
					displayConfigUuid={displayConfigUuid}
					companyUuid={companyUuid}
					sendEmailConfig={sendEmailConfig}
					statusTrackerConfig={statusTrackerConfig}
					disableActions={disableActions}
					rows={rows}
				/>
			}
			{showButtons?.autoCreateEntry && !showButtons?.autoSendEmail &&
				<AutoCreateEntryButton
					displayConfigUuid={displayConfigUuid}
					companyUuid={companyUuid}
					createEntryConfig={createEntryConfig}
					statusTrackerConfig={statusTrackerConfig}
					disableActions={disableActions}
					rows={rows}
				/>
			}
			{showButtons?.autoSendEmail && showButtons?.autoCreateEntry &&
				<ErrorChip/>
			}
		</div>	
	) : null;
}

export default AutoDisplayConfigActionButtons;