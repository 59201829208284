/* 
needs 2 views
// by module
// by app

by module or app - TOP LEVEL:
requests:
	- get multiApp configs instances by company

by module:
module dropdown - copy alertConfigsSysAdmin
selects company...
requests:
	- subscribed modules
display:
	- subscribed module dropdown

selects module...
requests: 
	- get multiApp configs by module


display:
	- multiApp config if used by module and instance by company


by app:
app dropdown - copy alertConfigsSysAdmin
selects company...
requests:
	- subscribed apps
display:
	- subscribed app dropdown

selects app...
requests:
	- get multiApp configs used by app

display: 
	- multiApp config if used by app and instance by company


TODO: how to update??
*/
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
// import useAppsSubscriptions from "../hooks/useAppsSubscriptions";
import { useMultiAppConfigsInstances } from "./hooks/useMultiAppConfigsInstances";
import { useToolbar } from "./hooks/useToolbar";
import DirectRequest from "../../../API/requests/DirectRequest";
import Toolbar from "./components/Toolbar";
import MultiAppConfigs from "./components/MultiAppConfigs";

export const CompanyMultiAppConfigs = ({selectedCompany}) => {
	const {
		viewOption,
		handleViewSelection,
		selectedAppUuid,
		handleAppSelection,
		selectedModuleUuid,
		handleModuleSelection
	} = useToolbar(selectedCompany)
	
	return (
		<Grid container spacing={2}>
			{/* Toolbar: view toggle */}
			<Toolbar
				viewOption={viewOption}
				handleViewSelection={handleViewSelection}
				selectedAppUuid={selectedAppUuid}
				handleAppSelection={handleAppSelection}
				selectedModuleUuid={selectedModuleUuid}
				handleModuleSelection={handleModuleSelection}
				selectedCompany={selectedCompany}
			/>
			{/* 
				2 tools?
				by app = get multiAppConfigs by app
				by module = get multiAppConfigs by module
			*/}
			<MultiAppConfigs
				viewOption={viewOption}
				selectedAppUuid={selectedAppUuid}
				selectedModuleUuid={selectedModuleUuid}
				selectedCompany={selectedCompany}
			/>
		</Grid>
	)
}

export const SysAdminMultiAppConfigs = ({selectedCompany}) => {
	return (
		<div key={`${selectedCompany?.uuid || 'no-company'}-multiAppConfigs-container`}>
			<CompanyMultiAppConfigs
				selectedCompany={selectedCompany}
			/>
		</div>
	)
}

export default SysAdminMultiAppConfigs;