// needs to show list of all modules
// each module needs subscribe/unsubscribe (!disabled if selectedCompany.uuid)
// each module needs edit
// each module needs delete
// top of page needs add module
import DirectRequest from "../../../API/requests/DirectRequest";
import { useModules } from "./hooks/useModules";
import { Grid, Typography } from "@mui/material";
import Module from "./components/Module";
import ModuleCreate from "./components/ModuleCreate";

const dummyModules = [
  {
    uuid: "mmmm_0001QWERTYUIOPSHJSHT",
    companyUuid: "12345",
    externalUuid: "0001-12345",
    name: "Accruals",
    createdBy: "system_async",

    fullName: "Accruals AI",
    description:
      "Automate your accruals process - perhaps some other text - except very long",

    // The apps that should have visual tabs;
    // there may be apps run in the dependency graph that are not displayed and thus not included in this list.
    displayApps: [],
    displayTitles: [],

    // a map describing the dependency graph of the module.
    // There should always be one or more nodes that are "root" nodes that are the first apps to be added to the queue
    // When all parent nodes' jobs have finished, then the child node should be added to the job queue.
    // Todo: Junaid decide what directed graph implementation to use
    appDependencyGraph: [],

    tags: ["module_accruals", "some other tag", "another one"],

    visibleToCompanyUuids: [],
    hideFromCompanyUuids: [],
  },
];

export const SysAdminModules = ({ 
  selectedCompany, 
  companies 
}) => {
  const {
    modulesArgs,
    modules,
    subscriptionsArgs,
    subscriptions,
    handleModules,
    handleSubscriptions,
    handleModulesRefresh,
    handleSubscriptionsRefresh,
    selectedModuleUuid,
    handleModuleSelection,
  } = useModules(selectedCompany);
  console.log("SysAdminModules - companies", companies);
  console.log("SysAdminModules - subscriptions", subscriptions);
  console.log("SysAdminModules - subscriptionsArgs", subscriptionsArgs);
  return (
    <>
      <DirectRequest requestArgs={modulesArgs} afterProcess={handleModules} />
      <DirectRequest
        requestArgs={subscriptionsArgs}
        afterProcess={handleSubscriptions}
      />
      <Grid container spacing={1}>
        {/* ModuleCreateButton here */}
        <ModuleCreate
          handleModulesRefresh={handleModulesRefresh}
          companies={companies}
        />
        {
          modules.map((module) => (
            <Module
              key={module.uuid + "_module"}
              //module, handleModulesRefresh, handleSubscriptionsRefresh
              module={module}
              handleModulesRefresh={handleModulesRefresh}
              handleSubscriptionsRefresh={handleSubscriptionsRefresh}
              subscriptions={subscriptions}
              companies={companies}
              selectedCompany={selectedCompany}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default SysAdminModules;
