import {useDispatch} from "react-redux";
import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import * as Constants from "../../Constants";
import {setSelectedIntegrations} from "../../store/selectedIntegrationsSlice";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";
import DirectRequest from "../../API/requests/DirectRequest";

const AddIntegrationCard = ({ selectedIntegrations }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [selectIntegration, setSelectIntegration] = useState(false);
  const [selectedIntegrationArgs, setSelectedIntegrationArgs] = useState(null);

  const handleSelection = (integration) => {
    console.log(integration)
    if (Constants.USE_DUMMY_DATA) {
      dispatch(setSelectedIntegrations([...selectedIntegrations, integration]));
    } else {
      const tempSelectedIntegrationsArgs = {
        method: "POST",
        url: Constants.SERVER_POST_SELECTED_CONNECTORS_URL,
        body: JSON.stringify({ selectedConnectorNames: [...selectedIntegrations, integration]})
      }
      setSelectedIntegrationArgs(tempSelectedIntegrationsArgs)
    }
  }
  const cancelSelection = (integration) => {
    setSelectIntegration(false)
  }
  const handleSuccessfulSelection = (res) => {
    if (res) {
      dispatch(setSelectedIntegrations(res.selectedConnectorNames))
    }
  }

  useEffect(() => {
    // when selectedIntegrations updates that means POST and afterProcess succeeded
    setSelectIntegration(false)
  }, [selectedIntegrations])
  
  return (
    <>
      <DirectRequest
        requestArgs={selectedIntegrationArgs}
        afterProcess={handleSuccessfulSelection}
        handleError={(err) => console.log("error setting selected integrations", err)}
        handleCatchError={(err) => console.log("catch error setting selected integrations", err)}
      />
      <Grid item xs={12} md={6} lg={4} sx={{ height: "30vh", minHeight: "250px" }}>
        <Card
          sx={{
            position: 'relative',
            borderRadius: "10px",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
            border: "1px solid " + theme.palette.border.light,
            height: "100%"
          }}
        >
          {selectIntegration && (
            <IconButton
              onClick={cancelSelection}
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <CardContent sx={{ height: "100%" }}>
            {selectIntegration && (
              <Grid container spacing={1} sx={{ height: "100%" }}>
                {Constants.supported_integrations.filter((integration) => !selectedIntegrations.includes(integration.name)).map((integration) => (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    item
                    xs={6}
                    key={uuidv4()}
                  >
                    <Typography
                      onClick={() => handleSelection(integration.name)}
                      sx={{
                        cursor: "pointer",
                        '&:hover': {
                          color: theme.palette.primary.main
                        }
                      }}
                    >
                      <strong>{integration.displayName}</strong>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
            {!selectIntegration && (
              <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }}>
                <Grid item>
                  <IconButton
                    aria-label='select-integration'
                    onClick={() => setSelectIntegration(true)}
                    sx={{
                      '& svg': {
                        fontSize: "4rem"
                      }
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default AddIntegrationCard;