import { useModuleActions } from "../hooks/useModuleActions";
import { IconButton, Modal, Box, Grid, Typography } from "@mui/material";
import DirectRequest from "../../../../API/requests/DirectRequest";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  form_fields,
  string_fields,
  disabled_fields,
  existingModuleFormFields,
} from "../hooks/useModuleForm";
import ModalWrapper from "../../../Modal/ModalWrapper";
import BasicInput from "./Inputs/BasicInput";
import AppDependencyGraphInput from "./Inputs/AppDependencyGraphInput";
import DisplayAppsInput from "./Inputs/DisplayAppsInput";
import DisplayTitlesInput from "./Inputs/DisplayTitlesInput";
import TagsInput from "./Inputs/TagsInput";
import HideFromInput from "./Inputs/HideFromInput";
import VisibleToInput from "./Inputs/VisibleToInput";
import UpdateButton from "./Buttons/UpdateButton";
import DeleteButton from "./Buttons/DeleteButton";

const ActionsForm = ({
  formState,
  isValid,
  showForm,
  toggleShowForm,
  handleFormUpdate,
  handleModuleUpdate,
  handleModuleDelete,
  companies,
}) => {
  console.log("ActionsForm - formState", formState);
  console.log("companies", companies);
  return (
    <ModalWrapper open={showForm} onClose={toggleShowForm} width={1200}>
      {/* new form here */}
      <Grid container sx={{ width: "100%" }} spacing={2}>
        {[
          form_fields.uuid,
          form_fields.name,
          form_fields.fullName,
          form_fields.description,
        ].map((field) => {
          return (
            <Grid item xs={6} key={field + "_item"}>
              <BasicInput
                field={field}
                formState={formState}
                handleFormUpdate={handleFormUpdate}
              />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <AppDependencyGraphInput
            field={form_fields.appDependencyGraph}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <DisplayAppsInput
            field={form_fields.displayApps}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <DisplayTitlesInput
            field={form_fields.displayTitles}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <TagsInput
            field={form_fields.tags}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={4}>
          <HideFromInput
            field={form_fields.hideFromCompanyUuids}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
            companies={companies}
          />
        </Grid>
        <Grid item xs={4}>
          <VisibleToInput
            field={form_fields.visibleToCompanyUuids}
            formState={formState}
            handleFormUpdate={handleFormUpdate}
            companies={companies}
          />
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent="space-between">
          <Grid item />  {/* Left spacer */}
          <Grid item>
            <UpdateButton
              isValid={isValid}
              handleModuleUpdate={handleModuleUpdate}
            />
          </Grid>
          <Grid item>
            <DeleteButton handleModuleDelete={handleModuleDelete} />
          </Grid>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export const ModuleActions = ({ module, companies, handleModulesRefresh }) => {
  const {
    moduleActionArgs,
    formState,
    isValid,
    showForm,
    toggleShowForm,
    handleFormUpdate,
    handleModuleUpdate,
    handleModuleDelete,
    handleModuleActionSuccess,
  } = useModuleActions(module, handleModulesRefresh);
  console.log(
    "ModuleActions - formState.displayApps",
    formState[form_fields.displayApps]
  );
  return (
    <>
      <DirectRequest
        requestArgs={moduleActionArgs}
        afterProcess={handleModuleActionSuccess}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={toggleShowForm}>
          <MoreHorizIcon />
        </IconButton>
      </div>
      <ActionsForm
        formState={formState}
        isValid={isValid}
        showForm={showForm}
        toggleShowForm={toggleShowForm}
        handleFormUpdate={handleFormUpdate}
        handleModuleUpdate={handleModuleUpdate}
        handleModuleDelete={handleModuleDelete}
        companies={companies}
      />
    </>
  );
};

export default ModuleActions;
