import { Grid, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import * as Constants from "../../../../Constants";
import {view_options} from "../hooks/useToolbar";
import {
	useMultiAppConfigs,
	useMultiAppConfigsByModule,
	useMultiAppConfigsByApp
} from "../hooks/useMultiAppConfigs";
import { useMultiAppConfigsInstances } from "../hooks/useMultiAppConfigsInstances";
import { 
	useMultiAppConfigsForm, 
	config_fields, 
	config_types, 
	validateField, 
	defaultDropdownValueList,
	config_defaults 
} from "../hooks/useMultiAppConfigsForm";
import {
	useCreateMultiAppConfig,
	create_config_fields
} from "../hooks/useCreateMultiAppConfig";
import DirectRequest from "../../../../API/requests/DirectRequest";
import BooleanInput from "../../components/configInputs/BooleanInput";
import DateInput from "../../components/configInputs/DateInput";
import DropdownInput from "../../components/configInputs/DropdownInput";
import FloatInput from "../../components/configInputs/FloatInput";
import IntegerInput from "../../components/configInputs/IntegerInput";
import KeyValueInput from "../../components/configInputs/KeyValueInput";
import ListInput from "../../components/configInputs/ListInput";
import TextInput from "../../components/configInputs/TextInput";
import { useState, useEffect, useMemo } from "react";
import ModalWrapper from "../../../Modal/ModalWrapper";
import EditIcon from '@mui/icons-material/Edit';

const config_modes = {
	create: "create",
	edit: "edit"
}

const UpdateButton = ({isValid, handleUpdate, disabled}) => {
	return (
		<Button
			disableRipple
			sx={{ width: "100%", textTransform: 'none' }}
			onClick={handleUpdate}
			disabled={!isValid || disabled}
			variant={"contained"}
			size={"small"}
			startIcon={disabled ? <CircularProgress size={"1rem"}/> : null}
		>UPDATE</Button>
	)
}

const ResetFormButton = ({handleFormReset, disabled}) => {
	return (
		<Button
			disableRipple
			sx={{ width: "100%" }}
			onClick={handleFormReset}
			disabled={disabled}
			variant={"outlined"}
			size={"small"}
		>CLEAR</Button>
	)
}

const ResetConfigsInstancesButton = ({isValidReset, handleReset, disabled}) => {
	const [confirm, setConfirm] = useState(false);
	
	useEffect(() => {
		let timeoutId;
		if (confirm) {
			timeoutId = setTimeout(() => {
				setConfirm(false);
			}, 3000); // Reset after 3 seconds
		}
		return () => clearTimeout(timeoutId);
	}, [confirm]);
	
	const handleClick = () => {
		if (confirm) {
			handleReset()
		} else {
			setConfirm(true)
		}
	}
	return (
		<Button
			disableRipple
			sx={{ width: "100%", textTransform: 'none' }}
			onClick={handleClick}
			disabled={!isValidReset || disabled}
			variant={"outlined"}
			color={"error"}
			size={"small"}
			startIcon={disabled ? <CircularProgress size={"1rem"}/> : null}
		>{confirm ? "CONFIRM RESET" : "RESET"}</Button>
	)
}

const CreateButton = ({disabled, refreshMultiAppConfigs}) => {
	const {
		open,
		toggleOpen,
		config,
		handleChange,
		isValidCreate,
		createConfigArgs,
		createConfig,
		handleSuccess
	} = useCreateMultiAppConfig(refreshMultiAppConfigs)
	return (
		<>
			<DirectRequest
				requestArgs={createConfigArgs}
				afterProcess={handleSuccess}
			/>
			<Button
				disableRipple
				sx={{ width: "100%" }}
				onClick={toggleOpen}
				disabled={disabled}
				variant={"contained"}
				size={"small"}
			>CREATE</Button>
			<ModalWrapper open={open} onClose={toggleOpen} width={600}>
				<Grid container sx={{ width: "100%" }} spacing={2}>
					<Grid item xs={12}>
						<strong>Create multiAppConfig</strong>
					</Grid>
					<Grid item xs={12} container spacing={2}>
						<Config
							config={config}
							handleChange={handleChange}
							disabled={disabled}
							viewOption={view_options.all}
							mode={config_modes.create}
						/>
					</Grid>
					<Grid item xs={12} container spacing={1} justifyContent={"center"}>
						<Button
							onClick={createConfig}
							disabled={!isValidCreate}
							size={"small"}
							variant={"contained"}
						>SAVE</Button>
					</Grid>
				</Grid>
			</ModalWrapper>
		</>
	)
}

const getLabel = (val, curVal, configType, label) => {
	let returnedLabel;
	if (configType === config_types.BOOLEAN) {
		returnedLabel = val;
	} else {
		returnedLabel = label
	}
	if (val === curVal) {
		return returnedLabel
	} else {
		return (<span>{returnedLabel} - <i>edited</i></span>)
	}
}

const getNoteLabel = (val, curVal) => {
	const label = "Note"
	if (val === curVal) {
		return label
	} else {
		return (<span>{label} - <i>edited</i></span>)
	}
}

const DisplayNameInput = ({config, handleChange, disabled, mode}) => {
	const configUuid = config[config_fields.uuid];
	const configType = config_types.TEXT;
	const curVal = mode === config_modes.create 
		? config[config_fields.displayName] 
		: config[config_fields.curDisplayName];
	const val = config[config_fields.displayName];
	const updateForm = (newVal) => {
		const config_field = config_fields.displayName;
		handleChange(newVal, configUuid, config_field);
	}
	const label = getLabel(val, curVal, configType, config_fields.displayName);
	const error = !val;
	const errorMessage = error ? "displayName cannot be empty" : null;
	return (
		<TextInput
			value={val}
			label={label}
			handleChange={updateForm}
			disabled={disabled}
			error={error}
			errorMessage={errorMessage}
		/>
	)
}

const ConfigNameInput = ({config, handleChange, disabled, mode}) => {
	const configUuid = config[config_fields.uuid];
	const configType = config_types.TEXT;
	const curVal = config[create_config_fields.configName];
	const val = config[create_config_fields.configName];
	const updateForm = (newVal) => {
		const config_field = create_config_fields.configName;
		handleChange(newVal, configUuid, config_field);
	}
	const label = getLabel(val, curVal, configType, create_config_fields.configName);
	const error = !val;
	const errorMessage = error ? "configName cannot be empty" : null;
	return (
		<TextInput
			value={val}
			label={label}
			handleChange={updateForm}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled || mode === config_modes.edit}
		/>
	)
}

const MultiAppConfigTypeInput = ({config, handleChange, disabled, mode}) => {
	const [open, setOpen] = useState(false);
	const toggleOpen = () => {
		setOpen(prevOpen => !prevOpen);
	}
	const [tempNewType, setTempNewType] = useState(null);
	
	const configUuid = config[config_fields.uuid];
	const configType = config_types.TEXT;
	const curVal = mode === config_modes.create 
		? config[config_fields.multiAppConfigType] 
		: config[config_fields.curMultiAppConfigType];
	const val = config[config_fields.multiAppConfigType];
	
	const handleTypeSelection = (newVal) => {
		if (newVal === val) {
			// do nothing
		} else if (newVal === config_types.DROPDOWN) {
			setTempNewType(newVal);
			toggleOpen();
		} else {
			const config_field = config_fields.val;
			const additionalParameters = {
				[config_fields.multiAppConfigType]: newVal
			}
			handleChange(config_defaults[newVal], configUuid, config_field, additionalParameters)
		}
	}
	
	const handleDropdownValueList = (newDropdownValueList) => {
		const config_field = config_fields.val;
		const additionalParameters = {
			[config_fields.multiAppConfigType]: tempNewType,
			[config_fields.dropdownValueList]: newDropdownValueList
		}
		const newVal = newDropdownValueList[0]
		handleChange(newVal, configUuid, config_field, additionalParameters);
	}
	
	const label = getLabel(val, curVal, configType, "multiAppConfigType");
	const options = Object.values(config_types);
	return (
		<>
			<DropdownInput
				value={val}
				label={label}
				handleChange={handleTypeSelection}
				options={options}
				disabled={disabled}
			/>
			<EditDropdownValueListModal
				open={open}
				config={config}
				handleSubmit={handleDropdownValueList}
				toggleOpen={toggleOpen}
			/>
		</>
	)
}

const EditDropdownValueListModal = ({open, config, handleSubmit, toggleOpen}) => {
	const [tempDropdownValueList, setTempDropdownValueList] = useState(
		// config.curMultiAppConfigType === DROPDOWN -> curDropdownValueList
		// else: -> substitute default
		config[config_fields.curMultiAppConfigType] === config_types.DROPDOWN 
			? JSON.stringify(config[config_fields.curDropdownValueList], null, 2)
			: JSON.stringify(defaultDropdownValueList, null, 2)
	)
	
	const updateTempValue = (newVal) => {
		setTempDropdownValueList(newVal);
	}
	
	const submitTempValue = () => {
		handleSubmit(JSON.parse(tempDropdownValueList));
		toggleOpen();
	}
	
	const label = "dropdownValueList";
	
	let error = false;
	let errorMessage = null;
	try {
			const parsed = JSON.parse(tempDropdownValueList);
			if (!Array.isArray(parsed)) {
					error = true;
					errorMessage = "must be a valid array";
			}
			if (Array.isArray(parsed) && parsed.length < 1) {
				error = true;
				errorMessage = "must have at least 1 value"
			}
			if (parsed.some(el => typeof el !== "string")) {
				error = true;
				errorMessage = "all values must be strings"
			}
	} catch (err) {
			error = true;
			errorMessage = "must be valid json";
	}
	
	return (
		<ModalWrapper open={open} onClose={toggleOpen} width={600}>
			<Grid container sx={{ width: "100%" }} spacing={2}>
				<Grid item xs={12}>
					<strong>{config[config_fields.displayName]}</strong>
				</Grid>
				<Grid item xs={12}>
					<ListInput
						value={tempDropdownValueList}
						label={label}
						handleChange={updateTempValue}
						error={error}
						errorMessage={errorMessage}
					/>
				</Grid>
				<Grid item xs={12} container justifyContent={"center"} alignItems={"center"}>
					<Button
						onClick={submitTempValue}
						disabled={error}
						variant={"contained"}
						size={"small"}
					>Submit</Button>
				</Grid>
			</Grid>
		</ModalWrapper>
	)
}

const EditDropdownValueListButton = ({config, handleChange, disabled}) => {
	const [open, setOpen] = useState(false);
	const toggleOpen = () => {
		setOpen(prevOpen => !prevOpen)
	}
	
	const handleSubmit = (newValueList) => {
		const configUuid = config[config_fields.uuid];
		const config_field = config_fields.val;
		const newVal = newValueList[0];
		const additionalValues = {
			[config_fields.dropdownValueList]: newValueList
		}
		handleChange(newVal, configUuid, config_field, additionalValues);
	}
	return (
		<>
			<Tooltip title={"Edit dropdownValueList"} followCursor>
				<IconButton sx={{ padding: "4px" }} onClick={toggleOpen} disabled={disabled}>
					<EditIcon/>
				</IconButton>
			</Tooltip>
			<EditDropdownValueListModal
				open={open}
				config={config}
				handleSubmit={handleSubmit}
				toggleOpen={toggleOpen}
			/>
		</>
	)
}

const ValueInput = ({config, handleChange, disabled, viewOption, mode}) => {
	const configUuid = config[config_fields.uuid];
	const configType = config[config_fields.multiAppConfigType];
	const curVal = mode === config_modes.create 
		? config[config_fields.val] 
		: config[config_fields.curVal];
	const val = config[config_fields.val];
	const updateForm = (newVal) => {
		const config_field = config_fields.val
		handleChange(newVal, configUuid, config_field)
	}
	const label = getLabel(val, curVal, configType, viewOption === view_options.all ? "Default value" : "Value");
	const error = !validateField(config[config_fields.val], configType);
	const errorMessage = error ? "type error" : null;
	const options = config[config_fields.dropdownValueList];
	return configType === config_types.BOOLEAN ? (
		<BooleanInput
			value={val}
			label={label}
			handleChange={updateForm}
			disabled={disabled}
		/>
	) : configType === config_types.DATE ? (
		<DateInput
			value={val}
			label={label}
			handleChange={updateForm}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled}
		/>
	) : configType === config_types.DROPDOWN ? (
		<DropdownInput
			value={val}
			label={label}
			handleChange={updateForm}
			options={options}
			disabled={disabled}
		/>
	) : configType === config_types.FLOAT ? (
		<FloatInput
			value={val}
			label={label}
			handleChange={updateForm}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled}
		/>
	) : configType === config_types.INTEGER ? (
		<IntegerInput
			value={val}
			label={label}
			handleChange={updateForm}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled}
		/>
	) : configType === config_types.KEY_VALUE ? (
		<KeyValueInput
			value={val}
			label={label}
			handleChange={updateForm}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled}
		/>
	) : configType === config_types.LIST ? (
		<ListInput
			value={val}
			label={label}
			handleChange={updateForm}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled}
		/>
	) : (
		<TextInput
			value={val}
			label={label}
			handleChange={updateForm}
			disabled={disabled}
		/>
	)
}

const NoteInput = ({config, handleChange, disabled}) => {
	const configUuid = config[config_fields.uuid];
	const configType = config_types.TEXT;
	const val = config[config_fields.displayNote];
	const curVal = config[config_fields.curDisplayNote];
	const updateForm = (newVal) => {
		const config_field = config_fields.displayNote
		handleChange(newVal, configUuid, config_field)
	}
	const label = getLabel(val, curVal, configType, "Note")
	return (
		<TextInput
			value={val}
			label={label}
			handleChange={updateForm}
			disabled={disabled}
		/>
	)
}

const source_options = Constants.supported_integrations.map((integration) => integration.name).concat(["generic"]);

const ConfigSourceInput = ({config, handleChange, disabled, mode}) => {
	const configUuid = config[config_fields.uuid]
	const val = config[config_fields.configSource]
	const curVal = mode === config_modes.create 
		? config[config_fields.configSource] 
		: config[config_fields.curConfigSource]
	const configType = config_types.TEXT;
	
	const rawLabel = getLabel(val, curVal, configType, config_fields.configSource)
	
	// Transform the label to add color if no value
	const label = <span style={{ color: !val ? "#d32f2f" : undefined }}>{rawLabel}<i>{!val ? " required" : ""}</i></span>
	
	// const label = getLabel(val, curVal, configType, config_fields.configSource)
	const updateForm = (newVal) => {
		const config_field = config_fields.configSource
		handleChange(newVal, configUuid, config_field)
	}
	return (
		<DropdownInput
			value={val}
			label={label}
			handleChange={updateForm}
			options={source_options}
			disabled={mode === config_modes.edit || disabled}
		/>
	)
}

const DeleteButton = ({config, disabled, handleDelete}) => {
	const [confirm, setConfirm] = useState(false);
	
	useEffect(() => {
		let timeoutId;
		if (confirm) {
			timeoutId = setTimeout(() => {
				setConfirm(false);
			}, 3000); // Reset after 3 seconds
		}
		return () => clearTimeout(timeoutId);
	}, [confirm]);
	
	const handleClick = () => {
		if (confirm) {
			handleDelete(config[config_fields.uuid])
		} else {
			setConfirm(true)
		}
	}
	return (
		<Button
			disableRipple
			sx={{ 
					width: "100%", 
					textTransform: 'none',
					borderColor: confirm ? undefined : 'rgba(0, 0, 0, 0.23)', // Default MUI input border color
					'&.MuiButton-outlined': {
							color: confirm ? undefined : 'rgba(0, 0, 0, 0.23)'
					}
			}}
			onClick={handleClick}
			disabled={disabled}
			variant={"outlined"}
			color={confirm ? "error" : "inherit"}
			size={"small"}
		>{confirm ? "CONFIRM DELETE" : "DELETE"}</Button>
	)
}

const Config = ({config, handleChange, disabled, viewOption, mode, handleDelete}) => {
	return (
		<>
			<Grid item xs={12}>
				{viewOption === view_options.all &&
					<DisplayNameInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
						mode={mode}
					/>
				}
				{viewOption !== view_options.all &&
					<>
						<strong>{config[config_fields.displayName]}</strong>
						<div style={{ marginTop: '4px' }}>{config[config_fields.configName]}</div>
					</>
				}
			</Grid>
			{viewOption === view_options.all &&
				<Grid item xs={12}>
					<ConfigNameInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
						mode={mode}
					/>
				</Grid>
			}
			{viewOption === view_options.all && config[config_fields.multiAppConfigType] === config_types.DROPDOWN &&
				<Grid item xs={12} container spacing={1} alignItems={"center"}>
					<Grid item xs={11}>
						<ValueInput
							config={config}
							handleChange={handleChange}
							disabled={disabled}
							viewOption={viewOption}
							mode={mode}
						/>
					</Grid>
					<Grid item xs={1}>
						<EditDropdownValueListButton
							config={config}
							handleChange={handleChange}
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			}
			{viewOption === view_options.all && config[config_fields.multiAppConfigType] !== config_types.DROPDOWN &&
				<Grid item xs={12}>
					<ValueInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
						viewOption={viewOption}
						mode={mode}
					/>
				</Grid>
			}
			{viewOption === view_options.all &&
				<Grid item xs={12}>
					<MultiAppConfigTypeInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
						mode={mode}
					/>
				</Grid>
			}
			{viewOption === view_options.all &&
				<Grid item xs={12}>
					<ConfigSourceInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
						mode={mode}
					/>
				</Grid>
			}
			{viewOption === view_options.all && mode !== config_modes.create &&
				<Grid item xs={12}>
					<DeleteButton
						config={config}
						handleDelete={handleDelete}
						disabled={disabled}
					/>
				</Grid>
			}
			{viewOption !== view_options.all &&
				<Grid item xs={12}>
					<ValueInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
						viewOption={viewOption}
						mode={mode}
					/>
				</Grid>
			}
			{viewOption !== view_options.all && 
				<Grid item xs={12}>
					<NoteInput
						config={config}
						handleChange={handleChange}
						disabled={disabled}
					/>
				</Grid>
			}
			<Grid item xs={12}>
				{/* vertical spacing */}
			</Grid>
		</>
	)
}
/* <strong>{config[config_fields.displayName]}</strong> */

const ConfigsUpdateRequest = ({updateArgs, handleUpdateSuccess}) => {
	return updateArgs ? (
		<DirectRequest requestArgs={updateArgs[0]} afterProcess={handleUpdateSuccess}/>
	) : null;
}

const ConfigsForm = ({
	selectedCompany,
	multiAppConfigs,
	refreshMultiAppConfigs,
	viewOption
}) => {
	const {
		multiAppConfigsInstancesArgs,
		multiAppConfigsInstances,
		handleMultiAppConfigsInstances,
		refreshMultiAppConfigsInstances
	} = useMultiAppConfigsInstances(selectedCompany); 
	
	const {
		configs,
		handleChange,
		handleFormReset,
		updateArgs,
		handleUpdate,
		handleDelete,
		handleReset,
		isValid,
		isValidReset,
		handleUpdateSuccess
	} = useMultiAppConfigsForm(
		multiAppConfigs, 
		multiAppConfigsInstances,
		refreshMultiAppConfigs, 
		refreshMultiAppConfigsInstances, 
		selectedCompany,
		viewOption
	);
	
	const disabled = useMemo(() => {
		return !!updateArgs
	}, [updateArgs])
	console.log("configs", configs)
	return (
		<>
			<DirectRequest requestArgs={multiAppConfigsInstancesArgs} afterProcess={handleMultiAppConfigsInstances} />
			<ConfigsUpdateRequest
				updateArgs={updateArgs}
				handleUpdateSuccess={handleUpdateSuccess}
			/>
			<Grid item xs={3}>
				<UpdateButton
					isValid={isValid}
					handleUpdate={handleUpdate}
					disabled={disabled}
				/>
			</Grid>
			<Grid item xs={3}>
				<ResetFormButton
					handleFormReset={handleFormReset}
					disabled={disabled}
				/>
			</Grid>
			<Grid item xs={3}>
				{viewOption !== view_options.all && 
					<ResetConfigsInstancesButton
						isValidReset={isValidReset}
						handleReset={handleReset}
						disabled={disabled}
					/>
				}
				{viewOption === view_options.all &&
					<CreateButton
						disabled={disabled}
						refreshMultiAppConfigs={refreshMultiAppConfigs}
					/>
				}
			</Grid>
			<Grid item xs={3}>
				{/* spacing item */}
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={3}>  {/* This spacing will handle the gaps between cards */}
					{configs.map((config) => {
						return (
							<Grid
								key={config[config_fields.uuid] + "_config"}
								item 
								xs={4}
								sx={{
									// Remove any default vertical margins/padding
									marginY: "0 !important",
									// Use negative margin to pull cards closer vertically
									marginBottom: "-16px !important"  // Adjust this value to match horizontal spacing
								}}
							>
								<Grid 
									container 
									spacing={2}
									sx={{
										border: "1px solid rgba(0, 0, 0, 0.23)",
										borderRadius: "10px",
										padding: "16px",
										height: '100%',
										margin: 0,
										width: '100%'
									}}
								>
									<Config
										config={config}
										handleChange={handleChange}
										disabled={disabled}
										viewOption={viewOption}
										mode={config_modes.edit}
										handleDelete={handleDelete}
									/>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</>
	)
}
/*
{configs.map((config, index) => {
	// return (
	// 	<Grid
	// 		key={config[config_fields.uuid] + "_config"}
	// 		item xs={4}
	// 		container spacing={2}
	// 		sx={{ 
	// 			marginBottom: "1rem !important",
	// 		}}
	// 	>
	// 		<Config
	// 			config={config}
	// 			handleChange={handleChange}
	// 			disabled={disabled}
	// 			viewOption={viewOption}
	// 			mode={config_modes.edit}
	// 			handleDelete={handleDelete}
	// 		/>
	// 	</Grid>
	// )
	return (
		<Grid
			key={config[config_fields.uuid] + "_config"}
			item 
			xs={4}
			sx={{ 
				paddingBottom: "16px",  // Add bottom padding instead of margin
				// Add margin to create space, but not for every 3rd item (last in row)
				// marginRight: (index + 1) % 3 === 0 ? 0 : "16px !important",
			}}
		>
			<Grid 
				container 
				spacing={2}
				sx={{
					border: "2px solid rgba(0, 0, 0, 0.23)",
					borderRadius: "10px",
					padding: "16px",
					height: '100%',  // Ensure full height
					margin: 0,  // Reset margin
					width: '100%'  // Ensure full width
				}}
			>
				<Config
					config={config}
					handleChange={handleChange}
					disabled={disabled}
					viewOption={viewOption}
					mode={config_modes.edit}
					handleDelete={handleDelete}
				/>
			</Grid>
		</Grid>
	)
})}
*/

// viewOption === ALL
const AllConfigs = ({
	selectedCompany,
	viewOption// passed for useMultiAppConfigsForm
}) => {
	const {
		multiAppConfigs,
		multiAppConfigsArgs,
		handleMultiAppConfigs,
		refreshMultiAppConfigs
	} = useMultiAppConfigs();
	
	return (
		<>
			<DirectRequest requestArgs={multiAppConfigsArgs} afterProcess={handleMultiAppConfigs}/>
			<ConfigsForm
				selectedCompany={selectedCompany}
				multiAppConfigs={multiAppConfigs}
				viewOption={viewOption}
				refreshMultiAppConfigs={refreshMultiAppConfigs}
			/>
		</>
	)
}

// viewOption === MODULE
const ConfigsByModule = ({
	selectedCompany,
	selectedModuleUuid,
	viewOption
}) => {
	const {
		multiAppConfigs,
		multiAppConfigsArgs,
		handleMultiAppConfigs
	} = useMultiAppConfigsByModule(selectedCompany, selectedModuleUuid);
	
	return (
		<>
			<DirectRequest requestArgs={multiAppConfigsArgs} afterProcess={handleMultiAppConfigs}/>
			<ConfigsForm
				selectedCompany={selectedCompany}
				multiAppConfigs={multiAppConfigs}
				viewOption={viewOption}
			/>
		</>
	)
}

// viewOption === APP
const ConfigsByApp = ({
	selectedCompany,
	selectedAppUuid,
	viewOption
}) => {
	const {
		multiAppConfigs,
		multiAppConfigsArgs,
		handleMultiAppConfigs
	} = useMultiAppConfigsByApp(selectedCompany, selectedAppUuid);
	console.log("multiAppConfigsArgs", multiAppConfigsArgs)
	return (
		<>
			<DirectRequest requestArgs={multiAppConfigsArgs} afterProcess={handleMultiAppConfigs}/>
			<ConfigsForm
				selectedCompany={selectedCompany}
				multiAppConfigs={multiAppConfigs}
				viewOption={viewOption}
			/>
		</>
	)
}


export const MultiAppConfigs = ({
	viewOption,
	selectedAppUuid,
	selectedModuleUuid,
	selectedCompany
}) => {
	return (
		<Grid item xs={12} container spacing={2}>
			{viewOption === view_options.all && 
				<AllConfigs
					selectedCompany={selectedCompany}
					viewOption={viewOption}
				/>
			}
			{viewOption === view_options.module && 
				<ConfigsByModule
					selectedCompany={selectedCompany}
					selectedModuleUuid={selectedModuleUuid}
					viewOption={viewOption}
				/>
			}
			{viewOption === view_options.app && 
				<ConfigsByApp
					selectedCompany={selectedCompany}
					selectedAppUuid={selectedAppUuid}
					viewOption={viewOption}
				/>
			}
		</Grid>
	)
}

export default MultiAppConfigs;