import { useState } from "react";
import { useSelector } from "react-redux";
import JsonInput from "../../../../Inputs/JsonInput";
import { isValidJson } from "../../hooks/useModuleForm";
import ReferenceButton from "../Buttons/ReferenceButton";
import getSourceTag from "../../../../../helpers/getSourceTag";
{
  /* <JsonInput
  label={field}
  value={formState[field]}
  handleUpdate={handleUpdate}
  helperText={helperText}
  minLines={10}
  maxLines={30}
/> */
}

const copyField = "uuid";

const columns = ["fullName", "uuid", "sourceTag"];

export const AppDependencyGraphInput = ({
  field,
  formState,
  handleFormUpdate,
}) => {
  const allApps = useSelector((state) => state.appsSysAdmin.list).map(app => {
    return {
      ...app,
      sourceTag: getSourceTag(app.tags)
    }
  });
  const sortedApps = [...allApps].sort((a, b) =>
    a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase())
  );
  const [helperText, setHelperText] = useState(""); // State to manage editor annotations
  const handleUpdate = (newAppDependencyGraph) => {
    console.log("newAppDependencyGraph", newAppDependencyGraph);
    handleFormUpdate(field, newAppDependencyGraph);
    // try {
    //   // Parse the new JSON input into an array
    //   const parsedGraph = JSON.parse(newAppDependencyGraph);

    //   // Ensure it's an array of arrays of strings
    //   if (Array.isArray(parsedGraph) && parsedGraph.every(row => Array.isArray(row) && row.every(item => typeof item === "string" && item.length > 0))) {
    //     console.log("Valid appDependencyGraph:", parsedGraph);
    //       handleFormUpdate(field, parsedGraph);
    //       setHelperText("");
    //   } else {
    //     console.error("Invalid appDependencyGraph structure:", parsedGraph);
    //     setHelperText("Invalid structure: Expected an array of arrays of non-empty strings.")
    //   }
    // } catch (err) {
    //   console.error("Invalid JSON input:", newAppDependencyGraph);
    //   setHelperText("Invalid JSON syntax.")
    // }
  };

  return (
    <div style={{ position: "relative" }}>
      <JsonInput
        label={field}
        value={formState[field]}
        handleUpdate={handleUpdate}
        helperText={helperText}
        minLines={10}
        maxLines={30}
      />
      <div style={{ position: "absolute", top: "-5px", right: "0px" }}>
        <ReferenceButton
          data={sortedApps}
          columns={columns}
          copyField={copyField}
          title="Apps Reference"
        />
      </div>
    </div>
  );
};

export default AppDependencyGraphInput;
