import TextInput from "./TextInput";
import StringInput from "../../../Inputs/StringInput";

export const DateInput = ({value, label, error, errorMessage, handleChange, disabled}) => {
	// need to generate error/error message based on date validation in parent
	return (
		<StringInput
			value={value}
			handleUpdate={handleChange}
			label={label}
			error={error}
			errorMessage={errorMessage}
			disabled={disabled}
		/>
	)
}

export default DateInput;