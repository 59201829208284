import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertNotificationArgs } from "../../store/alertsNotificationSlice";
import {
	Popper,
	ClickAwayListener,
	Grid,
	FormControl,
	TextField,
	Button,
	ButtonGroup,
	Tooltip,
	IconButton,
} from '@mui/material';
import * as Constants from "../../Constants";
import Angela_AI_Logo from "../../assets/logos/Angela-AI-Logo.png";
import { useTheme } from "@mui/material/styles";
import DirectRequest from "../../API/requests/DirectRequest";
import { module_fields } from "../../store/modulesSlice";
import { app_fields } from "../../store/appsSlice";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";
import objInBody from "../../helpers/objInBody";
import CloseIcon from "@mui/icons-material/Close";

const ai_agent_logos = {
	[Constants.ai_agents.Angela]: Angela_AI_Logo
}

// Separated animation styles
const typingAnimationStyles = `
	@keyframes bounceDot {
		0%, 80%, 100% { transform: translateY(0); }
		40% { transform: translateY(-6px); }
	}
`;

// Separated message components
const MessageBubble = ({ text, isUser, ai_agent }) => (
	<div style={{
		display: 'flex',
		justifyContent: isUser ? 'flex-end' : 'flex-start',
		marginBottom: '8px',
	}}>
		{!isUser &&
			<img
			//	ref={anchorRef}
				src={ai_agent_logos[ai_agent]/*Angela_AI_Logo*/}
				alt="Chat"
				style={{
					marginTop: "8px",
					marginRight: "8px",
					width: "20px",
					height: "20px",
					objectFit: "contain",
					borderRadius: "50%"
				}}
			/>
		}
		<div style={{
			backgroundColor: isUser ? 'rgba(228, 236, 255, 1.0)' : '#f5f5f5',
			padding: '8px 12px',
			borderRadius: '12px',
			maxWidth: isUser ? '80%' : 'calc(80% - 28px)',
			whiteSpace: 'pre-wrap',
			overflowWrap: 'break-word',
			wordWrap: 'break-word',
			wordBreak: 'break-word',
			hyphens: 'auto'
		}}>
			{text}
		</div>
	</div>
);


const TypewriterMessage = ({ text, isUser, messagesEndRef, onComplete, ai_agent }) => {
	const [displayedText, setDisplayedText] = useState("");
	const [showTypingIndicator, setShowTypingIndicator] = useState(true);
	
	useEffect(() => {
		// Start with typing indicator
		let typingTimeout = setTimeout(() => {
			setShowTypingIndicator(false);
			
			// Then start typewriter effect
			let index = 0;
			const interval = setInterval(() => {
				setDisplayedText((prev) => text.slice(0, index + 1));
				index++;

				if (messagesEndRef.current) {
					messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
				}

				if (index === text.length) {
					clearInterval(interval);
					setTimeout(() => {
						onComplete();
					}, 1000);
				}
			}, 30);

			return () => clearInterval(interval);
		}, 1500);

		return () => clearTimeout(typingTimeout);
	}, [text, messagesEndRef, onComplete]);

	if (showTypingIndicator) {
		return <TypingIndicator ai_agent={ai_agent} />;
	}

	return <MessageBubble text={displayedText} isUser={isUser} ai_agent={ai_agent} />;
};

const TypingIndicator = ({ai_agent}) => (
	<>
		<style>{typingAnimationStyles}</style>
		<div style={{
			display: 'flex',
			justifyContent: 'flex-start',
			marginBottom: '8px'
		}}>
			<img
			//	ref={anchorRef}
				src={ai_agent_logos[ai_agent]/*Angela_AI_Logo*/}
				alt="Chat"
				style={{
					marginTop: "8px",
					marginRight: "8px",
					width: "20px",
					height: "20px",
					objectFit: "contain",
					borderRadius: "50%"
				}}
			/>
			<div style={{
				position: 'relative',
				padding: '8px 12px',
				backgroundColor: '#f5f5f5',
				borderRadius: '12px',
			}}>
				<span style={{ visibility: 'hidden' }}>xxx</span>
				<div style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: 'flex',
					gap: '4px',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					{[0, 1, 2].map((i) => (
						<div
							key={i}
							style={{
								width: '6px',
								height: '6px',
								backgroundColor: '#757575',
								borderRadius: '50%',
								animation: `bounceDot 1s infinite`,
								animationDelay: `${i * 0.15}s`
							}}
						/>
					))}
				</div>
			</div>
		</div>
	</>
);

// Main chat interface component
export const MessageInput = ({ open, anchorEl, onClose, onSubmit, ai_agent }) => {
	const [message, setMessage] = useState("");
	const [messages, setMessages] = useState([
		{
			text: "What can I help you with today?",
			isUser: false,
			typing: false
		}
	]);
	const [showInput, setShowInput] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const messagesEndRef = useRef(null);
	const textFieldRef = useRef(null);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
		}
	}, [messages]);

	// Add escape key handler
	useEffect(() => {
		const handleEscapeKey = (event) => {
			if (event.key === 'Escape' && open) {
				onClose();
			}
		};

		document.addEventListener('keydown', handleEscapeKey);
		
		return () => {
			document.removeEventListener('keydown', handleEscapeKey);
		};
	}, [open, onClose]);

	const resetChat = () => {
		setMessages([]);
		setShowInput(true);
		setIsSubmitting(false);
		setMessage("");
	};

	const handleSubmit = () => {
		if (!message.trim()) return;

		const userMessage = message;
		setIsSubmitting(true);
		setShowInput(false);
		setMessage("");
		
		// Add user message
		setMessages(prev => [...prev, { text: userMessage, isUser: true }]);
		
		// Process the submission
		onSubmit(userMessage);

		// Add AI response with typing effect
		setMessages(prev => [...prev, {
			text: `${ai_agent/*Angela*/} is monitoring this and we will get back to you with any changes`,
			isUser: false,
			typing: true
		}]);
	};

	const handleAnimationComplete = () => {
		setTimeout(() => {
			resetChat();
			onClose();
		}, 500);
	};
	
	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement="top"
			id="general-ai-input"
			sx={{
				zIndex: 1000,
				marginBottom: '10px !important'
			}}
		>
			<ClickAwayListener onClickAway={onClose}>
				<Grid
					container
					spacing={1}
					sx={{
						marginTop: ".5rem",
						width: "450px",
						backgroundColor: "white !important",
						borderRadius: "10px",
						padding: "10px",
						boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.1)"
					}}
				>
					<Grid 
						item 
						xs={12} 
						sx={{
							height: '60vh',
							display: 'flex',
							flexDirection: 'column',
							overflow: 'auto', // Prevents double scrollbars
							padding: "0px !important"
						}}
					>
						{/* Messages Container */}
						<div
							ref={messagesEndRef}
							style={{
								flex: 1,
								overflowY: 'auto',
								// paddingRight: '8px'
								padding: "0px !important"
							}}
						>
							{messages.map((msg, index) => (
								msg.typing ? (
									<TypewriterMessage
										key={index}
										text={msg.text}
										isUser={msg.isUser}
										messagesEndRef={messagesEndRef}
										onComplete={handleAnimationComplete}
										ai_agent={ai_agent}
									/>
								) : (
									<MessageBubble
										key={index}
										text={msg.text}
										isUser={msg.isUser}
										ai_agent={ai_agent}
									/>
								)
							))}
						</div>
	
						{/* Input Field */}
						{showInput && (
							<div style={{
								marginTop: '10px',
								// padding: '0 8px'
							}}>
								<FormControl sx={{ width: "100%" }}>
									<TextField
										inputRef={(input) => {
											if (input && open) {
												input.focus();
											}
										}}
										multiline
										maxRows={16}
										variant="outlined"
										value={message}
										onChange={(event) => setMessage(event.target.value)}
										onKeyPress={(event) => {
											if (event.key === 'Enter' && !event.shiftKey) {
												event.preventDefault();
												handleSubmit();
											}
										}}
										//data-tut="reactour__description"
									/>
								</FormControl>
							</div>
						)}
					</Grid>
	
					{/* Submit Button */}
					<Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
						<Button
							onClick={handleSubmit}
							variant="contained"
							size="small"
							sx={{ width: "100%" }}
							disabled={!message || isSubmitting}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</ClickAwayListener>
		</Popper>
	);
};

// Main button component
const aiButtonStyle = {
	borderRadius: "50% !important",
	padding: "4px",
};

const angela = Constants.ai_agents.Angela;
// TODO: how will the agent choice get controlled/passed to this? eventually this will support multiple modules - multiple "agents" - module tags?

export const AiMessageInput = ({ moduleUuid }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.role.name);
	const isInternal = useSelector((state) => state.role.isInternal);
	const modules = useSelector((state) => state.modules.list);
	const apps = useSelector(state => state.apps.filteredList);
	const uuid = useSelector(state => state.dashboard.uuid);

	const [open, setOpen] = useState(false);
	const [generalAiMessageArgs, setGeneralAiMessageArgs] = useState(null);
	const anchorRef = useRef(null);
	const [inputKey, setInputKey] = useState(0);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
		setInputKey(prev => prev + 1);
	};

	const handleClose = (event) => {
		// If event exists and click was on anchor, don't close
		if (event && anchorRef.current?.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleSubmit = (message) => {
		const thisModule = modules.find(module => module[module_fields.uuid] === moduleUuid);
		const thisApp = apps.find(app => app[app_fields.uuid] === uuid);
		
		if (!isInternal) {
			const tempGeneralAiMessageArgs = {
				url: Constants.SERVER_POST_GENERAL_AI_MESSAGE_URL,
				method: "POST",
				body: JSON.stringify({ message: message })
			}
			setGeneralAiMessageArgs(tempGeneralAiMessageArgs)
			
			const moduleTitle = thisModule[module_fields.fullName];
			const displayTitleIndex = thisModule[module_fields.displayApps].indexOf(uuid);
			const displayTitle = displayTitleIndex >= 0 ? thisModule[module_fields.displayTitles][displayTitleIndex] ?? "" : "";
			
			const emailBody = `${angela} AI message from <strong>${user}</strong> viewing ${displayTitle} in ${moduleTitle} on ${window.location.origin}
				<br/><br/>Message:<br/>${message}
				<br/><br/>Module:<br/>${objInBody(thisModule)}
				<br/><br/>App:<br/>${objInBody(thisApp)}`;
			
			const tempBody = {
				"body": emailBody,
				"subject": `${angela} AI message from ${user} (${moduleTitle} - ${window.location.origin} - ${iso8601Timestamp()})`,
				"from": "noreply@wiselayer.com",
				"toEmail": Constants.notificationList.join(","),
				"sendHTML": true
			};

			dispatch(setAlertNotificationArgs({
				url: Constants.SERVER_SEND_EMAIL,
				method: "POST",
				body: JSON.stringify(tempBody)
			}));
		}
	};
	
	return (
		<>
			<DirectRequest
				requestArgs={generalAiMessageArgs}
				afterProcess={() => console.log("success posting general ai message")}
				handleError={(err) => console.log("error posting general ai message", err)}
				handleCatchError={(err) => console.log("catch error posting general ai message", err)}
			/>
			<ButtonGroup>
				
					<IconButton tabIndex={-1} onClick={handleToggle} disableRipple sx={aiButtonStyle} id="general-ai-button" ref={anchorRef}>
						{open ? (
							<CloseIcon
								sx={{
									width: "40px",
									height: "40px",
									borderRadius: "50%"
								}}
							/>
						) : (
							<Tooltip title={!open ? "Chat with Angela" : "Close chat"} enterDelay={400} enterNextDelay={500}>
								<img
									src={ai_agent_logos[angela]/*Angela_AI_Logo*/}
									alt="Chat"
									style={{
										width: "40px",
										height: "40px",
										objectFit: "contain",
										borderRadius: "50%"
									}}
								/>
							</Tooltip>
						)}
					</IconButton>
				
			</ButtonGroup>

			<MessageInput
				key={inputKey}
				open={open}
				anchorEl={anchorRef.current}
				onClose={handleClose}
				onSubmit={handleSubmit}
				ai_agent={angela}
			/>
		</>
	);
};

export default AiMessageInput;