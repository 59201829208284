import { createSlice } from '@reduxjs/toolkit';

export const create_integration_fields = {
  sourceName: "sourceName",
  redirectUri: "redirectUri",
  customConnector: "customConnector"
}

export const integration_fields = {
  connected: "connected",
  fivetranConnectorId:"fivetranConnectorId",
  sourceName: "sourceName",
  uuid: "uuid",
  customConnector: "customConnector"
}

export const integrationMetadataSlice = createSlice({
  name: "integrationMetadata",
  initialState: {
    integrationsError: {
      isErrored: false,
      errorMessage: "",
    },
    isCaptured: false,
    integrations: [],
  },
  reducers: {
    setIntegrations: (state, action) => {
      state.isCaptured = true;
      state.integrations = action.payload;
    },
    setIntegrationsError: (state, action) => {
      state.integrationsError.isErrored = true;
      state.integrationsError.errorMessage = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setIntegrations, setIntegrationsError } = integrationMetadataSlice.actions;

export default integrationMetadataSlice.reducer;